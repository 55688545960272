import React, { useState, useEffect, useRef } from "react"
import CheckmarkSvg from "../../../components/icons/Checkmark"
import Modal, { IModalProps, ModalCloseBtn } from "../../../components/Modal"
import { GPUpsellSuccessText } from "../styles/GamblingPartnerUpsellModal.styles"
import Ad from "../../../components/Ad"
import BidBarrel from "../../../utils/bid-barrel"
import LoadingView from "@cbs-sports/sports-shared-client/build/cjs/components/LoadingView"
import { canUseDom, edgeToNode } from "../../../utils/misc-utils"
import { useQuery } from "@apollo/client/react/hooks"
import {
  GamblingPartnerEventsForPeriodQuery,
  GamblingPartnerEventsForPeriodQueryVariables,
  GamblingPartnerEventsForPeriodQuery_gameInstance_period_events_edges_node,
  GamblingPartnerEventsForPeriodQuery_gameInstance_period_events_edges_node_homeTeam,
} from "../../../../__generated__/GamblingPartnerEventsForPeriodQuery"
import { GAMBLING_PARTNER_EVENTS_FOR_PERIOD_QUERY } from "../../queries"
import { emptyArray } from "@cbs-sports/sports-shared-client/build/cjs/utils"
import { useCallback } from "react"
import PickUtils from "../../../../common/pick-utils"
import { isMarchMadnessMatcher } from "../../../../common/common-utils-helpers"
import { ENUM_WILLIAM_HILL_NEW_JERSEY } from "../../../../common/enums"

interface IProps extends IModalProps {
  isOpen: boolean
  gameInstanceUid: string
  pickUtils: PickUtils
  layoutProps: any
  calloutBar?: any
  closeModal: () => void
}

const slotName = "gambling_partner_modal"
const preferredBook = ENUM_WILLIAM_HILL_NEW_JERSEY

const getGamblingPartnerModalMetadata = (
  event: GamblingPartnerEventsForPeriodQuery_gameInstance_period_events_edges_node,
  pickTeam: GamblingPartnerEventsForPeriodQuery_gameInstance_period_events_edges_node_homeTeam | null,
  usesSpread: boolean,
): {
  data: string
  isOffBoard: number
} => {
  const teamInfo = `${event.awayTeam?.abbrev || ""};${event.homeTeam?.abbrev || ""};${pickTeam?.abbrev || ""}`
  const lines = (usesSpread ? event?.oddsMarket?.spreads : event?.oddsMarket?.moneyLines) || (emptyArray as any)
  const line = lines.find((ml) => ml.teamId === pickTeam?.id)
  const spread = usesSpread ? line?.spread || "+0" : line?.odds || "+0"
  const isOffBoard =
    !line?.selectionId || event?.oddsMarket?.bookUsed?.name !== ENUM_WILLIAM_HILL_NEW_JERSEY || event.gameStatusDesc?.toUpperCase() !== "SCHEDULED"
      ? 0
      : 1
  return {
    data: `${teamInfo};${spread};${line?.selectionId || ""};${isOffBoard}`,
    isOffBoard,
  }
}
const GamblingPartnerUpsellModal = ({ isOpen, closeModal, afterClose, layoutProps, gameInstanceUid, pickUtils }: IProps) => {
  const periodId = pickUtils.period.id
  const gamblingPartnerEventsForPeriodQuery = useQuery<GamblingPartnerEventsForPeriodQuery, GamblingPartnerEventsForPeriodQueryVariables>(
    GAMBLING_PARTNER_EVENTS_FOR_PERIOD_QUERY,
    {
      skip: !gameInstanceUid || !periodId || isMarchMadnessMatcher.test(gameInstanceUid),
      variables: { gameInstanceUid, periodId, preferredBook },
    },
  )

  const allEvents = gamblingPartnerEventsForPeriodQuery.data?.gameInstance?.period?.events?.edges.map(edgeToNode) || emptyArray

  const [showContent, setShowContent] = useState(false)
  const countRef = useRef(0)
  const adProps = BidBarrel.getDomValues({
    adLayoutProps: layoutProps,
    adSlot: "gambling_partner_modal",
    requestedPos: "dynamic",
  })
  const renderAd = true

  const eventData = useCallback(() => {
    const events: {
      event: GamblingPartnerEventsForPeriodQuery_gameInstance_period_events_edges_node
      pickTeam: GamblingPartnerEventsForPeriodQuery_gameInstance_period_events_edges_node_homeTeam | null
    }[] = []
    const usesSpread = pickUtils.usesSpread()
    for (const pick of pickUtils.picks) {
      const event = pickUtils.getEventForSlotId(pick.slotId)
      const oddEvent = allEvents.find((x) => x.id === event.id)
      if (oddEvent) {
        const team = oddEvent.awayTeam?.id === pick.itemId ? oddEvent.awayTeam : oddEvent.homeTeam
        events.push({
          event: oddEvent,
          pickTeam: team,
        })
      }
    }
    return events
      .map((x) => {
        return getGamblingPartnerModalMetadata(x.event, x.pickTeam, usesSpread)
      })
      .sort((a, b) => b.isOffBoard - a.isOffBoard)
      .map((x) => x.data)
  }, [allEvents, pickUtils])

  const data = {
    games: eventData(),
    uses_spreads: pickUtils.usesSpread() ? 1 : 0,
  }

  //HACK => Put loading state till the add is load
  useEffect(() => {
    let intervalId = 0
    if (isOpen) {
      const getContent = () => {
        if (canUseDom) {
          const wrap = document.getElementById(slotName)
          // show content after render child or after 5seg
          if (wrap?.childElementCount || countRef.current >= 5) {
            setShowContent(true)
            clearInterval(intervalId)
            countRef.current = 0
          } else {
            countRef.current = countRef.current + 1
          }
        }
      }
      intervalId = setInterval(getContent, 1000)
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [isOpen])

  return (
    <Modal className="modal--gambling-partner-promo" isOpen={isOpen} onBackgroundClick={closeModal} afterClose={afterClose}>
      <GPUpsellSuccessText>
        <CheckmarkSvg icon={true} />
        <span>Your picks have been saved!</span>
        <ModalCloseBtn onClick={closeModal} id="betModalClose" />
      </GPUpsellSuccessText>
      {!showContent && renderAd && <LoadingView />}
      {isOpen && renderAd && (
        <Ad
          adSlot={slotName}
          useShSkybox={false}
          {...BidBarrel.extractProps(adProps)}
          style={{ visibility: showContent ? "visible" : "hidden" }}
          dataMetadata={JSON.stringify(data)}
        />
      )}
    </Modal>
  )
}

GamblingPartnerUpsellModal.modalKey = "GamblingPartnerUpsellModal"
export default GamblingPartnerUpsellModal
