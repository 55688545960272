import styled from "styled-components/macro"
import { breakpoints, layout, palette, pxToRem } from "../../../utils/style-utils"
import { breakpointBracket } from "./Bracket.styles"

interface IHasRoundCount {
  roundCount: number
}

export const SvgMinimapWrapper = styled.div<IHasRoundCount>`
  margin-right: auto;
  pointer-events: none;
  transform-origin: bottom left;
  & .minimap__matchup {
    stroke-width: 0.5;
    transition: all 0.2s ease-out;
  }
  & .minimap__viewport {
    stroke-width: 0.5;
    fill: ${palette.blue1}22;
    stroke: ${palette.blue1};
    opacity: 0.7;
    transition: all 0.2s ease-out;
  }
  @media (min-width: ${breakpointBracket}) {
    & .minimap__viewport {
      display: none;
    }
  }
  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    & {
      transform: scale(0.8);
    }
  }
  @media (max-height: 600px) {
    & {
      transform: scale(0.7);
    }
  }
  @media (min-width: ${pxToRem(breakpoints.handheld)}) {
    & {
      min-width: ${pxToRem(layout.picksPageBracketLeftColW)};
      display: flex;
      justify-content: center;
    }
  }
`

export const BracketMinimapWrapper = styled.div<IHasRoundCount>`
  @media (min-width: ${breakpointBracket}) {
    & {
      display: none;
    }
  }
  & svg {
    display: block;
    transition: stroke 0.4s ease;
  }
  &.is-mobile-fixed--false svg {
    width: 90%;
    margin: 6vw auto;
    max-width: ${pxToRem(200)};
  }
  & path {
    transition: stroke 0.4s ease;
  }
  &.is-mobile-fixed--true {
    max-width: 40vw;
    margin-right: auto;
    pointer-events: none;
  }
  &.is-mobile-fixed--true svg {
    width: 90%;
    margin: 6vw auto;
    max-width: ${pxToRem(200)};
  }
`
export const BracketDotsW = 9
export const BracketMinimapLi = styled.button<{ isHighlighted: boolean }>`
  width: ${pxToRem(BracketDotsW)};
  height: ${pxToRem(BracketDotsW)};
  border-radius: 50%;
  background-color: ${({ isHighlighted }) => (isHighlighted ? palette.blue1 : palette.uiSubText3)};
  transition: background-color 0.4s ease;
`
export const BracketMinimapUl = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${pxToRem((BracketDotsW + 7) * 3)};
  margin: 0 auto 6vw;
`
