/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react"
import { useEffect, useRef, useState } from "react"
import { Redirect } from "react-router-dom"
import BracketUtils from "../../../../common/bracket-utils"
import { IStyledBracketMatchup, IStyledBracketTeam, TBracketSection } from "../../../../common/common-utils-types.d"
// import { emptyVariable } from "../../../../common/misc-utils";
import { useDeviceType } from "../../../Base/DeviceType"
import { FlexColGrow, FlexRow } from "../../../components/FlexComponents"
import InformationOutlineSvg from "../../../components/icons/InformationOutline"
import TriangleSvg from "../../../components/icons/Triangle"
import Link from "../../../components/Link"
import { withoutDomain } from "../../../utils/url-utils"

import { IBracketProps, IMatchupComp, IMatchupTeamComp } from "../containers/IPicksProps"
import {
  BracketContainer,
  BracketInner,
  BracketRound,
  BracketSaveBtn,
  BracketSaveBtnContainer,
  BracketSection,
  BracketSectionInner,
  BracketWrapper,
  ChampContainer,
  Champion,
  ChampionH1,
  ChampionMatchup,
  IisSaving,
  Matchup,
  MatchupConnectorLeftDown,
  MatchupConnectorLeftUp,
  MatchupConnectorRightDown,
  MatchupConnectorRightUp,
  MatchupConnectorSemisLeft,
  MatchupConnectorSemisRight,
  MatchupInfo,
  MatchupLiveLink,
  MatchupMidSaving,
  MatchupOuter,
  MatchupSaving,
  MatchupTeam,
  MatchupTeamCorrectPick,
  MatchupTeamName,
  MatchupTeamSeed,
  Semifinals,
} from "../styles/Bracket.styles"
// import BracketMatchup, { BracketDefs } from "./BracketMatchup";
import BracketMinimap from "./BracketMinimap"
import SingleBracket from "./SingleBracket"

export const bracketSectionIdPrefix = "bracket-section--"
export const defaultSection = "left" as TBracketSection

const getLiveLinkFor = (event, pickUtils) => {
  const awayTeam = pickUtils.teams.find((t) => t.id === event.awayTeamId) || {}
  const homeTeam = pickUtils.teams.find((t) => t.id === event.homeTeamId) || {}
  const isoStr = new Date(event.startsAt).toISOString() // "2019-03-12T14:47:12.481Z"
  const ts = isoStr.split("T")[0].split("-").join("")
  return `https://www.cbssports.com/college-basketball/gametracker/live/NCAAB_${ts}_${awayTeam.abbrev}@${homeTeam.abbrev}/`
}

const liveLinkMatcher = /INPROGRESS|HALFTIME|OVERTIME/

// NOTE qac: THIS IS A HACK...read below:
const champPickTop = "3.6em"
const picKStyles = {
  default: {} as React.CSSProperties,
  champ: { top: champPickTop } as React.CSSProperties,
  semis: { left: "2em", top: "2.7em" } as React.CSSProperties,
  other: { right: "2em", top: "2.7em", left: "unset" } as React.CSSProperties,
}

const MatchupComp = ({
  section,
  pickUtils,
  className,
  onInputChange,
  showMatchupAnalysis,
  matchupId,
  topItemId,
  bottomItemId,
  round,
  pickId,
  tournamentRounds,
  matchupsCountInRoundSection,
  isLastMatchupInSection,
  roundCount,
  isSaving,
  canEdit,
}: IStyledBracketMatchup & IMatchupComp & IisSaving) => {
  const matchup = pickUtils.matchups.find(({ id }) => id === matchupId)
  const bracketUtils = pickUtils.bracketUtils
  if (!matchup) {
    return null
  }
  const { winnerPlaysIntoOrdinal, roundOrdinal, tournamentRound, event } = matchup
  const isSemis = tournamentRound === tournamentRounds.length - 1
  const isLastBottom = isLastMatchupInSection && matchupsCountInRoundSection !== 1 && section !== "mid"
  const showmatchupLink = !canEdit && !!event && liveLinkMatcher.test(event.gameStatusDesc || "")
  const liveLink = showmatchupLink && getLiveLinkFor(event, pickUtils)
  // console.log(`${matchup.id}: ${tournamentRound} ${roundOrdinal} (${isLastBottom} ${section})`)
  return (
    <MatchupOuter>
      <Matchup className={className} section={section} round={round} roundOrdinal={roundOrdinal} roundCount={roundCount}>
        {bracketUtils.teamPositions.map((position) => {
          const itemId = position === "top" ? topItemId : bottomItemId
          return (
            <MatchupTeamComp
              key={position}
              section={section}
              round={round}
              roundOrdinal={roundOrdinal}
              position={position}
              onInputChange={onInputChange}
              pickUtils={pickUtils}
              showMatchupAnalysis={showMatchupAnalysis}
              matchupId={matchupId}
              itemId={itemId}
              pickId={pickId}
              tournamentRounds={tournamentRounds}
              roundCount={roundCount}
              canEdit={canEdit}
            />
          )
        })}
        <MatchupInfo
          section={section}
          round={round}
          roundOrdinal={roundOrdinal}
          disabled={!topItemId || !bottomItemId}
          roundCount={roundCount}
          type="button"
          onClick={() => showMatchupAnalysis(`${topItemId}|${bottomItemId}|${matchupId}`)}
        >
          <InformationOutlineSvg />
        </MatchupInfo>
        {showmatchupLink && (
          <MatchupLiveLink as={Link as any} to={liveLink} target="_blank">
            <div>
              <i />
              <span>Live</span>
              <TriangleSvg />
            </div>
          </MatchupLiveLink>
        )}
        <MatchupSaving isSaving={isSaving} position={(isSemis && roundOrdinal === 0 && "left") || section}>
          Saved!
        </MatchupSaving>
        {isSemis && roundOrdinal === 0 && <MatchupConnectorSemisLeft roundCount={roundCount} />}
        {isSemis && roundOrdinal === 1 && <MatchupConnectorSemisRight roundCount={roundCount} />}
        {winnerPlaysIntoOrdinal !== null && section === "left" && ((isLastBottom && <MatchupConnectorLeftUp />) || <MatchupConnectorLeftDown />)}
        {winnerPlaysIntoOrdinal !== null && section === "right" && ((isLastBottom && <MatchupConnectorRightUp />) || <MatchupConnectorRightDown />)}
      </Matchup>
    </MatchupOuter>
  )
}
const nullItem = { id: null, abbrev: null }

const MatchupTeamComp = ({
  section,
  position,
  pickUtils,
  className,
  onInputChange,
  matchupId,
  itemId,
  round,
  canEdit,
  tournamentRounds,
  roundCount,
  incPickStyle,
}: IStyledBracketTeam & IMatchupTeamComp) => {
  const matchup = pickUtils.matchups.find(({ id }) => id === matchupId)
  if (!matchup) {
    return null
  }
  const tournamentRound = matchup.tournamentRound
  const roundOrdinal = matchup.roundOrdinal
  const slotId = matchup.id
  const item = pickUtils.teams.find(({ id }) => id === itemId) || nullItem
  const itemName = item.abbrev
  const isSeeded = !!matchup[`${position}ItemSeed`]
  const seed = (itemId && pickUtils.matchupSeedFor(itemId)) || matchup[`${position}ItemSeed`]
  const disabled = !(itemId && canEdit && item?.id)
  const isChampTile = matchup && !matchup.winnerPlaysIntoPosition && incPickStyle && incPickStyle.top == champPickTop
  const correctItemId = isChampTile ? matchup.winnerId : !isSeeded && matchup[`${position}ItemId`]
  // const isTBDChamp = isChampTile && correctItemId && (!matchup.winnerId);
  const correctTeam = !canEdit && correctItemId && pickUtils.teams.find(({ id }) => id === correctItemId)
  // if (matchup && !matchup.winnerPlaysIntoPosition && incPickStyle && incPickStyle.top == champPickTop) {
  //     console.log(`CHAMP ${correctItemId} != ${itemId} (${isTBDChamp})`, matchup, correctItemId)
  // }
  const style = {} as React.CSSProperties
  const isCorrect = !canEdit && !!correctItemId && itemId && correctItemId === itemId
  const isIncorrect =
    !canEdit &&
    ((!!correctItemId && correctItemId !== itemId && correctTeam) ||
      (!isSeeded && !isCorrect && !!itemId && pickUtils.isEliminatedFromBracket(itemId)))
  if (isIncorrect) {
    style.color = "#FF0000"
    style.textDecoration = "line-through"
  }
  if (isCorrect) {
    style.color = "#00D010"
  }
  return (
    <>
      <MatchupTeam
        as={"button" as any}
        type="button"
        role="button"
        title={(itemId && `Pick ${itemName} for round ${tournamentRound} game #${roundOrdinal + 1}`) || "unknown"}
        className={className}
        section={section}
        round={round}
        roundOrdinal={roundOrdinal}
        roundCount={roundCount}
        position={position}
        disabled={disabled}
        onClick={disabled ? undefined : (event) => onInputChange(slotId, itemId || null, event)}
      >
        <MatchupTeamSeed>
          <FlexRow>{seed}</FlexRow>
        </MatchupTeamSeed>
        {/*<input type="radio" value={itemName} name={slotId} onChange={onInputChange} />*/}
        <MatchupTeamName roundCount={roundCount} section={section} round={round} roundOrdinal={roundOrdinal} position={position}>
          <FlexColGrow style={style}>{itemName}</FlexColGrow>
        </MatchupTeamName>
      </MatchupTeam>
      {!canEdit && correctTeam && isIncorrect && (
        <MatchupTeamCorrectPick
          style={incPickStyle}
          roundCount={roundCount}
          section={section}
          round={round}
          roundOrdinal={roundOrdinal}
          position={position}
        >
          <div>{correctTeam.abbrev}</div>
        </MatchupTeamCorrectPick>
      )}
    </>
  )
}

const cachedSectionDims = {
  left: 0,
  mid: 0,
  right: 0,
  width: 0,
}

const Bracket = (props: IBracketProps) => {
  const deviceType = useDeviceType()
  const {
    showMatchupAnalysis,
    tournamentId,
    pickUtils,
    onInputChange,
    slotIdSavingAnimation,
    canEdit,
    scrollToSection,
    bracketNextBtnText,
    onBracketSubmit,
    isSingleBracket,
    gameInstanceUid,
  } = props

  const bracketUtils = pickUtils.bracketUtils
  const bracketMapping = pickUtils.bracketMapping
  const bracketPeriodTree = pickUtils.bracketPeriodTree
  if (!bracketMapping || !bracketPeriodTree) {
    return null
  }

  if (typeof window !== "undefined" && window.hasOwnProperty("QQ")) {
    window.pickUtils = pickUtils
  }

  if (isSingleBracket) {
    if (deviceType === "handheld") {
      return <Redirect to={withoutDomain(`/games/roadblock?showCongrats=false&gameType=${gameInstanceUid}`)} />
    }
    return <SingleBracket {...props} />
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const scrollContainerRef = useRef(null) // We need a ref to our "root" or our parent,
  const sectionRefs = {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    left: useRef(null),
    // eslint-disable-next-line react-hooks/rules-of-hooks
    mid: useRef(null),
    // eslint-disable-next-line react-hooks/rules-of-hooks
    right: useRef(null),
  }
  // [
  //   useRef(null),
  //   useRef(null),
  //   useRef(null),
  // ]
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [currentSection, setCurrentSection] = useState(defaultSection)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const containerEl = scrollContainerRef.current as any
    // window.scrollContainerRef = scrollContainerRef;
    if (containerEl) {
      const detectUpdate = () => {
        const bufferW = cachedSectionDims.width / 2
        const newSection =
          (containerEl.scrollLeft >= cachedSectionDims.right - bufferW && "right") ||
          (containerEl.scrollLeft >= cachedSectionDims.mid - bufferW && "mid") ||
          "left"
        // console.log(`${currentSection} > ${newSection}`)
        // if (currentSection !== newSection || true) {
        setCurrentSection(newSection)
        // }
      }
      const updateCachedSectionDims = () => {
        Object.keys(sectionRefs).forEach((section) => {
          const ref = sectionRefs[section].current
          if (ref) {
            const bounds = ref.getBoundingClientRect()
            // console.log(`updateCachedSectionDims: ${section}`, bounds)
            cachedSectionDims[section] = bounds.left
            cachedSectionDims.width = bounds.width
          }
        })
        detectUpdate()
      }
      const handleScroll = (evt) => {
        // console.log(`handleScroll: ${evt.target.scrollLeft}`, evt)
        detectUpdate()
      }
      containerEl.addEventListener("scroll", handleScroll, { passive: true })
      updateCachedSectionDims()
      window.addEventListener("resize", updateCachedSectionDims, { passive: true })
      return () => {
        containerEl.removeEventListener("scroll", handleScroll)
        window.removeEventListener("resize", updateCachedSectionDims)
      }
    } else {
      console.warn(`no el!`)
      return () => undefined
    }
  }, [])
  // useBracketIntersectionObserver({
  //   scrollContainerRef,
  //   sectionRefs,
  //   onIntersect: ([{ isIntersecting }]) => setThingIntersecting(isIntersecting)
  // });
  // console.log(`Bracket: ${tournamentId} (slotIdSavingAnimation: ${slotIdSavingAnimation})`)
  const tree = bracketUtils.buildTreeFor(tournamentId)
  const tournamentRounds = tree.rounds
  const { picks } = bracketMapping
  const roundCount = tournamentRounds.length
  const uiRoundHack = tournamentRounds.length < 5 ? 1 : 0
  const isMismatchedRoundsOnSides = tournamentRounds.length === 5 && bracketPeriodTree.right.length !== bracketPeriodTree.left.length
  const semisTournamentRound = (tournamentRounds.length > 1 && tournamentRounds[tournamentRounds.length - 2]) || -1
  // console.dir(tree);
  return (
    <>
      <BracketWrapper ref={scrollContainerRef}>
        <BracketContainer roundCount={roundCount}>
          {(bracketUtils as BracketUtils).bracketSections.map((section) => {
            const roundMs = tree[section]
            if (section === "mid") {
              const finalMatchups = (roundMs.length && roundMs[roundMs.length - 1]) || []
              const champMatchup = finalMatchups[0]
              const semisMatchups = (roundMs.length > 1 && roundMs[roundMs.length - 2]) || []
              // TODO qac: use DoubleClickWrapper and sponsorLogoDoubleClickSuffix
              return (
                <BracketSection
                  className={`can-edit--${canEdit}`}
                  ref={sectionRefs[section]}
                  id={`${bracketSectionIdPrefix}${section}`}
                  section={section}
                  roundCount={roundCount}
                  key={section}
                >
                  <BracketSectionInner section={section} roundCount={roundCount}>
                    <ChampContainer roundCount={roundCount}>
                      {champMatchup && (
                        <Champion>
                          <ChampionH1>Champion</ChampionH1>
                          <MatchupTeamComp
                            className="team"
                            section={section}
                            round={champMatchup.tournamentRound + 1 + uiRoundHack}
                            roundOrdinal={0}
                            position="top"
                            onInputChange={onInputChange}
                            pickUtils={pickUtils}
                            matchupId={champMatchup.id}
                            showMatchupAnalysis={showMatchupAnalysis}
                            itemId={picks[champMatchup.id]}
                            pickId={null}
                            tournamentRounds={tournamentRounds}
                            roundCount={roundCount}
                            canEdit={canEdit}
                            incPickStyle={(!canEdit && picKStyles.champ) || undefined}
                          />
                        </Champion>
                      )}
                      {finalMatchups.map((matchup) => {
                        const topItemId = picks[bracketUtils.getPlaysIntoIdFor(matchup, "top") || ""] || null
                        const bottomItemId = picks[bracketUtils.getPlaysIntoIdFor(matchup, "bottom") || ""] || null
                        return (
                          <ChampionMatchup key={matchup.id}>
                            <MatchupTeamComp
                              section={section}
                              round={matchup.tournamentRound + uiRoundHack}
                              roundOrdinal={0}
                              position="top"
                              onInputChange={onInputChange}
                              pickUtils={pickUtils}
                              matchupId={matchup.id}
                              showMatchupAnalysis={showMatchupAnalysis}
                              itemId={topItemId}
                              pickId={picks[matchup.id]}
                              tournamentRounds={tournamentRounds}
                              roundCount={roundCount}
                              canEdit={canEdit}
                              incPickStyle={(!canEdit && picKStyles.semis) || undefined}
                            />
                            <MatchupTeamComp
                              section={section}
                              round={matchup.tournamentRound + uiRoundHack}
                              roundOrdinal={0}
                              position="bottom"
                              onInputChange={onInputChange}
                              pickUtils={pickUtils}
                              matchupId={matchup.id}
                              showMatchupAnalysis={showMatchupAnalysis}
                              itemId={bottomItemId}
                              pickId={picks[matchup.id]}
                              tournamentRounds={tournamentRounds}
                              roundCount={roundCount}
                              canEdit={canEdit}
                              incPickStyle={(!canEdit && picKStyles.other) || undefined}
                            />
                            <MatchupInfo
                              className="info"
                              type="button"
                              section={section}
                              round={matchup.tournamentRound}
                              roundOrdinal={0}
                              disabled={!topItemId || !bottomItemId}
                              roundCount={roundCount}
                              onClick={() => showMatchupAnalysis(`${topItemId}|${bottomItemId}|${matchup.id}`)}
                            >
                              <InformationOutlineSvg />
                            </MatchupInfo>
                            <MatchupMidSaving isSaving={matchup.id === slotIdSavingAnimation}>Saved!</MatchupMidSaving>
                          </ChampionMatchup>
                        )
                      })}
                      <Semifinals>
                        {semisMatchups.map((matchup) => {
                          const topItemId = picks[bracketUtils.getPlaysIntoIdFor(matchup, "top") || ""] || null
                          const bottomItemId = picks[bracketUtils.getPlaysIntoIdFor(matchup, "bottom") || ""] || null
                          return (
                            <MatchupComp
                              key={matchup.id}
                              section={section}
                              round={matchup.tournamentRound + uiRoundHack}
                              roundOrdinal={matchup.roundOrdinal}
                              onInputChange={onInputChange}
                              pickUtils={pickUtils}
                              showMatchupAnalysis={showMatchupAnalysis}
                              matchupId={matchup.id}
                              topItemId={topItemId}
                              bottomItemId={bottomItemId}
                              pickId={picks[matchup.id]}
                              tournamentRounds={tournamentRounds}
                              matchupsCountInRoundSection={1}
                              isLastMatchupInSection={false}
                              roundCount={roundCount}
                              isSaving={matchup.id === slotIdSavingAnimation}
                              canEdit={canEdit}
                            />
                          )
                        })}
                      </Semifinals>
                    </ChampContainer>
                  </BracketSectionInner>
                </BracketSection>
              )
            }
            return (
              <BracketSection
                ref={sectionRefs[section]}
                id={`${bracketSectionIdPrefix}${section}`}
                section={section}
                roundCount={roundCount}
                key={section}
              >
                <BracketSectionInner section={section} roundCount={roundCount}>
                  {roundMs.map((matchups, i) => {
                    // const firstMatchup = matchups.length && matchups[0];
                    return (
                      <BracketRound
                        isMismatchedRoundsOnSides={isMismatchedRoundsOnSides}
                        section={section}
                        roundCount={roundCount}
                        round={i + 1 + uiRoundHack}
                        className={`round--${i + 1 + uiRoundHack} section--${section}`}
                        key={i}
                      >
                        <BracketInner>
                          {matchups.map((matchup, k) => {
                            const topPlaysInMatchupId = bracketUtils.getPlaysIntoIdFor(matchup, "top")
                            const bottomPlaysInMatchupId = bracketUtils.getPlaysIntoIdFor(matchup, "bottom")
                            const topItemId = (topPlaysInMatchupId ? picks[bottomPlaysInMatchupId || ""] : matchup.topItemId) || null
                            const bottomItemId = (bottomPlaysInMatchupId ? picks[bottomPlaysInMatchupId] : matchup.bottomItemId) || null
                            const matchupsCountInRoundSection = matchups.length
                            const isLastRoundBeforeSemis = semisTournamentRound - 1 === matchup.tournamentRound
                            const isLastMatchupInSection = k === matchupsCountInRoundSection - 1 && isLastRoundBeforeSemis
                            // if (matchup.tournamentRound === 2 && matchup.roundOrdinal === 0) {
                            //   console.log(`matchup ${matchup.id} (${bottomPlaysInMatchupId}, ${topPlaysInMatchupId})`, picks)
                            // }
                            return (
                              <MatchupComp
                                key={matchup.id}
                                section={section}
                                round={matchup.tournamentRound + uiRoundHack}
                                roundOrdinal={matchup.roundOrdinal}
                                onInputChange={onInputChange}
                                pickUtils={pickUtils}
                                showMatchupAnalysis={showMatchupAnalysis}
                                matchupId={matchup.id}
                                topItemId={topItemId}
                                bottomItemId={bottomItemId}
                                pickId={picks[matchup.id]}
                                tournamentRounds={tournamentRounds}
                                matchupsCountInRoundSection={matchupsCountInRoundSection}
                                isLastMatchupInSection={isLastMatchupInSection}
                                roundCount={roundCount}
                                isSaving={matchup.id === slotIdSavingAnimation}
                                canEdit={canEdit}
                              />
                            )
                          })}
                        </BracketInner>
                      </BracketRound>
                    )
                  })}
                </BracketSectionInner>
              </BracketSection>
            )
          })}
        </BracketContainer>
      </BracketWrapper>
      <BracketSaveBtnContainer disabled={!bracketNextBtnText}>
        <div>
          {!pickUtils && (
            <BracketMinimap
              pickUtils={pickUtils}
              bracketMapping={bracketMapping}
              highlightedSection={currentSection}
              scrollToSection={scrollToSection}
              roundCount={roundCount}
            />
          )}
          <BracketSaveBtn type="submit" onClick={onBracketSubmit} disabled={!bracketNextBtnText}>
            {bracketNextBtnText}
          </BracketSaveBtn>
        </div>
      </BracketSaveBtnContainer>
    </>
  )
}
export default Bracket
