import React from "react"
import styled from "styled-components/macro"
import { fontWeight, layout, palette, pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import ModalWrapper from "@cbs-sports/sports-shared-client/build/cjs/components/Modal/ModalWrapper"
import BracketSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Bracket"
import StyledButton from "@cbs-sports/sports-shared-client/build/cjs/components/Button/Button.styles"
import { IButton } from "@cbs-sports/sports-shared-client/build/types/components/Button/Button"
import { LinkProps } from "react-router-dom"

const BracketLoginContainer = styled.div`
  width: 26rem;
  padding: 0.5rem 1.5rem 0.5rem;
  box-sizing: border-box;

  @media (max-width: ${pxToRem(layout.modalTakeoverMaxWidth - 1)}) {
    width: ${pxToRem(311)};
    height: ${pxToRem(208)};
    padding: 0;
  }

  @media (max-width: ${pxToRem(layout.dialogMaxWidth - 1)}) {
    width: 100%;
    height: 100%;
  }

  & > div.wrap {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 0 0 1rem;

    @media (max-width: ${pxToRem(layout.modalTakeoverMaxWidth - 1)}) {
      flex-direction: column;
      padding: 0;
    }

    & > div.logo {
      position: relative;
      height: 5rem;
      width: 5rem;
      margin: 0 auto;

      @media (max-width: ${pxToRem(layout.modalTakeoverMaxWidth - 1)}) {
        margin: 0 auto ${pxToRem(8)} auto;
      }

      svg {
        height: auto;
        max-width: 100%;
        opacity: 1;
        transition: opacity 0.5s ease-in 0.5s;
        vertical-align: top;
        margin-bottom: 0;
      }
    }

    & > div.info {
      flex: 1;
      box-sizing: border-box;
      padding: 0 0 0 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;

      @media (max-width: ${pxToRem(layout.modalTakeoverMaxWidth - 1)}) {
        width: ${pxToRem(310)};
        text-align: center;
        padding: 0;
      }

      @media (max-width: ${pxToRem(layout.dialogMaxWidth - 1)}) {
        width: 100%;
      }

      & > span.title {
        margin: 0 0 0.25rem;
        font-size: 18px;
        line-height: 24px;
        font-weight: ${fontWeight.bold};
        width: 100%;
        display: block;
        color: ${palette.gray20};
      }

      & > span.lock-info {
        color: ${palette.gray40};
        font-size: 14px;
        font-weight: ${fontWeight.regular};
        line-height: 1.25rem;
        letter-spacing: -0.1px;
      }
    }
  }
`

export const BtnWrapper = styled.div`
  display: flex;
  flex-basis: auto;
  flex-grow: 0;
  flex-direction: row;
  max-width: none;
  box-sizing: border-box;
  justify-content: flex-end;

  & > a:first-child {
    margin-right: 1rem;
  }

  @media (max-width: ${pxToRem(layout.modalTakeoverMaxWidth - 1)}) {
    width: ${pxToRem(310)};
    margin: ${pxToRem(16)} auto 0 auto;

    & a {
      width: 100%;
    }
  }

  @media (max-width: ${pxToRem(layout.dialogMaxWidth - 1)}) {
    width: 100%;
  }
`

interface BracketSubmitLogOutProps {
  loginProps: IButton & LinkProps
  signUpProps: IButton & LinkProps
  handleCancelClick?: () => void
}

export const BracketLoginModal = ({ loginProps, signUpProps, handleCancelClick }: BracketSubmitLogOutProps) => {
  return (
    <ModalWrapper modalType="modal" variant="white" closeBtnAction={handleCancelClick}>
      <BracketLoginContainer>
        <div className="wrap">
          <div className="logo">
            <BracketSvg />
          </div>
          <div className="info">
            <span className="title">Your picks are almost saved!</span>
            <span className="lock-info">Log in or create a new CBSSports.com account to save your picks.</span>
          </div>
        </div>

        <BtnWrapper>
          <StyledButton {...signUpProps} className="variant--secondary">
            Sign Up
          </StyledButton>
          <StyledButton {...loginProps} className="variant--primary">
            Log In
          </StyledButton>
        </BtnWrapper>
      </BracketLoginContainer>
    </ModalWrapper>
  )
}

export default BracketLoginModal
