import * as React from "react"

const Options = ({ color = "currentColor", ...rest }) => (
  <svg className="exit" {...rest} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    {/* tslint:disable-next-line */}
    <path
      fill={color}
      d="M32 384h272v32H32zM400 384h80v32h-80zM384 447.5c0 17.949-14.327 32.5-32 32.5-17.673 0-32-14.551-32-32.5v-95c0-17.949 14.327-32.5 32-32.5 17.673 0 32 14.551 32 32.5v95z"
    />
    <g>
      {/* tslint:disable-next-line */}
      <path
        fill={color}
        d="M32 240h80v32H32zM208 240h272v32H208zM192 303.5c0 17.949-14.327 32.5-32 32.5-17.673 0-32-14.551-32-32.5v-95c0-17.949 14.327-32.5 32-32.5 17.673 0 32 14.551 32 32.5v95z"
      />
    </g>
    <g>
      {/* tslint:disable-next-line */}
      <path
        fill={color}
        d="M32 96h272v32H32zM400 96h80v32h-80zM384 159.5c0 17.949-14.327 32.5-32 32.5-17.673 0-32-14.551-32-32.5v-95c0-17.949 14.327-32.5 32-32.5 17.673 0 32 14.551 32 32.5v95z"
      />
    </g>
  </svg>
)

export default Options
