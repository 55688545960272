import { Mutation } from "@apollo/client/react/components"
import { Form, Formik } from "formik"
import * as React from "react"
import { Route } from "react-router"
import {
  CopyEntryToChallengeEntryMutation,
  CopyEntryToChallengeEntryMutationVariables,
} from "../../../../__generated__/CopyEntryToChallengeEntryMutation"
import buildGameText from "../../../../common/game-text"
import palette from "../../../../common/palette"
import { withoutDomain } from "../../../../common/url-utils"
import { ButtonBase } from "../../../components/Button"
import { extractValidationError, FormButtonSpinnerBase, FormSuccessStatus } from "../../../components/Form"
import CheckmarkSvg from "../../../components/icons/Checkmark"
import SportslineSvg from "../../../components/icons/SportsLine"
import SportslineTextSvg from "../../../components/icons/SportsLineText"
import Link from "../../../components/Link"
import Modal, { ModalCloseBtn } from "../../../components/Modal"
import { COPY_ENTRY_TO_CHALLENGE_ENTRY_MUTATION } from "../../queries"
import { Row } from "../styles/BcgPromo.styles"
import {
  UpsellBtn,
  UpsellDisclaimer,
  UpsellDiv,
  UpsellHeader,
  UpsellT1,
  UpsellT2,
  UpsellT3,
  UpsellT4,
  UpsellWrapper,
} from "../styles/UpsellModal.styles"
import { IGameOgProps } from "../../../../routes.d"

// <img src="https://sports.cbsimg.net/images/email/graphics/190215_conferencebracketheaderimg.png" />
// Upsell pls!
interface IProps extends Omit<IGameOgProps, "multiGameInstances" | "poolName" | "poolUrl"> {
  entryId: string
  periodId: string
  isOpen: boolean
  close: any
  afterClose?: any
  multiGameInstances?: boolean
  poolName?: string
  poolUrl?: string
}

class UpsellModal extends React.PureComponent<IProps> {
  public static modalKey = "UpsellModal"

  public render() {
    const { isOpen, entryId, periodId, close, afterClose, gameInstanceUid, seasonType, year, productAbbrev } = this.props
    const gameText = buildGameText(gameInstanceUid, seasonType, year, productAbbrev, "upsell")
    const isSportslineUpsell = false
    const color = isSportslineUpsell ? "#03791C" : palette.ncaabConfPrimary

    return (
      <Modal isOpen={isOpen} onBackgroundClick={close} afterClose={afterClose} analyticsScreen="Pool Rollover Modal">
        <Route>
          {({ history }) => {
            if (isSportslineUpsell) {
              return (
                <UpsellWrapper style={{ color }}>
                  <ModalCloseBtn onClick={close} />
                  <UpsellHeader className="sportsline">
                    <SportslineSvg color="#03791C" />
                    <SportslineTextSvg color="#000" />
                  </UpsellHeader>
                  <UpsellT1>
                    <CheckmarkSvg color="#fff" bgColor="currentColor" />
                    Your picks have been saved!
                  </UpsellT1>
                  <UpsellT2>
                    Second Guessing
                    <br />
                    those upset picks?
                  </UpsellT2>
                  <UpsellDiv />
                  <UpsellT3>We beat 92% of Bracket Players over the last 2 years.*</UpsellT3>
                  <Row>
                    <ButtonBase as="a" href={`https://www.sportsline.com/join/?ttag=SL${gameText.tagSuffix}`} rel="noopener">
                      View Picks
                    </ButtonBase>
                  </Row>
                  <UpsellT4>SportsLine simulates each game 10k times to give you the winning pick</UpsellT4>
                  <UpsellDisclaimer>
                    * SportsLine's optimal bracket beat 95.44% of Bracket Challenge players in 2018 and SportsLine's upset bracket beat 89.63% of
                    Bracket Challenge players in 2019.
                  </UpsellDisclaimer>
                </UpsellWrapper>
              )
            }
            return (
              <Mutation<CopyEntryToChallengeEntryMutation, CopyEntryToChallengeEntryMutationVariables>
                mutation={COPY_ENTRY_TO_CHALLENGE_ENTRY_MUTATION}
                refetchQueries={["CentralCurrentUsersEntriesQuery"]}
              >
                {(mutation) => {
                  return (
                    <Formik
                      initialValues={{ entryId, periodId }}
                      onSubmit={(variables, actions) => {
                        mutation({ variables })
                          .then((res) => {
                            // console.log("COPY_ENTRY_TO_CHALLENGE_ENTRY_MUTATION");
                            // console.dir(res);
                            actions.setStatus(FormSuccessStatus)
                            actions.setSubmitting(false)
                            setTimeout(() => {
                              history.push(withoutDomain(res.data?.copyEntryToChallengeEntry?.entry.pool?.url ?? ""))
                            }, 900)
                          })
                          .catch((err) => {
                            // console.log('err')
                            // console.dir(err)
                            const apiErrors = extractValidationError(err)
                            actions.setErrors(apiErrors.errors)
                            actions.setSubmitting(false)
                          })
                      }}
                    >
                      {(formikBag) => {
                        const { isSubmitting, status } = formikBag
                        return (
                          <UpsellWrapper style={{ color }} as={Form}>
                            <UpsellHeader />
                            <ModalCloseBtn onClick={close} />
                            <UpsellT1>
                              <CheckmarkSvg />
                              Your picks have been saved!
                            </UpsellT1>
                            <UpsellT2>Enter Your Picks for the chance to</UpsellT2>
                            <UpsellDiv />
                            <UpsellT3>win up to $10,000</UpsellT3>
                            <FormButtonSpinnerBase
                              as={UpsellBtn}
                              success="Entered!"
                              type="submit"
                              inert="Enter Now"
                              status={status}
                              isSubmitting={isSubmitting}
                              isValid={true}
                              disabled={false}
                            />
                            <UpsellT4>
                              It's free to play and only takes <strong>one click</strong> to submit your picks!
                            </UpsellT4>
                            <UpsellDisclaimer>
                              No purchase necessary. See <Link to={gameText.rulesUrl}>official rules</Link> for details.
                            </UpsellDisclaimer>
                          </UpsellWrapper>
                        )
                      }}
                    </Formik>
                  )
                }}
              </Mutation>
            )
          }}
        </Route>
      </Modal>
    )
  }
}

export default UpsellModal
