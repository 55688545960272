import React from "react"

interface IEntryBarPeriodDetail {
  title: string
  text: string
}

const EntryBarPeriodDetail = (props: IEntryBarPeriodDetail) => {
  const { title, text } = props

  return (
    <div className={"upperCased entryBarPeriodDetail"}>
      <small>
        <dl>
          <div>
            <dd>{title}</dd>
            <dt>
              <strong>{text}</strong>
            </dt>
          </div>
        </dl>
      </small>
    </div>
  )
}

export default EntryBarPeriodDetail
