import styled from "styled-components/macro"
import { pxToRem } from "../../../utils/style-utils"

export const CardActions = styled.div`
  height: ${pxToRem(48)};
  display: flex;
  flex-flow: row nowrap;
  padding-bottom: 0;
  & > * {
    height: 100%;
    flex: 1;
  }
`
export default CardActions
