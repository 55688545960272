import { Query } from "@apollo/client/react/components"
import { DateTime } from "luxon"
import * as React from "react"
import { awayValue, homeValue } from "../../../../common/common-utils-helpers"
import { IBracketUtilMatchup, IPickUtilsEvent, IPickUtilsPick, IPickUtilsTeam } from "../../../../common/common-utils-types.d"
import {
  MatchupAnalysisQuery,
  MatchupAnalysisQueryVariables,
  MatchupAnalysisQuery_matchupAnalysis,
  MatchupAnalysisQuery_matchupAnalysis_awayTeamAnalysis_teamComparison,
} from "../../../../__generated__/MatchupAnalysisQuery"
import { buildTtag } from "../../../../common/game-text"
import PickUtils, { FakePickUtilsTeam } from "../../../../common/pick-utils"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import AvatarBase from "../../../components/Avatar"
import CheckmarkSvg from "../../../components/icons/Checkmark"
import CloseSvg from "../../../components/icons/Close"
import LockSvg from "../../../components/icons/Lock"
import PlaySvg from "../../../components/icons/Play"
import SportsLineSvg from "../../../components/icons/SportsLine"
// import SportsLineTextSvg from "../../../components/icons/SportsLineText"
import LinkRow from "../../../components/LinkRow"
import LoadingView from "../../../components/LoadingView"
import { Logo } from "../../../components/Logos"
import Modal, { ModalWrapper } from "../../../components/Modal"
import TeamImg from "../../../components/TeamImg"
import { placeholderImg, teamLongName, teamSrc } from "../../../utils/data-utils"
import { toRank, toTenth } from "../../../utils/misc-utils"
import { palette } from "../../../utils/style-utils"
import { MATCHUP_ANALYSIS_QUERY } from "../../queries"
import PickemEvent from "../components/PickemEvent"
import { Nbsp } from "../components/PoolPage"
import {
  BarChartInner,
  BarChartOuter,
  // , RoundBar, SSR, SSS,
  MaBlueHeader,
  MaBracketHeader,
  MaBracketPlayerInfo,
  MaBracketPlayerInjury,
  MaBracketPlayerJersey,
  MaBracketPlayerS,
  MaBracketPlayerStats,
  MaCloseBtn,
  MaContent,
  MaExpert,
  // MaExpertIcon,
  MaExpertMain,
  MaExpertRow,
  MaExpertRowTeams,
  MaExperts,
  MaExpertTeam,
  MaExpertText,
  MaHeader,
  MaHGameTime,
  MaHGameWeather,
  MaHr,
  MaHWeatherIcon,
  MaImgLogoContainer,
  MaInjuries,
  MaInjury,
  MaInjuryCol,
  MaInjuryHeader,
  MaKeyStatRow,
  MaKeyStatWrapper,
  MaLogoContainer,
  MaMobileTeamOverview,
  MaMobileVenueInfo,
  MaOdd,
  MaOddCenter,
  MaOdds,
  MaPicks,
  MaPicksDivider,
  MaRecord,
  MaRecords,
  MaRedHeader,
  MaSectionHeaderAltH,
  MaSectionHeaderAltS,
  MaSectionHeaderS,
  MaSectionSubHeader,
  MaSplitRow,
  MaStat,
  MaTeamImg,
  MaTeamInfo,
  MaTeamOverviewTeam,
  MaTeamSection,
  MaTeamsSection,
  MaWatchRow,
  MaWatchTeamImg,
  MaWrapper,
  PlayerStat,
} from "../styles/MatchupAnalysis.styles"
import { sportslineAnalysisComingSoonText, matchupAnalysisKeys } from "../../../../common/constants"
import { recordFor, getWeatherIconToUse } from "../../../../common/misc-utils"

const BarChart = ({ percent = 0, className = "" }) => {
  return (
    <BarChartOuter className={className}>
      <BarChartInner style={{ width: `${percent < 8 ? 8 : percent}%` }}>{`${percent}%`}</BarChartInner>
    </BarChartOuter>
  )
}

const MaKeyStatVal = ({ side, num, stat }) => {
  if (num === null) {
    return <strong className={side}>--</strong>
  }
  // percentile is: "rawfg3percentage": "0.318",
  return (
    <strong className={side}>
      {stat.type === "percentile" ? toTenth(parseFloat(num || "0.0") * 100) : num}
      {(stat.type === "rank" && <sup>{toRank(num, true)}</sup>) || (stat.type === "percentile" && "%") || null}
    </strong>
  )
}

const formatRecordForExpert = (rawRecord) => {
  const { correct, incorrect, push } = rawRecord
  const record = [correct || 0, incorrect || 0]
  if (push) {
    record.push(push)
  }
  return record.join("-")
}

const getRecordFor = (node: any, attr: string, prefix?: string | null): string => {
  const value = node[`${prefix || ""}${attr}`] || node[attr] || ""
  const parts = value.split("-")
  if (parts.length === 3 && parts[2] === "0") {
    parts.pop()
  }
  return parts.join("-")
}

const toSpread = (spread: string | number, flip = false, addPlus = true) => {
  if (!spread) {
    return ""
  }
  try {
    const stringSpread = spread.toString()
    const num = parseFloat(stringSpread) * (flip ? -1 : 1)
    return `${num && num > 0 ? (addPlus ? "+" : "") : "-"}${stringSpread.replace("+", "").replace("-", "")}`
  } catch (err) {
    window.SH_ERROR_NOTIFY(err)
    return spread
  }
}

const maSides = [awayValue, "divider", homeValue]

const injuryMapping = {
  QUESTIONABLE: {
    label: "Questionable",
    style: { color: palette.injuryRed },
  },
  PROBABLE: {
    label: "Probable",
    style: { color: palette.injuryRed },
  },
  DOUBTFUL: {
    label: "Doubtful",
    style: { color: palette.injuryRed },
  },
  INACTIVE: {
    label: "Inactive",
    style: { color: palette.injuryRed },
  },
  "RESERVE-EX": {
    label: "Reserve Exempt",
    style: { color: palette.injuryRed },
  },
  "RESERVE-CEL": {
    label: "Reserve-Cel",
    style: { color: palette.injuryRed },
  },
  "RESERVE-COVID-19": {
    label: "Reserve Covid-19",
    style: { color: palette.injuryRed },
  },
  "PUP-R": {
    label: "PUP",
    style: { color: palette.injuryRed },
  },
  OUT: {
    label: "Out",
    style: { color: palette.injuryRed },
  },
  OFS: {
    label: "Out",
    style: { color: palette.injuryRed },
  },
  SUSPENSION: {
    label: "Suspension",
    style: { color: palette.injuryRed },
  },
  SUSPENDED: {
    label: "Suspended",
    style: { color: palette.injuryRed },
  },
  IR: {
    label: "Injured Reserve",
    style: { color: palette.injuryRed },
  },
  "IR-R": {
    label: "Injured Reserve",
    style: { color: palette.injuryRed },
  },
}

const maPlayerStatsMapping = ["PPG", "RPG", "APG"]
const MaBracketPlayer = ({ apiTeam, player, sportType }) => {
  const stats = (player.keyStats || []).filter((x) => maPlayerStatsMapping.includes(x.label))
  return (
    <MaBracketPlayerS>
      <MaBracketPlayerJersey>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41 51">
          {/* tslint:disable-next-line */}
          <path
            d="M12.6 0L4.7 1.6s1.1 5.5 1.1 11.7C5.8 19.4 0 25.8 0 25.8V51h41V25.8s-5.4-6.5-5.4-12.6.7-11.6.7-11.6L28.4.1s0 7.9-7.9 7.9-7.9-8-7.9-8z"
            fill={`#${apiTeam.team_color}`}
          />
        </svg>
        <span>{player.jersey_number || 0}</span>
        <img src={teamSrc(apiTeam, sportType)} loading="lazy" alt="player" />
      </MaBracketPlayerJersey>
      <MaBracketPlayerInfo>
        <h5>{player.name}</h5>
        <div>{player.positionAbbrev}</div>
      </MaBracketPlayerInfo>
      <MaBracketPlayerStats>
        {stats.map((stat) => (
          <PlayerStat key={stat.id}>
            <strong>{stat.label}</strong>
            <span>{stat.formattedValue || "-"}</span>
          </PlayerStat>
        ))}
      </MaBracketPlayerStats>
    </MaBracketPlayerS>
  )
}
const MaSectionAlt = ({ children, title, isBracket, bgType = "white", hr = false, className = "" }) => {
  if (isBracket) {
    return (
      <MaSectionHeaderAltS className={`${className} bg__${bgType}`}>
        <MaSectionHeaderAltH className={`${className} bg__${bgType}`}>{title}</MaSectionHeaderAltH>
        {children}
      </MaSectionHeaderAltS>
    )
  } else {
    return (
      <>
        {hr && <MaHr />}
        <MaSectionHeader gray={bgType === "gray"}>{title}</MaSectionHeader>
        {children}
      </>
    )
  }
}
const MaSectionHeader = ({ children, gray = false }) => (
  <MaSectionHeaderS className={gray ? "gray-bg" : ""}>
    <h2 className={gray ? "gray-bg" : ""}>{children}</h2>
  </MaSectionHeaderS>
)

const emptyObject = {} as any
const emptyArray = [] as any[]

const withParenthesis = (str?: string, prefix?: string) => (str ? `${prefix ? prefix + " " : ""}(${str})` : "\u00A0")
const fakeMaApiTeam = {
  record: {
    away: "",
    conference: "",
    home: "",
    last_5: "",
    overall: "",
    standing: "",
    vs_top_25: "",
    vs_top_50: "",
  },
  injury_report: [],
}

interface IProps {
  variables: MatchupAnalysisQueryVariables
  isOpen: boolean
  event?: IPickUtilsEvent
  matchup?: IBracketUtilMatchup
  pickUtils: PickUtils
  closeModal: any
  afterModalClose: any
  log: any
  pick?: IPickUtilsPick | null
  team?: IPickUtilsTeam
  homeTeam: IPickUtilsTeam
  pickTeam?: any
  periodId: string
  isMine: boolean
  gameInstanceUid: string
  showSaveAnimation: boolean
  canUseTeamLogos: boolean
  sportslineKey: string
  nextPickableModalKey: string | null
  prevPickableModalKey: string | null
  season: any
  isCbsAppWebview: boolean
  lastScoredAt?: number
}

class MatchupAnalysis extends React.PureComponent<IProps> {
  public getStatForKey(stat: string, teamComparison: MatchupAnalysisQuery_matchupAnalysis_awayTeamAnalysis_teamComparison[] = []) {
    return teamComparison.find((teamStat) => teamStat.key === stat)
  }

  public getLineFromGameData(event: IPickUtilsEvent, line: string, pick?: string) {
    let lineToUse = line
    if (pick) {
      const lineToUseAsNum = Number(lineToUse)
      if (pick === event.awayTeam?.abbrev) {
        // Note LL: IND @ JAX - Line = +7.5
        if (lineToUseAsNum > 0) {
          lineToUse = `-${lineToUseAsNum}`
        }
        // Note LL: HOU @ KC - Line = -9.5
        else if (lineToUseAsNum < 0) {
          lineToUse = `+${Math.abs(lineToUseAsNum)}`
        }
      }
    }
    return lineToUse
  }

  public render() {
    const maProps = this.props
    const {
      closeModal,
      afterModalClose,
      pickUtils,
      log,
      pickTeam,
      isMine,
      gameInstanceUid,
      lastScoredAt,
      showSaveAnimation,
      canUseTeamLogos,
      season,
      event,
      matchup,
      homeTeam,
    } = maProps
    const isComingSoon = !event
    return (
      <Modal
        modalType={isComingSoon ? "dialog" : "modal"}
        className={isComingSoon ? undefined : "modal--matchup-analysis"}
        isOpen={maProps.isOpen}
        onBackgroundClick={closeModal}
        afterClose={afterModalClose}
      >
        <Query<MatchupAnalysisQuery, MatchupAnalysisQueryVariables> query={MATCHUP_ANALYSIS_QUERY} variables={maProps.variables}>
          {(matchupAnalysisQuery) => {
            // if (!maProps.isOpen) {
            //   return null;
            // }
            // console.dir(matchupAnalysisQuery)
            // console.dir(maProps);
            const isLoading = matchupAnalysisQuery.loading
            if (isLoading) {
              return <LoadingView />
            }

            if (!event) {
              return (
                <ModalWrapper
                  header="Matchup Analysis coming soon!"
                  icon={<Logo gameInstanceUid={gameInstanceUid} season={season} />}
                  variant="white"
                  isDialog={true}
                  closeBtnAction={closeModal}
                >
                  <p>Come back soon to see stats and comparisons to help you make your picks!</p>
                </ModalWrapper>
              )
            }
            const sportType = pickUtils.sportType
            const isBracket = pickUtils.isBracket()
            const seasonType = season.season
            const isCollegeSport = pickUtils.isCollegeSport()
            const spread = event && pickUtils.getSpreadFor(event)
            const analysis = matchupAnalysisQuery?.data?.matchupAnalysis || (emptyObject as MatchupAnalysisQuery_matchupAnalysis)
            const openingOrPickHomeSpread = pickUtils.displayFormattedSpreadFor(event, true, false, undefined, undefined, true)
            const latestHomeSpread = pickUtils.displayFormattedSpreadFor(event, true, false)
            log(analysis)
            // console.dir(event);
            // console.dir(analysis);
            const { awayTeamAnalysis, homeTeamAnalysis, sportslinePrediction, teamComparisonOrder, expertPicks, event: analysisEvent } = analysis
            const awayHasStats = awayTeamAnalysis?.hasStats
            const homeHasStats = homeTeamAnalysis?.hasStats
            const analysisOddsMarket = analysisEvent?.oddsMarket || emptyObject
            const awayOdds = {
              moneyLine: analysisOddsMarket?.moneyLines?.find(({ teamId }) => event.awayTeam?.id === teamId) || emptyObject,
              totals: analysisOddsMarket?.totals?.[0] || emptyObject, // FIXME LL: Need to figure out which one to get
              spreads: analysisOddsMarket?.spreads?.find(({ teamId }) => event.awayTeam?.id === teamId) || emptyObject,
            }
            const homeOdds = {
              moneyLine: analysisOddsMarket?.moneyLines?.find(({ teamId }) => event.homeTeam?.id === teamId) || emptyObject,
              totals: analysisOddsMarket?.totals?.[0] || emptyObject, // FIXME LL: Need to figure out which one to get
              spreads: analysisOddsMarket?.spreads?.find(({ teamId }) => event.homeTeam?.id === teamId) || emptyObject,
            }
            const venue = analysisEvent?.venueInformation
            const { stadiumCity, stadiumState } = venue || emptyObject
            const venueLocation = venue ? `${stadiumCity}, ${stadiumState}` : undefined
            const weather = analysisEvent?.weather || emptyObject
            const {
              confidencePercent,
              hasGameAnalysis,
              isSubscriber,
              matchupNote,
              pickedTeamAbbrev,
              projectedAwayTeamScore,
              projectedHomeTeamScore,
              spreadCoverAnalysis,
              upsellCopy,
            } = sportslinePrediction || emptyObject
            /*
              TODO/FIXME LL - get link, do we need this anymore? I can't find it in existing api, but was there before.
              Sample Data Structure:
              {
                url: "/nfl/gametracker/preview/NFL_20181223_CHI@SF/",
                type: "preview", // we do not use type
                label: "Preview",
              },
            */
            const link = undefined as any
            const sportslineUpsellCopy = upsellCopy || ""
            const sportslineAnalysis = isSubscriber
              ? matchupNote || sportslineAnalysisComingSoonText
              : sportslineUpsellCopy || sportslineAnalysisComingSoonText
            const sportslinePickTeam =
              (pickedTeamAbbrev && (pickedTeamAbbrev === event?.homeTeam?.abbrev ? event?.homeTeam : event?.awayTeam)) || FakePickUtilsTeam
            const spotrslineIsSub = !!isSubscriber || false
            const sportslineHasData = hasGameAnalysis
            const projectedScore = {
              [awayValue]: {
                projectedScore: projectedAwayTeamScore,
                abbr: event.awayTeam?.abbrev,
              },
              [homeValue]: {
                projectedScore: projectedHomeTeamScore,
                abbr: event.homeTeam?.abbrev,
              },
            }
            // Hiding Odds for bracket games since they will only be relevant in the first round of games since those are the only actual games set with odds
            const hideOdds = isBracket ? 1 : 0
            const subUrl = `https://www.sportsline.com/join/?ttag=${buildTtag(season.productAbbrev, season.year, "FF", `${gameInstanceUid}-matchup`)}`
            const expertNodeName = spread ? "againstTheSpread" : "straightUp"
            const expertPicksGameData = expertPicks?.gameData || emptyObject
            const experts = expertPicks?.expertsData
              .map((expert) => {
                const { expertId, imageUrl, name, byLine, recordData } = expert
                const expertPick = expertPicksGameData[expertNodeName].find((e) => {
                  return e.expertId === expertId
                })
                const recordTimeframe = "seasonRecord"
                const record = formatRecordForExpert(recordData[recordTimeframe][expertNodeName] || emptyObject)
                return {
                  photo: imageUrl || placeholderImg,
                  content: name || expertId,
                  line: byLine,
                  record,
                  pick: expertPick?.pick,
                }
              })
              .filter((expert) => !!expert.pick)
            const showOverview = !isBracket
            const showSportsline = spotrslineIsSub
            const sportslineSpread = spreadCoverAnalysis || (isBracket ? "Our Pick" : "TBD")
            const sportslineConfidence = parseFloat(`${confidencePercent || 0}`)
            const hideExperts = !experts || experts?.length === 0 || isBracket
            const weatherIconToUse = getWeatherIconToUse(weather.icon)

            return (
              <MaWrapper className={`ma-type__${isBracket ? "bracket" : "nfl"} is-college--${isCollegeSport}`}>
                <AnalyticScreen feature="picks" subfeature={`matchup analysis`} title={`Picks - Make Picks - Matchup Analysis`} isModal={true} />
                <MaHeader>
                  <MaBlueHeader />
                  {isBracket ? (
                    <MaBracketHeader>
                      <MaCloseBtn as="button" onClick={closeModal}>
                        <CloseSvg />
                      </MaCloseBtn>
                    </MaBracketHeader>
                  ) : (
                    <MaRedHeader>
                      <MaHGameTime>{`${(event && DateTime.fromMillis(event.startsAt).toFormat("EEE L/dd h:mma ZZZZ")) || "--"} - ${
                        event && event.tvInfoName
                      }`}</MaHGameTime>
                      <MaHGameWeather>
                        {(weatherIconToUse && (
                          <MaHWeatherIcon>
                            <img alt={weatherIconToUse.description} src={weatherIconToUse.url} />
                          </MaHWeatherIcon>
                        )) ||
                          null}
                        {venueLocation || <Nbsp />}
                      </MaHGameWeather>
                      <MaCloseBtn as="button" onClick={closeModal}>
                        <CloseSvg />
                      </MaCloseBtn>
                    </MaRedHeader>
                  )}
                </MaHeader>
                {isBracket ? (
                  <MaImgLogoContainer />
                ) : (
                  <MaLogoContainer>
                    <Logo gameInstanceUid={gameInstanceUid} season={season} />
                  </MaLogoContainer>
                )}
                <MaContent>
                  <MaPicks>
                    <PickemEvent
                      eventId={event.id}
                      matchupId={matchup?.id}
                      pickTeam={pickTeam}
                      cacheBuster={pickUtils.cacheKeyFor(event)}
                      isMine={isMine}
                      lastScoredAt={lastScoredAt}
                      pickUtils={pickUtils}
                      showSaveAnimation={showSaveAnimation}
                      delaySaveCommit={true}
                      useOutsideSaveAnimation={false}
                      log={log}
                      canUseTeamLogos={canUseTeamLogos}
                      mode="ma"
                    />
                  </MaPicks>
                  {/*<SSS />
                  <SSR />
                  <MaTeamsMobile>
                    {maSides.map((side) => {
                      if (side === "divider") {
                        return (<MaPicksDivider key={side} />);
                      }
                      const team = event && event[`${side}Team`] || FakePickUtilsTeam;
                      const apiTeam = analysis[`${side}_team`] || fakeMaApiTeam;
                      const record = event && event.weekNumber > 1 && recordFor(team) || getRecordFor(recordData[team.abbrev] || emptyObject, "record");
                      // const isPicked = !!pick && pick.itemId === team.id;
                      return (
                        <MaTeamInfo key={side} className={`in-content side__${side} is-bracket--${isBracket}`}>
                          <h3>{adjustedLocationName(team)}</h3>
                          <h4>{isBracket ? withParenthesis(apiTeam.record.overall) : (isCollegeSport && team.nickName || teamLongName(team, true))}</h4>
                          <div>{isBracket ? withParenthesis(apiTeam.record.conference, apiTeam.record.standing) : record}</div>
                        </MaTeamInfo>
                      );
                    })}
                  </MaTeamsMobile>*/}
                  {!isBracket && (
                    <MaMobileVenueInfo>
                      <strong>{`${(event && DateTime.fromMillis(event.startsAt).toFormat("EEE L/dd h:mma ZZZZ")) || "--"} - ${
                        event && event.tvInfoName
                      }`}</strong>
                      <div>
                        {(weatherIconToUse && (
                          <MaHWeatherIcon className="mobile-weather-icon">
                            <img alt={weatherIconToUse.description} src={weatherIconToUse.url} />
                          </MaHWeatherIcon>
                        )) ||
                          null}
                        {venueLocation || <Nbsp />}
                      </div>
                    </MaMobileVenueInfo>
                  )}
                  <MaRecords className="gray-bg">
                    {maSides.map((side) => {
                      if (side === "divider") {
                        return <MaPicksDivider key={side} />
                      }
                      const teamAnalysis = (side === homeValue ? homeTeamAnalysis : awayTeamAnalysis) || emptyObject
                      const situationalStats = teamAnalysis.situationalStatistics || emptyArray
                      const sportslineTeamAnalysis = teamAnalysis.sportslineTeamAnalysis || emptyObject
                      // Seems like nfl week 1 doesnt have records, but 0-0-0 instead
                      const atsRecord = sportslineTeamAnalysis.allGamesRecord?.spread
                      const hasAtsRecord = spread && atsRecord && atsRecord !== "--"
                      let formerSeasonLabel = "RECORD"
                      if (hasAtsRecord && formerSeasonLabel) {
                        formerSeasonLabel = formerSeasonLabel.replace("RECORD", "ATS")
                      }
                      // This is only going to be for Conf Brackets
                      if (isBracket) {
                        const bracketRecordObject = {
                          conference: "--",
                          vsTop25: "--",
                          overall: recordFor(teamAnalysis.team),
                        }
                        const netRank = teamAnalysis.rank
                        for (const teamComparisonObj of teamAnalysis.teamComparison) {
                          switch (teamComparisonObj.key) {
                            case matchupAnalysisKeys.teamComparison.vsTop25:
                              bracketRecordObject.vsTop25 = teamComparisonObj.formattedValue
                              break
                            default:
                              break
                          }
                        }

                        for (const situationalStatisticsObj of teamAnalysis.situationalStatistics) {
                          switch (situationalStatisticsObj.key) {
                            case matchupAnalysisKeys.situationalStats.conferenceRecord:
                              bracketRecordObject.conference = situationalStatisticsObj.formattedValue
                              break
                            default:
                              break
                          }
                        }

                        return (
                          <MaRecord key={side}>
                            <MaStat>
                              <strong>{getRecordFor(bracketRecordObject, "conference")}</strong>
                              <span>{pickUtils.period.description || ""}</span>
                            </MaStat>
                            <MaStat>
                              <strong>{toRank(netRank) || "--"}</strong>
                              <span>NET</span>
                            </MaStat>
                            <MaStat>
                              <strong>{getRecordFor(bracketRecordObject, "vsTop25")}</strong>
                              <span>VS TOP 25</span>
                            </MaStat>
                          </MaRecord>
                        )
                      } else {
                        // NOTE qac: ats_record is NOT last 5 games, whereas 'record' is... decision is to use the ats if ats
                        // const ytdLabel = formerSeasonLabel || (spread && "ATS RECORD") || "LAST 5 GAMES"
                        let situationalStatsMapping = {}
                        if (situationalStats.length > 0) {
                          situationalStatsMapping = situationalStats.reduce((mapping, situationalStat) => {
                            return {
                              ...mapping,
                              [situationalStat.key]: situationalStat,
                            }
                          }, {})
                        }
                        const lastGamesRecordLabel = situationalStatsMapping["lastGamesRecord"]?.label.toUpperCase() || "LAST GAMES"
                        const lastGamesRecord = situationalStatsMapping["lastGamesRecord"]?.formattedValue || "--"
                        const ytdLabel = (spread && hasAtsRecord && "ATS RECORD") || lastGamesRecordLabel
                        const ytdRecord = (ytdLabel === "ATS RECORD" && atsRecord) || lastGamesRecord

                        const sideRecord = situationalStatsMapping[`${side}Record`]?.formattedValue || "--"
                        return (
                          <MaRecord key={side}>
                            <MaStat>
                              {/*NOTE qac: ats_record is NOT last 5 games, whereas 'record' is... decision is to use the ats if ats*/}
                              <strong>{pickUtils?.period?.order === 1 ? "0-0" : ytdRecord}</strong>
                              <span>{ytdLabel}</span>
                            </MaStat>
                            {(isCollegeSport && seasonType === "post" && (
                              <MaStat>
                                <strong>{lastGamesRecord}</strong>
                                <span>{lastGamesRecordLabel}</span>
                              </MaStat>
                            )) || (
                              <MaStat>
                                <strong>{pickUtils?.period?.order === 1 ? "0-0" : sideRecord}</strong>
                                <span>{situationalStatsMapping[`${side}Record`]?.label.toUpperCase() || "RECORD"}</span>
                              </MaStat>
                            )}
                            {isCollegeSport ? (
                              <MaStat>
                                <strong>{toRank(teamAnalysis.rank) || "--"}</strong>
                                <span>AP RANK</span>
                              </MaStat>
                            ) : (
                              <MaStat>
                                <strong>{toRank(teamAnalysis.rank) || "--"}</strong>
                                <span>{"POWER RANK"}</span>
                              </MaStat>
                            )}
                          </MaRecord>
                        )
                      }
                    })}
                  </MaRecords>
                  {!hideOdds && !isCollegeSport && false && <MaSectionSubHeader>Provided by Caesars Sportsbook</MaSectionSubHeader>}
                  {hideOdds
                    ? null
                    : (isLoading && <LoadingView color="rgba(0,0,0,0.6)" />) || (
                        <MaOdds>
                          {maSides.map((side) => {
                            const oddsToUse = side === homeValue ? homeOdds : awayOdds
                            if (side === "divider") {
                              const totalOddsToUse = homeOdds.totals || awayOdds.totals || emptyObject
                              return (
                                <MaOddCenter key={side}>
                                  <div>
                                    <strong>Opening</strong>
                                    <span>
                                      {homeTeam.abbrev}: {openingOrPickHomeSpread}
                                    </span>
                                    <span>
                                      O/U:{" "}
                                      {(totalOddsToUse && toSpread(totalOddsToUse.openingTotal || totalOddsToUse.total, undefined, false)) || "-"}
                                    </span>
                                  </div>
                                  <div>
                                    <strong>Latest</strong>
                                    <span>
                                      {homeTeam.abbrev}: {latestHomeSpread}
                                    </span>
                                    <span>O/U: {(totalOddsToUse && toSpread(totalOddsToUse.total, undefined, false)) || "-"}</span>
                                  </div>
                                </MaOddCenter>
                              )
                            }
                            return (
                              <MaOdd key={side}>
                                <div>{(oddsToUse && oddsToUse.moneyLine && toSpread(oddsToUse.moneyLine.odds)) || "--"}</div>
                              </MaOdd>
                            )
                          })}
                        </MaOdds>
                      )}
                  {(link && (
                    <MaWatchRow to={`https://www.cbssports.com${link.url}`} target="_blank">
                      {maSides.map((side) => {
                        if (side === "divider" || !canUseTeamLogos) {
                          return null
                        }
                        const team = (event && event[`${side}Team`]) || FakePickUtilsTeam
                        return <MaWatchTeamImg key={side} className={`side__${side}`} alt={teamLongName(team)} src={teamSrc(team, sportType)} />
                      })}
                      <PlaySvg />
                      <div>{`Watch Game ${link.label}`}</div>
                    </MaWatchRow>
                  )) ||
                    null}
                  {false /* short circuiting for team overview for brackets */ && isBracket && (
                    <MaSectionAlt title={`Team Overview`} className="large-only" isBracket={isBracket}>
                      {showOverview && (
                        <MaSplitRow>
                          {maSides.map((side) => {
                            if (isLoading) {
                              if (side === "divider") {
                                return <LoadingView key={side} color="rgba(0,0,0,0.6)" />
                              } else {
                                return null
                              }
                            }
                            if (side === "divider") {
                              // <MaVrtDivider key={side} />
                              return null
                            }
                            // const team = event && event[`${side}Team`] || FakePickUtilsTeam;
                            const apiTeam = analysis[`${side}_team`] || fakeMaApiTeam
                            const teamAnalysis = (side === homeValue ? homeTeamAnalysis : awayTeamAnalysis) || emptyObject
                            const percOwned = teamAnalysis.userPickPercent
                            return (
                              <MaTeamOverviewTeam key={side} className={`side__${side}`}>
                                {percOwned && (
                                  <div>
                                    <strong>{percOwned}%</strong>
                                    <span>USER PICK</span>
                                  </div>
                                )}
                                <p>{apiTeam.team_outlook || `No outlook available.`}</p>
                              </MaTeamOverviewTeam>
                            )
                          })}
                        </MaSplitRow>
                      )}
                    </MaSectionAlt>
                  )}
                  <React.Fragment>
                    <MaSectionHeader>Sportsline</MaSectionHeader>
                    <MaExpertRow
                      className={`has-overview--${showOverview} is-coming-soon--${
                        showSportsline && !sportslineHasData
                      } sportsline-full is-bracket--${isBracket}`}
                    >
                      <div className="sportsline-col--left">
                        <SportsLineSvg color="#40CE22" />
                        <MaExpertText className={`variable-text is-bracket--${isBracket}`}>
                          {sportslineHasData && <strong>MATCHUP NOTE:</strong>}
                          {/* replace the first html tag with nothing (ul), replace the rest (li's) with br's */}
                          {<div dangerouslySetInnerHTML={{ __html: sportslineAnalysis.replace(/<[^>]*>/, "").replace(/<[^>]*>/g, "<br>") }} />}
                        </MaExpertText>
                      </div>
                      {showSportsline ? (
                        <div className={`sportsline-col--right is-bracket--${isBracket}`}>
                          <div className="pick">
                            {(sportslineHasData && (
                              <img alt={sportslinePickTeam.nickName} src={teamSrc(sportslinePickTeam, sportType)} loading="lazy" />
                            )) ||
                              null}
                            <div>{sportslineSpread}</div>
                          </div>
                          <div className="simulated-score">
                            <p>
                              <strong>Simulated Score</strong>
                            </p>
                            {maSides.map((side) => {
                              // NOTE qac: ncaab has `home_projected_score` on the root node
                              const obj = projectedScore[side]
                              if (obj) {
                                return (
                                  <p key={side}>
                                    {obj.abbr}: {obj.projectedScore || "-"}
                                  </p>
                                )
                              } else {
                                return null
                              }
                            })}
                          </div>
                          <div className="confidence">
                            <BarChart percent={sportslineConfidence} />
                            <p>Pick Confidence</p>
                          </div>
                        </div>
                      ) : (
                        <MaExpertMain className={`sportsline-col--right is-bracket--${isBracket}`}>
                          <LinkRow className="expert-cta" to={subUrl || sportslinePrediction?.subUrl}>
                            <LockSvg color="#717171" />
                            Subscribe To Unlock
                          </LinkRow>
                        </MaExpertMain>
                      )}
                    </MaExpertRow>
                  </React.Fragment>
                  {!hideExperts && (
                    <React.Fragment>
                      <MaSectionHeader>Experts</MaSectionHeader>
                      <MaExpertRowTeams>
                        {maSides.map((side) => {
                          if (side === "divider") {
                            return <MaPicksDivider key={side} />
                          }
                          const team = (event && event[`${side}Team`]) || FakePickUtilsTeam
                          const ratio = experts?.length
                            ? `${experts?.filter((expert) => expert.pick === team.abbrev).length}/${experts?.length || 1}`
                            : "0/0"
                          if (canUseTeamLogos) {
                            return (
                              <MaExpertTeam key={side}>
                                <TeamImg team={team} canUseTeamLogos={canUseTeamLogos} />
                                <div>{ratio}</div>
                              </MaExpertTeam>
                            )
                          }
                          return (
                            <MaExpertTeam key={side}>
                              <div>
                                {team.abbrev}: {ratio}
                              </div>
                            </MaExpertTeam>
                          )
                        })}
                      </MaExpertRowTeams>
                    </React.Fragment>
                  )}
                  {hideExperts
                    ? null
                    : (isLoading && <LoadingView color="rgba(0,0,0,0.6)" />) || (
                        <MaExperts as="ul">
                          {experts?.map((expert, i) => {
                            const team = (event && (homeTeam.abbrev === expert.pick ? event.homeTeam : event.awayTeam)) || FakePickUtilsTeam
                            return (
                              <MaExpert key={i}>
                                <AvatarBase alt={expert.content} src={expert.photo} />
                                <small>{expert.content}</small>
                                <div>{expert.record}</div>
                                {canUseTeamLogos ? (
                                  <>
                                    <TeamImg team={team} canUseTeamLogos={canUseTeamLogos} />
                                    <div>{teamLongName(team, canUseTeamLogos, true)}</div>
                                    <strong>{this.getLineFromGameData(event, expertPicksGameData.line, expert.pick)}</strong>
                                  </>
                                ) : (
                                  <strong>
                                    {team.abbrev} {this.getLineFromGameData(event, expertPicksGameData.line, expert.pick)}
                                  </strong>
                                )}
                              </MaExpert>
                            )
                          })}
                        </MaExperts>
                      )}
                  <MaSectionAlt isBracket={isBracket} bgType={`gray`} title={`Key Statistics`} hr={true}>
                    <MaKeyStatWrapper className="gray-bg">
                      {teamComparisonOrder
                        ?.filter((x) => x !== matchupAnalysisKeys.teamComparison.netRank)
                        .map((stat, index) => {
                          const awayStat =
                            awayTeamAnalysis?.teamComparison[index]?.key === stat
                              ? awayTeamAnalysis?.teamComparison[index]
                              : this.getStatForKey(stat, awayTeamAnalysis?.teamComparison)
                          const homeStat =
                            homeTeamAnalysis?.teamComparison[index]?.key === stat
                              ? homeTeamAnalysis?.teamComparison[index]
                              : this.getStatForKey(stat, homeTeamAnalysis?.teamComparison)
                          const showRank = stat === matchupAnalysisKeys.teamComparison.strengthOfSchedule
                          const numLeft = (showRank ? toRank(Number(awayStat?.rank)) : awayStat?.formattedValue) || 0
                          const numRight = (showRank ? toRank(Number(homeStat?.rank)) : homeStat?.formattedValue) || 0
                          // Note LL: since away and home stats are guaranteed to come back, we can pick an arbitrary stat here to get meta data on
                          const goodIcon = awayStat?.isInverted || showRank ? null : <CheckmarkSvg color={palette.green} />
                          const badIcon = awayStat?.isInverted || showRank ? <CheckmarkSvg color={palette.green} /> : null
                          const isEqual = numLeft == numRight || numLeft === null || numRight === null || !awayHasStats || !homeHasStats
                          let leftIcon: JSX.Element | null = null
                          let rightIcon: JSX.Element | null = null
                          if (stat === matchupAnalysisKeys.teamComparison.vsTop25 && !isEqual) {
                            const [awayTeamWins, awayTeamLosses, awayTeamTies] = String(numLeft).split("-").map(Number)
                            const [homeTeamWins, homeTeamLosses, homeTeamTies] = String(numRight).split("-").map(Number)
                            if (awayTeamWins !== homeTeamWins) {
                              leftIcon = awayTeamWins > homeTeamWins ? goodIcon : badIcon
                              rightIcon = awayTeamWins < homeTeamWins ? goodIcon : badIcon
                            } else if (awayTeamTies !== homeTeamTies) {
                              leftIcon = (awayTeamTies || 0) > (homeTeamTies || 0) ? goodIcon : badIcon
                              rightIcon = (awayTeamTies || 0) < (homeTeamTies || 0) ? goodIcon : badIcon
                            } else {
                              leftIcon = awayTeamLosses < homeTeamLosses ? goodIcon : badIcon
                              rightIcon = awayTeamLosses > homeTeamLosses ? goodIcon : badIcon
                            }
                          } else {
                            leftIcon = Number(numLeft) > Number(numRight) ? goodIcon : badIcon
                            rightIcon = Number(numLeft) < Number(numRight) ? goodIcon : badIcon
                          }
                          return (
                            <MaKeyStatRow key={stat}>
                              <span>{isEqual ? "" : leftIcon}</span>
                              <MaKeyStatVal side="left" num={awayHasStats ? numLeft : "-"} stat={stat} />
                              <div>{awayStat?.label || homeStat?.label || ""}</div>
                              <MaKeyStatVal side="right" num={homeHasStats ? numRight : "-"} stat={stat} />
                              <span>{isEqual ? "" : rightIcon}</span>
                            </MaKeyStatRow>
                          )
                        })}
                    </MaKeyStatWrapper>
                  </MaSectionAlt>
                  {isBracket ? (
                    <MaTeamsSection>
                      {maSides.map((side) => {
                        if (side === "divider") {
                          return null
                        }
                        const team = (event && event[`${side}Team`]) || FakePickUtilsTeam
                        const teamAnalysis = (side === homeValue ? homeTeamAnalysis : awayTeamAnalysis) || emptyObject
                        const apiTeam = teamAnalysis?.team || fakeMaApiTeam
                        const keyPlayer = teamAnalysis?.keyPlayers?.[0]
                        const injuryReport = teamAnalysis?.injuredPlayers
                        const confRecord = teamAnalysis.situationalStatistics?.find(
                          (x) => x.key === matchupAnalysisKeys.situationalStats.conferenceRecord,
                        )

                        // NOTE LL: need to verify this will work since this is brackets and maSides is "home" and "away"
                        const percOwned = teamAnalysis.userPickPercent
                        return (
                          <MaTeamSection key={side} className={`side__${side}`}>
                            <MaSectionAlt className={`small-only side__${side}`} isBracket={true} title={`Team Overview`}>
                              <MaMobileTeamOverview>
                                <div>
                                  <MaTeamImg alt={teamLongName(team, true)} src={teamSrc(team, sportType)} />
                                  <MaTeamInfo>
                                    <h3>{team.abbrev}</h3>
                                    <h4>{withParenthesis(`${apiTeam.wins}-${apiTeam.losses}`)}</h4>
                                    <div>{confRecord?.formattedValue}</div>
                                    <i>{pickUtils.matchupSeedFor(team.id)}</i>
                                  </MaTeamInfo>
                                </div>
                                {showOverview && (
                                  <MaTeamOverviewTeam className={`side__${side}`}>
                                    {percOwned && (
                                      <div>
                                        <strong>{percOwned}%</strong>
                                        <span>USER PICK</span>
                                      </div>
                                    )}
                                  </MaTeamOverviewTeam>
                                )}
                              </MaMobileTeamOverview>
                            </MaSectionAlt>
                            <MaSectionAlt className={`side__${side}`} isBracket={true} title={`Key Player`}>
                              {keyPlayer && <MaBracketPlayer apiTeam={apiTeam} player={keyPlayer} sportType={sportType} />}
                            </MaSectionAlt>
                            <MaSectionAlt isBracket={true} className={`side__${side}`} title={`Injury Report`}>
                              {(injuryReport || []).map((player, i) => {
                                return (
                                  <React.Fragment key={i}>
                                    <MaBracketPlayer apiTeam={apiTeam} player={player} sportType={sportType} />
                                    <MaBracketPlayerInjury>
                                      <strong>{player?.injuryType}:</strong>
                                      {player?.estReturn}
                                    </MaBracketPlayerInjury>
                                  </React.Fragment>
                                )
                              })}
                            </MaSectionAlt>
                          </MaTeamSection>
                        )
                      })}
                    </MaTeamsSection>
                  ) : (
                    <>
                      <MaHr />
                      <MaInjuries>
                        {maSides.map((side) => {
                          if (isLoading) {
                            if (side === "divider") {
                              return <LoadingView key={side} color="rgba(0,0,0,0.6)" />
                            } else {
                              return null
                            }
                          }
                          if (side === "divider") {
                            return null
                          }
                          const team = (event && event[`${side}Team`]) || FakePickUtilsTeam
                          const teamAnalysis = (side === homeValue ? homeTeamAnalysis : awayTeamAnalysis) || emptyObject
                          const { injuredPlayers: myInjuries } = teamAnalysis
                          const limit = 4
                          return (
                            <MaInjuryCol key={side} className={`side__${side}`}>
                              <MaInjuryHeader className={`side__${side}`}>
                                {canUseTeamLogos && <TeamImg team={team} canUseTeamLogos={canUseTeamLogos} />}
                                <h4>{canUseTeamLogos ? "" : team.abbrev} Injury Report</h4>
                              </MaInjuryHeader>
                              {myInjuries?.map((player, i) => {
                                if (i >= limit) {
                                  return null
                                }
                                const injury = injuryMapping[player.injuryStatus?.toUpperCase() || ""] || {
                                  label: player.injuryStatus,
                                }
                                return (
                                  <MaInjury key={player.id}>
                                    {isCollegeSport ? <TeamImg team={team} canUseTeamLogos={false} /> : <AvatarBase src={player.imgUrl} />}
                                    <div>
                                      <div>
                                        <i>{player.positionAbbrev}</i>
                                        <strong>{player.name}</strong>
                                      </div>
                                      {injury.label ? (
                                        <div>
                                          <strong style={injury.style || {}}>{injury.label}</strong>
                                          <span>{player.injuryType}</span>
                                        </div>
                                      ) : (
                                        <div>
                                          <strong>{player.injuryType}</strong>
                                        </div>
                                      )}
                                    </div>
                                  </MaInjury>
                                )
                              })}
                            </MaInjuryCol>
                          )
                        })}
                      </MaInjuries>
                    </>
                  )}

                  {/*<ErrorView title={openModalKey} error={matchupAnalysisQuery && matchupAnalysisQuery.data || {}} />*/}
                </MaContent>
              </MaWrapper>
            )
          }}
        </Query>
      </Modal>
    )
  }
}
export default MatchupAnalysis
