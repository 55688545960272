import * as React from "react"
import styled from "styled-components/macro"
import GamblingPartnerAdPlacement from "../../../components/GamblingPartnerAdPlacement"
import OddsByCaesarsSvg from "../../../components/icons/parners/Caesars/OddCaesars"
import { layout, palette, pxToRem } from "../../../utils/style-utils"

const GamblingPartnerBannerContainer = styled.div`
  height: 2.5rem;
  box-sizing: border-box;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${palette.white};
  margin-bottom: ${pxToRem(layout.pageGutterW)};
  margin-bottom: var(--grid-gap);
  border: 1px solid ${palette.cardBorderGray};

  & > div {
    display: flex;
  }
  svg {
    height: 1.5rem;
    width: auto;
  }
`

const GamblingPartnerPickTileIntegration = () => {
  return (
    <GamblingPartnerBannerContainer>
      <GamblingPartnerAdPlacement placement="picks_page_banner">
        <OddsByCaesarsSvg />
      </GamblingPartnerAdPlacement>
    </GamblingPartnerBannerContainer>
  )
}

export default React.memo(GamblingPartnerPickTileIntegration)
