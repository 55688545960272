import React from "react"
import styled, { keyframes } from "styled-components/macro"
import {
  IStyledBracketMatchup,
  IStyledBracketRound,
  IStyledBracketSection,
  IStyledBracketTeam,
  TBracketSection,
} from "../../../../common/common-utils-types"
import { FlexColGrow, FlexRow } from "../../../components/FlexComponents"
import { breakpoints, fontWeight, layout, palette, pxToRem, skyboxH, zIndexes } from "../../../utils/style-utils"
import { bracketSaveAnimMs } from "./Picks.styles"

export interface IisSaving {
  isSaving: boolean
}

export const stroke = keyframes`
100% {
  stroke-dashoffset: 0;
}
`
export const scale = keyframes`
0%, 100% {
  transform: none;
}
50% {
  transform: scale3d(1.3, 1.3, 1);
}
`
export const fill2 = keyframes`
100% {
  box-shadow: inset 0px 0px 0px 80px #FF8900;
}
`
// we want everything to fit within 1280 - 350 (w minus sidebar)
// so lets fugde it to 950 / 8 (8 rounds)
// this gives us round w of ~118px
const bFontSize = 10 // 1em == 10px
const bpxToEm = (px: number) => `${(px / bFontSize).toFixed(2)}em`
const mW = 123
const tH = 22
const mH = tH * 2 + 4 // (bFontSize * 0.2);
const spaceBetweenMatchups = mH * 0.75
const mHplusSpace = mH + spaceBetweenMatchups
const bH = mH * 5 + spaceBetweenMatchups * 4 // 400; // x4 matchups + 3 * 75
const bMaxH = bH // (mH + mH / 2) * 5;
const bMinH = bH // mH * 5;
const bBreakpoint = 1200
const bBreakpointSmallBracket = 1000
const sectionMaxW = mW * 4.75
const sectionMaxWSb = mW * 2
const sectionMidMinWSb = 320
const sectionMinW = 300
// export const mHW = mW / 2
const mBorderW = 1.5
// export const mPadLW = 10;
// export const gutterW = 10;
// a section
// export const minSectionW = 2.5 * mW + 2 * gutterW;
// export const maxSectionW = 3 * (mW + mPadLW) + 2 * gutterW;
export const breakpointBracket = ({ roundCount }) => {
  // console.log(`breakpointBracket: ${roundCount}`);
  return pxToRem(roundCount < 5 ? bBreakpointSmallBracket : bBreakpoint)
}
export const BracketWrapper = styled.div`
  overflow-x: auto;
  overflow-y: visible;
  scroll-snap-destination: 0% 100%;
  scroll-snap-points-x: repeat(100%);
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`
export const BracketContainer = React.memo(styled.div<{ roundCount: number }>`
  display: flex;
  flex-flow: row nowrap;
  max-height: ${bpxToEm(bMaxH)};
  height: 80vh;
  min-height: ${bpxToEm(bMinH)};
  margin: ${pxToRem(18)} 0;
  /* fit most < 800px screen heights without scrollbar */
  font-size: 0.625rem;
  @media (max-width: ${breakpointBracket}) {
    & {
      width: 300%;
      margin: 0;
    }
  }
  @media (max-width: ${pxToRem(breakpoints.handheld)}) and (max-height: 700px) and (min-height: 650px) {
    & {
      /* fit for everything less than 700px but let iphone5 fix thru */
      font-size: 0.5rem;
    }
  }
  @media (min-width: 1600px) and (min-height: 800px) {
    & {
      /* for mid-high end laptops */
      font-size: 0.75rem;
    }
  }
`)
export const BracketSection = React.memo(styled.div<IStyledBracketSection>`
  flex: 1 1 100%;
  height: 100%;
  scroll-snap-align: start;
  @media (max-width: ${breakpointBracket}) {
    & {
      padding: 0 ${bpxToEm(20)};
      width: 100%;
    }
  }
`)
const innerMin3 = ({ section, roundCount }) => {
  // console.log(`innerMin3: ${section} ${roundCount}`);
  return bpxToEm(
    (section === "left" && roundCount < 5 && (sectionMinW * 2) / 3) ||
      (section === "right" && roundCount < 5 && (sectionMinW * 2) / 3) ||
      (section === "mid" && roundCount < 5 && (sectionMinW * 8) / 7) ||
      sectionMinW,
  )
}
const innerMin2 = ({ section, roundCount }) =>
  (section === "left" && roundCount < 5 && "margin: 0 15vw 0 -15vw") ||
  (section === "right" && roundCount < 5 && "margin: 0 -15vw 0 15vw") ||
  `margin: 0 auto`
const maxInnerSb = ({ section, roundCount }) =>
  (section === "left" && roundCount < 5 && `max-width: ${bpxToEm(sectionMaxWSb)};`) ||
  (section === "right" && roundCount < 5 && `max-width: ${bpxToEm(sectionMaxWSb)};`) ||
  (section === "mid" && roundCount < 5 && `min-width: ${bpxToEm(sectionMidMinWSb)};`) ||
  ``
export const BracketSectionInner = React.memo(styled.div<IStyledBracketSection>`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  max-width: ${bpxToEm(sectionMaxW)};
  min-width: ${innerMin3};
  margin: 0 auto;
  height: 100%;
  @media (max-width: ${breakpointBracket}) {
    & {
      ${innerMin2};
    }
  }
  @media (min-width: ${breakpointBracket}) {
    & {
      ${maxInnerSb};
    }
  }
`)
// export const MatchupInner = styled.div`
// width: 100%;
// height: 100%;
// position: relative;
// `
export const MatchupOuter = styled.div`
  position: relative;
`

// roundCount < 6 && section === "left" && round === 3 && "right: 0%" ||
// roundCount < 6 && section === "left" && round === 2 && "left: 0%" ||
// roundCount < 6 && section === "right" && round === 2 && "right: 0%" ||
// roundCount < 6 && section === "right" && round === 3 && "left: 0%" ||
const bracketRoundAbs = ({ section, round, isMismatchedRoundsOnSides }: IStyledBracketRound): string =>
  (section === "right" && isMismatchedRoundsOnSides && round === 1 && "right: 25%") ||
  (section === "right" && isMismatchedRoundsOnSides && round === 2 && "left: 25%") ||
  (section === "left" && round === 3 && "right: 0") ||
  (section === "left" && round === 2 && "left: 50%") ||
  (section === "right" && round === 1 && "right: 0%") ||
  (section === "right" && round === 2 && "left: 50%") ||
  (section === "mid" && round === 3 && "left: 0") ||
  `left: 0`
const bracketRoundMarginLeft = ({ section, round, roundCount }: IStyledBracketRound): string =>
  (round === 2 && `margin-left: ${bpxToEm(mW * -0.5)}`) || `margin-left: 0`
const bracketRoundPadding = ({ section, round, isMismatchedRoundsOnSides }: IStyledBracketRound): string =>
  (section === "right" && isMismatchedRoundsOnSides && round === 1 && `padding: ${bpxToEm(mHplusSpace * 1)} 0 ${bpxToEm(mHplusSpace * 1)}`) ||
  (section === "right" && isMismatchedRoundsOnSides && round === 2 && `padding: ${bpxToEm(mHplusSpace * 2)} 0 0`) ||
  (round === 3 && `padding: ${bpxToEm(mHplusSpace * 2)} 0 0`) ||
  (round === 1 && `padding: 0 0 ${bpxToEm(mHplusSpace * 2)}`) ||
  `padding: ${bpxToEm(mHplusSpace)} 0 ${bpxToEm(mHplusSpace * 1)}`
const bracketRoundTopSBMobile = ({ section, round, roundCount }: IStyledBracketRound): string =>
  (roundCount < 5 && round < 4 && `margin-top: -${bpxToEm(mHplusSpace / 2)};`) || ``
const sbFirstRoundDesktopFix = ({ section, round, roundCount }: IStyledBracketRound): string =>
  (roundCount < 5 && section === "left" && round === 2 && "left: 0; margin: 0;") ||
  (roundCount < 5 && section === "right" && round === 2 && "right: 0; left: unset; margin: 0;") ||
  ``
// export const sbFirstRoundDesktopFix = ({section, round, roundCount}) => (
//   roundCount < 5 && round === 4 && `padding: ${bpxToEm(mHplusSpace)} 0 0;` ||
//   roundCount < 5 && round === 2 && `padding: 0;` ||
//   roundCount < 5 && `padding: ${bpxToEm(mHplusSpace )} 0 ${bpxToEm(mHplusSpace)};` ||
//   ``
// );
// export const bracketRoundPadding = ({section, round}) => (
//   round === 3 && `padding: 50% 0 0` ||
//   round === 1 && `padding: 0` ||
//   `padding: 25% 0 30%`
// )
// export const bracketRoundPadding = ({section, round}) => (
//   round === 3 && `padding: 20vh 0 0` ||
//   round === 1 && `padding: 0` ||
//   `padding: 11vh 0 12vh`
// )
export const BracketRound = React.memo(styled.div<IStyledBracketRound>`
  position: absolute;
  top: 0;
  width: ${bpxToEm(mW)};
  height: 100%;
  ${bracketRoundMarginLeft};
  ${bracketRoundAbs};
  ${bracketRoundPadding};
  @media (max-width: ${breakpointBracket}) {
    & {
      ${bracketRoundTopSBMobile}
    }
  }
  @media (min-width: ${breakpointBracket}) {
    & {
      ${sbFirstRoundDesktopFix}
    }
  }
`)
export const BracketInner = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
`

export const Matchup = React.memo(styled.div<IStyledBracketMatchup>`
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: ${bpxToEm(mH)};
  width: ${bpxToEm(mW)};
  z-index: ${zIndexes.ui1};
`)

export const maskPosition = ({ section, position, roundOrdinal, round }) =>
  (section === "left" && position === "top" && "100% 100%") ||
  (section === "left" && position === "bottom" && "100% 0%") ||
  (section === "right" && position === "top" && "0% 100%") ||
  (section === "right" && position === "bottom" && "0% 0%") ||
  (section === "mid" && position === "bottom" && round === 5 && "0% 100%") ||
  (section === "mid" && position === "top" && roundOrdinal === 1 && "0% 100%") ||
  (section === "mid" && position === "bottom" && roundOrdinal === 1 && "0% 0%") ||
  (section === "mid" && position === "top" && "100% 100%") ||
  (section === "mid" && position === "bottom" && "100% 0%") ||
  "100% 100%"
export const teamFlexDir = ({ section, round, roundOrdinal, position }) =>
  section === "left" || (round >= 5 && position === "top") || (round === 4 && roundOrdinal === 0) ? "row" : "row-reverse"

export const mInfoR = (tH * 0.8) / 2
export const stopList = `transparent ${bpxToEm(tH / 2 - 0.5)}, #000 0`
export const MatchupTeam = React.memo(styled(FlexRow)<IStyledBracketTeam>`
  position: relative;
  display: flex;
  align-items: center;
  min-width: ${bpxToEm(mW)};
  flex-flow: ${teamFlexDir} nowrap;
  background-color: white;
  transition: background-color 0.2s ease-out;
  border: ${bpxToEm(mBorderW)} solid ${palette.gray3};
  height: ${bpxToEm(tH)};
  border-radius: ${bpxToEm(1)};
  line-height: 2em;
  -webkit-mask: radial-gradient(circle at ${maskPosition}, ${stopList});
  mask: radial-gradient(circle at ${maskPosition}, ${stopList});
  color: #000;
  & input {
    position: absolute;
    opacity: 0;
    z-index: -1;
    overflow: hidden;
  }
  &:active {
    background-color: #3685d6;
    color: #fff;
  }
  @media (hover: hover) {
    &:hover {
      background-color: #3685d6;
      color: #fff;
    }
  }
`)
// liveIcon
// animation: ${liveIcon} 3.5s infinite;
export const liveFSZ = 10
export const MatchupLiveLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  right: ${bpxToEm(20)};
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  padding-left: ${bpxToEm(40)};
  align-items: center;
  z-index: 1;
  color: #000;
  @media (hover: hover) {
    &:hover:not(:active) div {
      transform: scale(1.2);
    }
  }
  &:active div {
    transform: scale(1.2);
  }
  & div {
    height: ${bpxToEm(liveFSZ + 4)};
    border-radius: ${bpxToEm(5)};
    text-transform: uppercase;
    background-color: rgb(230, 230, 230);
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: transform 0.2s ease-out;
    transform: translatez(0);
  }
  & svg {
    width: ${bpxToEm(13)};
    height: 100%;
    color: #000;
    transform: rotate(-90deg);
  }
  & i {
    width: ${bpxToEm(liveFSZ * 0.8)};
    height: ${bpxToEm(liveFSZ * 0.8)};
    margin: ${bpxToEm(liveFSZ * 0.5)};
    border-radius: 100%;
    background-color: ${palette.injuryRed};
    display: inline-block;
  }
  & span {
    font-size: ${bpxToEm(liveFSZ)};
    letter-spacing: ${bpxToEm(0.8)};
    font-weight: ${fontWeight.bold};
    line-height: 1.1em;
  }
`
export const MatchupTeamSeed = styled.div`
  width: ${bpxToEm(tH * 0.75)};
  height: ${bpxToEm(tH - mBorderW * 2)};
  background-color: #23374a;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  & > * {
    font-size: ${bpxToEm(10)};
    font-weight: ${fontWeight.bold};
  }
`
export const MatchupTeamName = styled(FlexColGrow)<IStyledBracketTeam>`
  height: 100%;
  padding: 0 ${bpxToEm(4)};
  transition: all 0.2s ease-out;
  & > * {
    flex-direction: ${teamFlexDir};
    height: 100%;
    font-size: ${bpxToEm(12)};
    font-weight: ${fontWeight.bold};
  }
`
export const matchupInfoSide = ({ section, round, roundOrdinal }) =>
  section === "left" || round === 5 || (roundOrdinal === 0 && round === 4)
    ? `right: ${bpxToEm(mInfoR * -1.15)};`
    : `left: ${bpxToEm(mInfoR * -1.15)};`
export const MatchupInfo = styled.button<IStyledBracketMatchup>`
  color: ${palette.blue1};
  position: absolute;
  top: ${bpxToEm(mH / 2 - mInfoR)};
  ${matchupInfoSide}
  width: ${bpxToEm(mInfoR * 2.1)};
  height: ${bpxToEm(mInfoR * 2.1)};
  z-index: ${zIndexes.ui2};
  transition: all 0.2s ease-out;
  &:disabled {
    color: ${palette.gray2};
    opacity: 0.6;
  }
  @media (hover: hover) {
    &:hover {
      transform: scale(1.05);
      color: ${palette.blue2};
    }
  }
  &:active {
    transform: scale(0.95);
    color: ${palette.blue2};
  }
`
// export const MatchupConnector = styled.div`
// position: absolute;
// height: 90%;
// width: 50%;
// border: 1px solid rgba(255,255,255,0.6);
// `;
// export const MatchupConnectorLeftUp = styled(MatchupConnector)`
// border-top: none;
// border-right: none;
// left: -65%;
// top: -45%;
// `;
// export const MatchupConnectorLeftDown = styled(MatchupConnector)`
// border-bottom: none;
// border-right: none;
// left: -65%;
// top: 55%;
// `;
export const MatchupConnector = styled.div`
  position: absolute;
  width: 25%;
  border: 1px solid ${palette.gray2};
`
export const MatchupConnectorLeftUp = styled(MatchupConnector)`
  border-bottom: none;
  border-right: none;
  height: 90%;
  top: -110%;
  left: 50%;
`
export const MatchupConnectorLeftDown = styled(MatchupConnector)`
  border-top: none;
  border-right: none;
  height: 100%;
  bottom: -125%;
  left: 50%;
`
export const MatchupConnectorRightUp = styled(MatchupConnector)`
  border-bottom: none;
  border-left: none;
  height: 90%;
  top: -110%;
  right: 50%;
`
export const MatchupConnectorRightDown = styled(MatchupConnector)`
  border-top: none;
  border-left: none;
  height: 100%;
  bottom: -125%;
  right: 50%;
`
export const MatchupConnectorSemisLeft = styled(MatchupConnector)`
  border-top: none;
  border-left: none;
  height: 60%;
  top: -10%;
  right: -36%;
  @media (min-width: ${breakpointBracket}) {
    & {
      right: -22%;
      width: 12%;
    }
  }
`
export const MatchupConnectorSemisRight = styled(MatchupConnector)`
  border-top: none;
  border-right: none;
  height: 60%;
  top: -10%;
  left: -36%;
  @media (min-width: ${breakpointBracket}) {
    & {
      left: -22%;
      width: 12%;
    }
  }
`
const champMobilePadding = ({ roundCount }) => {
  if (roundCount < 5) {
    return `padding: ${bpxToEm(bH * 0.1)} 0 ${bpxToEm(bH * 0.35)};`
  }
  return ``
  // console.log(`breakpointBracket: ${roundCount}`);
}

export const ChampContainer = styled.div<Omit<IStyledBracketSection, "section">>`
  padding: ${bpxToEm(bH * 0.2)} 0 ${bpxToEm(bH * 0.25)};
  width: 100%;
  @media (max-width: ${breakpointBracket}) {
    ${champMobilePadding}
  }
`
export const SponsorImg = styled.a`
  pointer-events: auto;
  position: absolute;
  width: ${pxToRem(132)};
  top: ${pxToRem(-36)};
  left: 50%;
  margin-left: ${pxToRem(-132 / 2)};
  & .sponsor-logo__img {
    width: 100%;
    height: 100%;
    display: block;
  }
  & .sponsor-logo__ad {
    visibility: hidden;
    width: 1px;
    height: 1px;
    position: absolute;
  }
`
export const Champion = styled.div`
  width: 100%;
  max-width: ${bpxToEm(200)};
  height: ${bpxToEm(90)};
  border-radius: ${bpxToEm(4)};
  background-color: ${palette.blue1};
  border: 1px solid ${palette.gray1};
  padding: ${bpxToEm(15)};
  margin: 0 auto ${bpxToEm(mH * 0.65)};
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  pointer-events: none;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.35);
  & button {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }
  & .team {
    -webkit-mask: none;
    mask: none;
    width: 100%;
    flex-flow: row;
    border-color: white;
    border-radius: 5px;
  }
`
export const ChampionH1 = styled.div`
  text-transform: uppercase;
  font-weight: ${fontWeight.bold};
  font-size: ${bpxToEm(14)};
  color: #fff;
`
export const ChampionMatchup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${bpxToEm(mW * 2.05)};
  position: relative;
  margin: ${bpxToEm(mH * 0.75)} auto;
  & .info {
    right: 50%;
    margin-right: ${bpxToEm(mInfoR * -1)};
  }
`
export const Semifinals = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${bpxToEm(mW * 3.0)};
  margin: 0 auto;
`
export const MatchupMidSaving = styled.div<IisSaving>`
  position: absolute;
  bottom: -70%;
  left: 40%;
  right: 40%;
  border-radius: 1px;
  color: ${palette.white};
  background-color: ${palette.blue1};
  font-weight: ${fontWeight.bold};
  pointer-events: none;
  transition: transform 0.2s ease-out, opacity 0.15s linear ${({ isSaving }) => (!isSaving ? "" : ` 0.1s`)};
  opacity: ${({ isSaving }) => (isSaving ? 1 : 0)};
  transform: translateY(${({ isSaving }) => (isSaving ? bpxToEm(10) : `0`)});
  padding: ${bpxToEm(2)} ${bpxToEm(7)};
`
export const MatchupSaving = styled.div<IisSaving & { position: TBracketSection }>`
  position: absolute;
  bottom: 35%;
  left: ${({ position }) => (position === "left" ? `59%` : `3%`)};
  top: 35%;
  border-radius: 1px;
  color: ${palette.white};
  background-color: ${palette.blue1};
  font-weight: ${fontWeight.bold};
  transition: transform ${bracketSaveAnimMs / 1000}s ease-in-out,
    opacity ${(bracketSaveAnimMs / 1000) * 0.8}s ease-in-out ${({ isSaving }) => (isSaving ? "" : ` ${(bracketSaveAnimMs / 1000) * 0.2}s`)};
  pointer-events: none;
  opacity: ${({ isSaving }) => (isSaving ? 1 : 0)};
  transform: translateX(${({ isSaving, position }) => (isSaving ? bpxToEm(position === "left" ? -10 : 10) : `0`)});
  padding: ${bpxToEm(2)} ${bpxToEm(7)};
`

export const BracketSaveBtn = styled.button`
  font-weight: ${fontWeight.bold};
  width: ${pxToRem(64)};
  height: ${pxToRem(64)};
  border-radius: 100%;
  background-color: ${palette.buttonOrange1};
  border: 3px solid ${palette.buttonOutlineYellow1};
  transition: all 0.2s ease-out;
  position: relative;
  color: ${palette.white};
  &:disabled {
    transform: scale(0);
  }
  & svg {
    width: 109%;
    height: 109%;
    position: absolute;
    margin: -3px;
    top: 0;
    left: 0;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    border-radius: 100%;
    animation: ${fill2} 0.4s ease-in-out 0.4s forwards, ${scale} 0.3s ease-in-out 0.9s both;
  }
  & circle {
    stroke-dasharray: 216;
    stroke-dashoffset: 216;
    stroke-width: 3;
    stroke-miterlimit: 10;
    stroke: #fff;
    fill: none;
    animation: ${stroke} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  & path {
    transform-origin: 50% 50%;
    stroke-dasharray: 98;
    stroke-dashoffset: 98;
    animation: ${stroke} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  @media (hover: hover) {
    &:hover:not(:active) {
      transform: scale(1.03);
    }
  }
  &:active {
    transform: scale(0.95);
  }
`
export const BracketSaveBtnContainer = styled.div<{ disabled: boolean }>`
  position: fixed;
  bottom: ${pxToRem(16)};
  padding: 0 ${pxToRem(16)};
  left: 0;
  right: 0;
  z-index: ${zIndexes.ui3};
  &[disabled] {
    pointer-events: none;
  }
  & > * {
    width: 100%;
    max-width: ${pxToRem(layout.pageContentMaxW - layout.pageGutterW * 2)};
    max-width: calc(var(--page-max-w) - var(--grid-margin) * 2);
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    margin: 0 auto;
    align-items: center;
  }
  @media (max-width: ${pxToRem(layout.useMobileNavW)}) {
    & {
      padding: 0 ${pxToRem(8)};
      bottom: ${pxToRem(layout.mobileBottomAdH + 8)};
    }
  }
  @media (min-height: ${layout.siteNavH + layout.siteSubNavH + layout.pageMarqueeMinH + layout.maxBracketH + 150}px) {
    & {
      /* but set a max height for really tall screens of course */
      bottom: unset;
      top: calc(${skyboxH} + ${pxToRem(layout.siteNavH + layout.siteSubNavH + layout.pageMarqueeMinH + layout.maxBracketH - 70)});
    }
  }
`

// interface IHasPickUtils {
//   pickUtils: PickUtils;
//   tournamentRounds: number[];
//   showMatchupAnalysis: (eventId: string) => {};
//   onInputChange: (itemId: any, slotId: any, event: any) => {};
//   matchupId: string;
//   pickId: string | null;
//   className?: string;
//   canEdit: boolean;
// }

// interface IMatchupComp extends IHasPickUtils {
//   matchupsCountInRoundSection: number;
//   isLastMatchupInSection: boolean;
//   topItemId?: string | null;
//   bottomItemId?: string | null;
// }
// interface IMatchupTeamComp extends IHasPickUtils {
//   itemId?: string | null;
// }

// <IStyledBracketTeam>
// flex-direction: ${teamFlexDir};
export const MatchupTeamCorrectPick = styled.div<IStyledBracketTeam>`
  position: absolute;
  bottom: ${({ position }) => (position === "top" ? "unset" : "-30%")};
  top: ${({ position }) => (position === "top" ? "-35%" : "unset")};
  left: ${({ section, round, roundOrdinal }) => ((section === "mid" && roundOrdinal === 0) || section === "left" ? "12%" : "unset")};
  right: ${({ section, round, roundOrdinal }) => (section === "left" ? "unset" : "12%")};
  color: ${palette.gray2};
  z-index: 4;
  & > * {
    height: 100%;
    font-size: ${bpxToEm(12)};
    font-weight: ${fontWeight.bold};
  }
`
