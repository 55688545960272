import { Formik } from "formik"
import { DateTime } from "luxon"
import * as React from "react"
import BracketUtils from "../../../../common/bracket-utils"
import {
  IBracketUtilMatchup,
  IPickUtilsEvent,
  IPickUtilsPeriod,
  IPickUtilsPick,
  IPickUtilsPicks,
  IPickUtilsTeam,
  TBracketSection,
} from "../../../../common/common-utils-types.d"
import constants from "../../../../common/constants"
import { ENUM_BRACKET, PeriodLengthEnum, SeasonEnumType, ENUM_NCAAF, ENUM_CONFERENCE_TOURNAMENT } from "../../../../common/enums"
import { copyArray, oneDay, toTruncatedNumber } from "../../../../common/misc-utils"
import { toPercentile } from "../../../../common/misc-utils"
import PickUtils from "../../../../common/pick-utils"
import { sortByOrder } from "../../../../common/sorters"
import { withoutDomain } from "../../../../common/url-utils"
import Ad from "../../../components/Ad"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import { extractValidationError, FormButtonSpinnerBase, FormSuccessStatus } from "../../../components/Form"
import ArrowSvg from "../../../components/icons/Arrow"
import EmptyPageFillerSvg from "../../../components/icons/EmptyPageFiller"
import ErrorSvg from "../../../components/icons/Error"
import OneMillionSvg from "../../../components/icons/OneMillion"
import Link from "../../../components/Link"
import LoadingView from "../../../components/LoadingView"
import Helmet from "../../../shared/react-helmet-async/Helmet"
import { List } from "../../../shared/react-movable"
import BidBarrel from "../../../utils/bid-barrel"
import { cancelAnimationFrame, canUseDom, edgeToNode, getScrollContainerEl, requestAnimationFrame, toRank } from "../../../utils/misc-utils"
import GameOgMeta from "../../GameOgMeta"
import { AuthModal } from "../../NonPoolPages/components/AuthGateView"
import { EntryNameModal } from "../../PoolSetupPages/components/CreateEntryForm"
import DlItem, { IDlItemProps, ptsAvailDt } from "../components/DlItem"
import EntryBar from "../components/EntryBar"
import EntryBarTeam from "../components/EntryBarTeam"
import EntryBarTournamentLink from "../components/EntryBarTournamentLink"
import NativeAppPromoModal from "../components/NativeAppPromoModal"
import PeriodSelect from "../components/PeriodSelect"
import PickemEvent from "../components/PickemEvent"
import PicksParlayBonus from "../components/PicksParlayBonus"
import { PicksPageLayout, PoolPage, PoolPageActions, PoolPageActionsToggleButton } from "../components/PoolPage"
import RulesAndPrizing from "../components/RulesAndPrizing"
import { CalloutBar, ThreeCol, WillHillCalloutContainer, ICalloutBarProps } from "../styles/EntryBar.styles"
import {
  BracketForm,
  bracketSaveAnimMs,
  CtaModule,
  MainSaveBtn,
  PicksPageHeader,
  PicksSubmitBtnContainerStyled,
  SportslineAd,
  WarningStateEl,
} from "../styles/Picks.styles"
import { IPicksProps, TOnInputChange } from "./IPicksProps"
import MatchupAnalysis from "./MatchupAnalysis"
import ScoringBreakdownModal, {
  IScoringModalBreadkownProps,
  lastTiebreakerInfoKey,
  scoringBreakdownInfoKey,
  IScoringModalBreadkownVariants,
} from "./ScoringBreakdownModal"
import UpsellModal from "./UpsellModal"

import { deserializePicks, serializePicks } from "../../../../common/common-utils-helpers"
import { ButtonBase, ButtonSmallBase, IconButton } from "../../../components/Button"
import CarrotSvg from "../../../components/icons/Carrot"
import Toggle from "../../../components/Toggle"
import { gatherPoolSettings } from "../../../hooks/usePoolData"
import Analytics from "../../../utils/analytics"
import ClearPicksModal from "../../PoolSetupPages/components/ClearPicksModal"
import BcgPromo from "../components/BcgPromo"
import Bracket, { bracketSectionIdPrefix, defaultSection } from "../components/Bracket"
import FantasyArticlesSidebar from "../components/FantasyArticlesSidebar"
import GamblingPartnerUpsellModal from "./GamblingPartnerUpsellModal"
import invariant from "invariant"
import GamblingPartnerPickTileIntegration from "../components/GamblingPartnerPickTileIntegration"
import { timeoutQ } from "../../../utils/timeout-promise"
import Options from "../../../components/icons/Options"
import PicksParlayBonusMultipleGames from "../components/PicksParlayBonusMultipleGames"

// interface IPicksFormPick {
//   slotId: string;
//   itemId: string;
// }
// interface IPicksFormTiebreakerAnswer {
//   tiebreakerQuestionId: string;
//   value: number;
// }
// interface IPicksFormValues {
//   picks: IPicksFormPick[];
//   tiebreakerAnswers: IPicksFormTiebreakerAnswer[];
// }

interface IPicksState {
  entryBarOpenTop: number | null
  entryBarPlaceholderH: number | null
  entryBarMobileTop: number | null
  entryBarDesktopInnerHeight: number | null
  errorMessage: string | null
  wipSerializedPicks: null | string
  mobileTiebreakerInView: boolean
  weightingMode: boolean
  savingAnimationKey: string
  mobileStickyFooterTiebreakerRevealed: boolean
  poolPageActionsOpen: boolean
  tieBreakerFromBottomPosition: number
}

const emptyObject = {} as never
const emptyArray = [] as never[]
const useSaveBtn = false
const useWhInPickSlip = false

// TODO qac: fix
const FakePickUtilsTeam = ({
  nickName: " ",
  abbrev: "TB",
  wins: 0,
  losses: 0,
  id: "",
  location: "",
} as unknown) as IPickUtilsTeam
const matchupAnalysisModalKey = "eventMatchupAnalysisModal"
const savingBracketPicksModalKey = "savingBracket"
const clearPicksModalKey = "ClearPicksModal"

const fakeDomRect = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
} as DOMRect | ClientRect

class Picks extends React.PureComponent<IPicksProps, IPicksState> {
  public state = {
    entryBarOpenTop: null,
    entryBarPlaceholderH: null,
    entryBarMobileTop: null,
    entryBarDesktopInnerHeight: null,
    wipSerializedPicks: null,
    errorMessage: null,
    mobileTiebreakerInView: false,
    weightingMode: false,
    savingAnimationKey: "",
    mobileStickyFooterTiebreakerRevealed: false,
    poolPageActionsOpen: false,
    tieBreakerFromBottomPosition: 0,
  }

  public entryBarStickyFooterRef = React.createRef<HTMLFormElement>()

  public pendingChanges = [] as IPickUtilsPicks
  public canQueuePersistingPicks = true
  public mounted = false
  public pickUtils: null | PickUtils = null
  public bracketUtils: null | BracketUtils = null
  public initialShowOnboard: null | boolean = null

  public dims = {
    screenW: 0,
    screenH: 0,
    scrollTop: 0,
    scrollParentTop: 0,
    scrollParentRect: fakeDomRect,
    entryBarRect: fakeDomRect,
    entryBarStickyFooterRect: fakeDomRect,
  }

  public timeoutIds = {
    save: 0,
    modalOpen: 0,
    saveAnimation: 0,
    resize: 0,
    processDOM: 0,
  }
  public rafIds = ["processDOM"]
  public elements = {
    scrollContainer: null as null | Element,
    entryBar: null as null | Element,
    entryBarStickyFooter: null as null | Element,
  }
  public elementIds = {
    entryBar: "entry-bar",
    entryBarStickyFooter: "entry-bar-sticky-footer",
  }

  public previousModalParts = ""

  public getTimeoutId = (key: string) => this.timeoutIds[key]
  public clearTimeout = (key: string) => {
    const id = this.getTimeoutId(key)
    if (id) {
      if (this.rafIds.includes(key)) {
        cancelAnimationFrame(id)
      } else {
        clearTimeout(id)
      }

      this.timeoutIds[key] = 0
    }
  }
  public clearSaveTimeout = () => this.clearTimeout("save")
  public clearSaveAnimationTimeout = () => this.clearTimeout("saveAnimation")
  public getPendingPicks = () => this.buildPickUtils().getDiffFrom(deserializePicks(this.props.picks))
  public toggleWeightingMode = () => {
    this.setState({
      weightingMode: !this.state.weightingMode,
    })
  }

  public clearPicks = async () => {
    const pickUtils = this.buildPickUtils()
    const picks = pickUtils.picks
    const changes = picks.map((pk) => {
      return {
        slotId: pk.slotId,
        itemId: null,
      }
    })
    const merged = pickUtils.getMergedPicks(changes)
    try {
      pickUtils.validate(changes)
      this.clearErrors()
      await this.updateLocalPicks(serializePicks(merged))
      await this.persistPendingPicks(true)
      // we need to set item so that the spreads update!
      let renewedChanges = [] as IPickUtilsPicks
      for (const pick of picks) {
        renewedChanges = renewedChanges.concat(pickUtils.setChanges(pick.slotId, pick.itemId))
      }
      // console.log(`renewedChanges`)
      // console.dir(renewedChanges)
      const renewedPicks = pickUtils.getMergedPicks(renewedChanges)
      // console.log(`renewedPicks`)
      // console.dir(renewedPicks)
      // wait a little bit to make sure the DOM syncs back up and the modal closes
      await timeoutQ(1500)
      await this.updateLocalPicks(serializePicks(renewedPicks))
    } catch (err) {
      this.handleApiErrors(err)
    }
  }

  public handleApiErrors = (errOrApiErrors: any | Error, throwInstead = false, sendToSentryId: string | null = "pick-saving") => {
    // console.debug("handleApiErrors");
    // console.dir(errOrApiErrors);
    const apiErrors = extractValidationError(errOrApiErrors)
    if (sendToSentryId) {
      window.SH_ERROR_NOTIFY(errOrApiErrors, sendToSentryId)
    }
    if (throwInstead) {
      throw apiErrors
    }
    // TODO qac: need to make sure only scrubbed errors are shown
    const errMsgOrig = errOrApiErrors?.message || ""
    const lockedMessage = (/locked/.test(errMsgOrig) && errMsgOrig) || ""
    const msg = lockedMessage || apiErrors.errors?.base?.[0] || `There was an error processing your pick`
    this.showError(msg)
  }

  public persistPendingPicks = async (force = false, tiebreakerAnswers: any[] | null = null) => {
    const picks = this.getPendingPicks()
    // console.log(`persistPendingPicks: (${this.canQueuePersistingPicks}, ${this.mounted})`, picks)
    const isManualSubmit = force === true
    if (this.mounted && (picks.length || isManualSubmit)) {
      this.canQueuePersistingPicks = false
      const requiresManualSubmit = this.requiresManualSubmit()
      const { mutation, periodId, gameInstanceUid, poolId, entryId } = this.props
      const variables = {
        entryId,
        gameInstanceUid,
        poolId,
        periodId,
        picks,
        tiebreakerAnswers: tiebreakerAnswers || null,
        skipPoolData: true,
      } as any
      if (!picks.length && !!tiebreakerAnswers) {
        // NOTE qac: this is a tiebreaker only submital (no changes)
        delete variables.picks
      }
      // console.debug("mutation");
      try {
        const res = await mutation({ variables, refetchQueries: ["EntryDetailsQuery"] })
        this.clearSaveTimeout()
        this.canQueuePersistingPicks = true
        const pendingPicksPost = this.getPendingPicks()
        const hasPendingPicks = this.mounted && !!pendingPicksPost.length
        if (hasPendingPicks) {
          console.warn(`more changes detected after sync...`)
          this.log(pendingPicksPost)
          this.log(this.props.picks)
          this.log(this.getLocalPicks())
          this.log(this.buildPickUtils()?.picks)
        }
        if (hasPendingPicks && !useSaveBtn && !requiresManualSubmit && !isManualSubmit) {
          this.queuePickPersisting(700)
        }
        return res
      } catch (err) {
        this.canQueuePersistingPicks = true
        this.handleApiErrors(err, force)
        await this.updateLocalPicks(null)
      }
    } else {
      this.clearSaveTimeout()
    }
    return null
  }
  public componentDidMount() {
    // console.debug("componentDidMount");
    // console.debug(`needsAuth: ${this.props.needsAuth} ${this.getModalToOpenKey()}`)
    const { onMount, needsEntryName, hasManagerEntry, picks, tiebreakerAnswers, isMine, isIosBrowser, isCbsAppWebview } = this.props
    // account for root treatment for picks page:
    if (onMount) {
      onMount()
    } else if (isMine && needsEntryName && hasManagerEntry && !!picks && this.hasUpsellModal()) {
      // this is some rough logic to see if this entry is an upsell entry
      this.timeoutIds.modalOpen = window.setTimeout(this.openEntryNameModal, 800)
    } else if (isMine && this.getModalToOpenKey()) {
      // if user returns from auth, we link the account, then they get to name the entry
      // only if its your entry (allow vewing others)
      const pickUtils = this.buildPickUtils()
      const allPeriodPicksMade = pickUtils.hasMadeAllPicks()
      const isPrizeEligible = pickUtils.isPrizeEligible(tiebreakerAnswers)
      const isTiebreakerEnabled = pickUtils.isTiebreakerEnabled()
      const tiebreakerIsLocked = pickUtils.tiebreakerIsLocked()
      const pendingTiebreakerInput = !tiebreakerIsLocked && !!isTiebreakerEnabled && !isPrizeEligible
      const allBracketsPicked = !pickUtils.isBracket() || !this.getNextPickableUnpickedPeriod()
      if (allPeriodPicksMade && !pendingTiebreakerInput && allBracketsPicked && !pickUtils.requiredSetupErrorMessage()) {
        this.timeoutIds.modalOpen = window.setTimeout(() => this.props.openModal(this.getModalToOpenKey()), 800)
      }
    }
    if (canUseDom && this.requiresDimensionsAndScrolling()) {
      this.elements.scrollContainer = getScrollContainerEl()
      this.elements.entryBar = document.getElementById(this.elementIds.entryBar)
      this.elements.entryBarStickyFooter = document.getElementById(this.elementIds.entryBarStickyFooter)
      this.recalculateDimensions()
      if (this.elements.scrollContainer) {
        const listener = this.elements.scrollContainer === document.body ? window : this.elements.scrollContainer
        listener.addEventListener("scroll", this.onScroll, { passive: true })
        listener.addEventListener("scroll", this.calcTieBreakerbFromPosition, { passive: true })
      }
      window.addEventListener("resize", this.onResize, { passive: true })
      if (isIosBrowser && isCbsAppWebview) {
        document.addEventListener("focusout", this.onResize, { passive: true })
      }
    }
    this.mounted = true
  }

  public isBodyScroll = () => (canUseDom ? this.elements.scrollContainer === document.body : this.props.isIosBrowser && this.props.isCbsAppWebview)

  public recalculateDimensions = () => {
    this.log(`recalculateDimentions`)
    if (canUseDom && this.elements.scrollContainer) {
      const isBodyScroll = this.isBodyScroll()
      const docEl = window.document.documentElement || emptyObject
      this.dims.screenW = docEl.clientWidth || window.innerWidth
      this.dims.screenH = docEl.clientHeight || window.innerHeight
      this.dims.scrollTop = this.getScrollTop()
      this.dims.scrollParentRect = this.elements.scrollContainer.getBoundingClientRect()
      // for body scroll, this is assumed to be 0 (no offset from headers!)
      this.dims.scrollParentTop = isBodyScroll ? 0 : this.dims.scrollParentRect.top
      if (this.elements.entryBar) {
        this.dims.entryBarRect = this.elements.entryBar.getBoundingClientRect()
      }
      const el = this.entryBarStickyFooterRef.current // || this.elements.entryBarStickyFooter
      if (el) {
        this.dims.entryBarStickyFooterRect = el.getBoundingClientRect()
      }

      if (!this.timeoutIds.processDOM) {
        this.timeoutIds.processDOM = requestAnimationFrame(this.processDOM) || 0
      }
    }
    // console.debug(`recalculateDimensions:`, this.dims, this.elements)
  }

  public getScrollTop = () => (this.isBodyScroll() ? window.scrollY : this.elements.scrollContainer!.scrollTop)

  public onScroll = (_event: Event) => {
    // console.debug(`onscroll:`, event)
    if (this.elements.scrollContainer) {
      const scrollTop = this.getScrollTop()
      if (this.dims.scrollTop !== scrollTop) {
        this.dims.scrollTop = scrollTop
        if (this.elements.entryBar) {
          this.dims.entryBarRect = this.elements.entryBar.getBoundingClientRect()
        }
        const el = this.entryBarStickyFooterRef.current // || this.elements.entryBarStickyFooter
        if (el) {
          this.dims.entryBarStickyFooterRect = el.getBoundingClientRect()
        }
        if (!this.timeoutIds.processDOM) {
          this.timeoutIds.processDOM = requestAnimationFrame(this.processDOM) || 0
        }
      }
    }
  }
  public onResize = (_event: Event) => {
    // console.debug(`onResize:`, event)
    const resizeTO = this.timeoutIds.resize
    this.log(`onResize: ${resizeTO} ${_event.type}`)
    if (resizeTO) {
      clearTimeout(resizeTO)
      this.timeoutIds.resize = 0
    }
    this.timeoutIds.resize = setTimeout(this.recalculateDimensions, 800)
  }
  public processDOM = () => {
    // const newEntryBarTop
    this.timeoutIds.processDOM = 0
    const { screenH, screenW, scrollParentTop, entryBarRect, entryBarStickyFooterRect, scrollParentRect } = this.dims
    const isBodyScroll = this.isBodyScroll()
    const isMobileW = screenW < 800 // breakpoints.handheld
    // only capture this once (it may change... #lazy)
    const isBottomStickyPickSlip = this.isBottomStickyPickSlip()
    this.log(this.dims)
    const newEntryBarPlaceholderH = this.state.entryBarPlaceholderH || entryBarRect.height
    const newScrollTop = !isBottomStickyPickSlip && entryBarRect.top - scrollParentTop <= 0 ? scrollParentTop : null
    // SkyBox + NetworkBar = 130
    const entryBarDesktopInnerHeight =
      screenH - (Math.max(entryBarRect.top, -140) + entryBarStickyFooterRect.height + this.state.tieBreakerFromBottomPosition)
    const mobileTiebreakerOffset = isBottomStickyPickSlip ? 150 : 0 //  + (isBodyScroll ? screenH : 0)
    const mobileTiebreakerThreshold = isBodyScroll ? screenH : scrollParentRect.height
    const mobileTiebreakerInView = !isBottomStickyPickSlip && entryBarStickyFooterRect.top + mobileTiebreakerOffset <= mobileTiebreakerThreshold
    // console.log(`entryBarDesktopInnerHeight: ${entryBarDesktopInnerHeight} = isMobileW ? null : ${screenH} - (${entryBarRect.top} + ${entryBarStickyFooterRect.height})`);
    // console.log(`entryBarMobileTop: isMobileW ? ${newScrollTop} : null (${entryBarRect.top} - ${scrollParentTop} <= 0 ? ${scrollParentTop} : null)`);
    // console.log(`mobileTiebreakerInView: ${mobileTiebreakerInView} (${entryBarStickyFooterRect.top} + ${mobileTiebreakerOffset} <= ${mobileTiebreakerThreshold})`)
    const newState = {
      entryBarPlaceholderH: isMobileW ? newEntryBarPlaceholderH : null,
      entryBarMobileTop: isMobileW ? newScrollTop : null,
      entryBarDesktopInnerHeight: isMobileW ? null : entryBarDesktopInnerHeight,
      mobileTiebreakerInView,
    } as IPicksState
    if (Object.keys(newState).find((key) => this.state[key] !== newState[key])) {
      // console.debug("changing state:", this.state, newState);
      this.setState(newState)
    }
  }

  public requiresManualSubmit() {
    // TODO: qac uuuuuugh we need to put this into our Segment probably: but really ncaa tourney saving needs a refactor anyways
    if (this.props.segmentForArea?.subsection === ENUM_CONFERENCE_TOURNAMENT) {
      return false
    }
    // only require manual submit if we require all picks submitted at once, not peice-meal
    const pickUtils = this.pickUtils || this.buildPickUtils()
    return !pickUtils.supportsPicksWithDifferentSpreadLockTimes()
    // NOTE qac: we dont want to use all the buildPickUtils logic each time we calculate DOM, so a minor optimization:
    // return !this.buildPickUtils()?.supportsPicksWithDifferentSpreadLockTimes();
    // return this.props.gameType === ENUM_PARLAY;
  }
  public isBottomStickyPickSlip() {
    // Bottom pick slip exposes the save button at all times
    return this.requiresManualSubmit()
  }
  public hasUpsellModal() {
    return this.props.gameType === ENUM_BRACKET
  }
  public requiresDimensionsAndScrolling() {
    return !this.props.isSingleBracket
  }
  public hasTiebreakerForm() {
    return !!this.buildPickUtils().tiebreakerQuestionsAttr()
  }

  public openEntryNameModal = () => {
    this.props.openModal(EntryNameModal.modalKey)
    this.timeoutIds.modalOpen = 0
  }
  public openUpsellModal = () => {
    this.props.openModal(UpsellModal.modalKey)
    this.timeoutIds.modalOpen = 0
  }

  public componentWillUnmount() {
    // console.debug("componentWillUnmount");
    Object.keys(this.timeoutIds).forEach(this.clearTimeout)
    if (this.elements.scrollContainer) {
      const listener = this.elements.scrollContainer === document.body ? window : this.elements.scrollContainer
      listener.removeEventListener("scroll", this.onScroll)
      listener.removeEventListener("scroll", this.calcTieBreakerbFromPosition)
      window.removeEventListener("resize", this.onResize)
      if (this.props.isIosBrowser && this.props.isCbsAppWebview) {
        document.removeEventListener("focusout", this.onResize)
      }
    }
    Object.keys(this.elements).forEach((key) => (this.elements[key] = null))
    if (!this.requiresManualSubmit()) {
      this.persistPendingPicks()
    }
    this.mounted = false
  }

  public closeModal = () => {
    this.props.openModal(null)
  }

  public onPrizeEligibleCompleteEntry() {
    if (!this.props.needsToMakePicks) {
      this.props.openModal(GamblingPartnerUpsellModal.modalKey)
    } else if (this.requiresManualSubmit()) {
      this.toggleEntryBarModal()
    }
  }

  public afterEntryNameModalClose = () => {
    this.afterModalClose()
    this.onPrizeEligibleCompleteEntry()
  }

  public showScoringBreakdown = (
    eventId?: string | null,
    teamIdOrEventId?: string | null,
    variant: IScoringModalBreadkownVariants = null,
    extraId?: string,
  ) => {
    const parts = [ScoringBreakdownModal.modalKey, eventId || "", teamIdOrEventId || ""]
    if (variant) {
      parts.push(variant)
      if (extraId) {
        parts.push(extraId)
      }
    }
    return this.props.openModal(parts.join("-"))
  }

  public getOpenModalParts() {
    // TODO qac: this is a hack since i did modal closing wrong :(
    const openModalKey = this.props.openModalKey || this.previousModalParts || ""
    // this.log(openModalKey)
    this.previousModalParts = openModalKey
    return openModalKey.split("-")
  }

  public isModalOpen = () => !!this.props.openModalKey

  public afterModalClose = () => {
    this.previousModalParts = ""
  }

  public getMAModalProps(pickUtils: PickUtils, parts: string[]) {
    const isOpen = !!(parts.length && parts[0] === matchupAnalysisModalKey && pickUtils.events.length)
    let event: IPickUtilsEvent | undefined
    let pick: IPickUtilsPick | undefined
    let homeTeam: IPickUtilsTeam = FakePickUtilsTeam
    let team: IPickUtilsTeam | undefined
    let matchup: IBracketUtilMatchup | undefined
    let sportslineKey = ""
    const nextPickableModalKey = ""
    const prevPickableModalKey = ""
    const variables = {} as any
    if (isOpen) {
      // const eventId = isOpen && parts[1] || ""
      if (pickUtils.isBracket()) {
        // NOTE: for bracket, we want to build a fake event!
        const hackParts = parts[1].split("|")
        matchup = pickUtils.matchups.find(({ id }) => id === hackParts[2])
        // NOTE qac: this is a hack to disable MA for now
        event = (matchup && (pickUtils.buildFakeEventFor(matchup, hackParts[1], hackParts[0]) as IPickUtilsEvent)) || undefined
        const topTeam = event?.homeTeam || FakePickUtilsTeam
        const bottomTeam = event?.awayTeam || FakePickUtilsTeam
        pick = pickUtils.picks.find(({ slotId }) => slotId === matchup?.id)
        sportslineKey = `${bottomTeam.abbrev}-${topTeam.abbrev}`
      } else {
        matchup = undefined
        event = isOpen && pickUtils.events.find((e) => e.id === parts[1])
        team = (event && [event.awayTeam, event.homeTeam].find((e) => e && e.id === parts[2])) || FakePickUtilsTeam
        homeTeam = (event && event.homeTeam) || FakePickUtilsTeam
        pick = event && pickUtils.picks.find((pk) => event && pk.slotId === event.id)
        variables.weekNumber = (event && event.weekNumber) || pickUtils.period.order
      }
      variables.awayTeamAbbrev = event?.awayTeam?.abbrev
      variables.homeTeamAbbrev = event?.homeTeam?.abbrev
      variables.sportType = pickUtils.sportType
      variables.poolId = this.props.poolId
    }
    // const event = isOpen && eventId. pickUtils.events.find((e) => e.id === parts[1]);
    // const team = event && [event.awayTeam, event.homeTeam].find((e) => e.id === parts[2]);
    // const homeTeam = event && event.homeTeam || FakePickUtilsTeam
    // const pick = event && pickUtils.picks.find((pk) => pk.slotId == event.id);
    // const variables = {eventId: isOpen && parts[1] || ""}
    return {
      isOpen: isOpen && this.isModalOpen(),
      team,
      event,
      matchup,
      pick,
      homeTeam,
      variables,
      sportslineKey,
      nextPickableModalKey,
      prevPickableModalKey,
    }
  }

  public getSMBModalProps(pickUtils: PickUtils, parts: string[]) {
    const { poolUrl } = this.props
    const isOpen = parts?.[0] === ScoringBreakdownModal.modalKey
    const event = isOpen && pickUtils.events.find((e) => e.id === parts?.[1])
    const team = event && [event.awayTeam, event.homeTeam].find((e) => !!e && e.id === parts?.[2])
    const poolSettingsUrl = `${poolUrl}/settings`
    const eventId = event ? event.id : null
    const teamId = team ? team.id : null
    const variant = isOpen ? parts?.[3] || scoringBreakdownInfoKey : null
    const tiebreakerQuestionId = variant === lastTiebreakerInfoKey && parts?.[4]
    return {
      isOpen: isOpen && this.isModalOpen(),
      teamId,
      eventId,
      pickUtils,
      poolSettingsUrl,
      tiebreakerQuestionId,
      variant,
    } as IScoringModalBreadkownProps
  }

  public getUpsellModalProps(isDonePeriodPicksForBracket: boolean, parts: string[], periodId: string) {
    // hasChallengeEntry
    const nextBtnText = (isDonePeriodPicksForBracket && this.getBracketNextBtnText()) || null
    const { hasChallengeEntry, entryId, gameInstanceUid, seasonType, year, productAbbrev } = this.props
    const isOpen = !!parts.length && parts[0] === UpsellModal.modalKey
    return {
      nextBtnText,
      hasChallengeEntry,
      entryId,
      periodId,
      gameInstanceUid,
      seasonType,
      year,
      productAbbrev,
      isOpen: isOpen && this.isModalOpen(),
    }
  }

  public toggleMatchupAnalysis = (eventId: string) => {
    return this.props.openModal([matchupAnalysisModalKey, eventId].join("-"))
  }

  public log(item: any) {
    if (canUseDom && window.hasOwnProperty("QQ")) {
      //  || !constants.IS_PRODISH
      console.dir(item)
      window["Picks"] = this
    }
  }

  public getMatchups() {
    return this.props.period.matchups || emptyArray
    // const tournamentIds = this.props.tournamentIds || [];
    // // console.log(`getMatchups:`, this.props.tournamentIds)
    // return (this.props.period.matchups || []).filter(
    //   ({tournamentId}) => tournamentIds.includes(tournamentId),
    // );
  }

  public buildPickUtils() {
    const { picks, period, centralTeams, entryId } = this.props
    const localPicks = this.getLocalPicks()
    const { weightingMode } = this.state
    const canEdit = this.canEdit()
    const dbPicks = deserializePicks(picks)
    const deserializedPicks = (localPicks && canEdit && deserializePicks(localPicks)) || dbPicks
    const events = period?.events?.edges.map(edgeToNode) || emptyArray
    const matchups = this.getMatchups()
    const overriding = this.inManagerMode()
    this.pickUtils = PickUtils.buildFor(
      deserializedPicks,
      events,
      period as IPickUtilsPeriod,
      matchups as any,
      centralTeams,
      gatherPoolSettings(this.props),
      {
        overriding,
        inWeightingMode: !!weightingMode,
        extraCacheKey: entryId,
      },
      this.pickUtils,
    )
    const diff = this.pickUtils.getDiffFrom(dbPicks)
    const needsSave = diff.length > 0
    // console.log(`needsSave: ${this.pickUtils.needsSave} -> ${needsSave}`, diff, dbPicks)
    this.pickUtils.needsSave = needsSave
    return this.pickUtils
  }

  public getTiebreakerInitialValues(fields: any[], picksSnapshot: string) {
    const tiebreakerAnswers = this.props.tiebreakerAnswers || []
    const values = {
      picks: picksSnapshot,
      tiebreakerAnswers: {} as Map<string, number | string>,
    }
    if (fields) {
      for (const field of fields) {
        const tiebreakerAnswer = tiebreakerAnswers.find(({ tiebreakerQuestionId }) => tiebreakerQuestionId === field.id) || undefined
        values.tiebreakerAnswers[field.id] = tiebreakerAnswer ? tiebreakerAnswer.value : ""
      }
    }
    return values
  }

  // public validateTiebreakerForm = (values) => {
  //   // const pickUtils = this.buildPickUtils()
  //   let errors = {} as any;
  //   const {tiebreakerAnswers, localPicks} = this.props;
  //   const picksChanged = values.picks !== localPicks
  //   // console.log(`validating... (picksChanged: ${picksChanged})`)
  //   return errors
  // }

  public editTiebreakerClick = () => {
    this.toggleEntryBarModal()
    setTimeout(this.toggleStickyFooterTiebreaker, 600)
  }

  public onTiebreakerSubmit = (values, actions) => {
    // console.dir(values);
    const { mobileStickyFooterTiebreakerRevealed } = this.state
    const { needsToMakePicks, openModal } = this.props
    const formTV = values.tiebreakerAnswers
    const pickUtils = this.buildPickUtils()
    // if (
    //   !needsToMakePicks &&
    //   !mobileStickyFooterTiebreakerRevealed &&
    //   !pickUtils.supportsPicksWithDifferentSpreadLockTimes()
    // ) {
    //   actions.setStatus(null);
    //   actions.setSubmitting(false);
    //   openModal(clearPicksModalKey);
    //   return;
    // }
    const tiebreakerAnswersBeforeFilter = Object.keys(formTV)
      .filter((key) => {
        const value = formTV[key]
        if (value == null) {
          return false
        }
        return value >= 0
      })
      .map((key) => ({
        tiebreakerQuestionId: key,
        value: formTV[key],
      }))
    const tiebreakerAnswers = pickUtils.getTiebreakerAnswers(tiebreakerAnswersBeforeFilter)
    this.clearSaveTimeout()
    try {
      pickUtils.validateTiebreaker(tiebreakerAnswers)
      this.clearErrors()
    } catch (err) {
      this.handleApiErrors(err, false, null)
      return
    }
    // NOTE qac: all tiebreaker values are strings in the data, but allow for number inputs
    const tiebreakerAnswersToDbValues = tiebreakerAnswers.map((ta) => Object.assign(ta, { value: ta.value.toString() }))
    // console.debug("onTiebreakerSubmit start");

    this.persistPendingPicks(true, tiebreakerAnswersToDbValues)
      .then((_res) => {
        const requiresManualSubmit = this.requiresManualSubmit()
        // console.debug("onTiebreakerSubmit res");
        // console.log("res");
        // console.dir(_res);
        actions.setStatus(FormSuccessStatus)
        actions.setSubmitting(false)
        const successMs = 900
        const modalMs = requiresManualSubmit ? successMs : successMs + 200
        setTimeout(actions.resetForm, successMs)
        const modalKeyToOpen = this.getModalToOpenKey()
        if (mobileStickyFooterTiebreakerRevealed) {
          // NOTE qac: closing this should be delayed since it hides the cta
          const stickyFooterCloseDelay = needsToMakePicks ? 0 : successMs + 200
          setTimeout(this.toggleStickyFooterTiebreaker, stickyFooterCloseDelay)
        }
        // console.debug(`'onTiebreakerSubmit modalKeyToOpen: ${modalKeyToOpen}', needsToMakePicks: ${needsToMakePicks}, ${this.props.needsToMakePicks}`)
        if (modalKeyToOpen) {
          setTimeout(() => openModal(modalKeyToOpen), modalMs)
        } else if (needsToMakePicks) {
          // open pick slip IF: this is the user filling out the entry for the first time
          setTimeout(() => {
            this.onPrizeEligibleCompleteEntry()
          }, 500)
        }
        const saveType = `picks_tiebreaker_${needsToMakePicks ? "submit" : "edit"}`
        Analytics.trackAction("left rail tiebreaker", "left_rail", saveType)
      })
      .catch((apiErrors) => {
        // NOTE qac: we have already handle extracting!
        // console.log("err");
        // console.dir(apiErrors);
        // const apiErrors = extractValidationError(err);
        actions.setErrors(apiErrors.errors)
        actions.setSubmitting(false)
        this.handleApiErrors(apiErrors)
      })
    if (tiebreakerAnswers.length !== tiebreakerAnswersBeforeFilter.length) {
      try {
        throw new Error(`out of sync tiebreaker submit`)
      } catch (err) {
        window.SH_ERROR_NOTIFY(err)
      }
    }
  }

  public getCtasNeededForPicks() {
    const { period, hasManagerRole, poolUrl, isChallengePool } = this.props
    const previousPeriodOrder = period.order - 1
    let children = ""
    const ctaBtns = [] as any
    const basePath = withoutDomain(poolUrl)
    if (period.skipPeriodText) {
      if (hasManagerRole && isChallengePool) {
        children = `You need to set and publish the games and spreads.`
        ctaBtns.push({
          to: `${basePath}/set-period-events`,
          children: `Set Games`,
        })
      } else {
        children = period.skipPeriodText
      }
    } else if (period.needsEventGroupCategoryApproval) {
      if (hasManagerRole && isChallengePool) {
        children = `You need to set and publish the games and spreads.`
        ctaBtns.push({
          to: `${basePath}/set-period-events`,
          children: `Set Games`,
        })
      } else {
        children = `CBS selected games will be available soon.`
      }
    } else if (period.needsEventGroupApproval) {
      if (hasManagerRole) {
        children = `You need to set and publish your custom games.`
        ctaBtns.push({
          to: `${basePath}/set-period-events`,
          children: `Set Games`,
        })
      } else {
        children = `Games have not been published by your Manager.`
      }
    }
    if (children && !ctaBtns.length) {
      if (previousPeriodOrder) {
        ctaBtns.push({
          to: `${poolUrl}?order=${previousPeriodOrder}`,
          children: `View Previous Results`,
        })
      } else if (isChallengePool && constants.ENABLE_BUDDY_GROUPS) {
        ctaBtns.push({
          to: `${basePath}/groups`,
          children: `Create or Join Buddy Groups`,
        })
      } else if (hasManagerRole) {
        ctaBtns.push({
          to: `${basePath}/invite-center`,
          children: `Invite your friends`,
        })
      }
    }
    if (children) {
      return (
        <CtaModule
          iconSvg={<EmptyPageFillerSvg sportType={period.segment.sportType} />}
          cta={
            ctaBtns.length ? (
              <>
                {ctaBtns.map((btn, i) => (
                  <ButtonSmallBase key={i} as={Link} {...btn} />
                ))}
              </>
            ) : null
          }
        >
          {children}
        </CtaModule>
      )
    }
    return null
  }

  public onBracketSubmit = (event?: React.SyntheticEvent) => {
    // NOTE qac: its ok to not display errors here since this is an accessibility thing:
    if (event) {
      event.preventDefault()
    }
    // console.log(`onBracketSubmit`);
    if (this.canEdit()) {
      const pickUtils = this.buildPickUtils()
      const bracketIsUnlocked = pickUtils.periodIsPickable()
      if (bracketIsUnlocked) {
        this.clearSaveAnimationTimeout()
        if (useSaveBtn) {
          this.persistPendingPicks()
          this.props.openModal(savingBracketPicksModalKey)
          window.setTimeout(this.clearModal, 2100)
        } else {
          this.persistPendingPicks().then(this.goToNextPeriod)
        }
      }
    }
  }
  public clearSavingAnimation = () => this.setSavingAnimation("")
  public setSavingAnimation = (savingAnimationKey: string) => this.setState({ savingAnimationKey })
  public onInputChange: TOnInputChange = (slotId, itemId, event) => {
    if (event) {
      event.preventDefault()
    }
    // console.log(`onInputChange`, slotId, itemId, event);
    this.pickTeam(slotId, itemId)
    if (!useSaveBtn) {
      const key = slotId
      this.clearSaveAnimationTimeout()
      this.setSavingAnimation(key)
      this.timeoutIds.saveAnimation = window.setTimeout(this.clearSavingAnimation, bracketSaveAnimMs + 200)
    }
    const pickUtils = this.buildPickUtils()
    const matchup = pickUtils.isBracket() && pickUtils.matchups.find(({ id }) => id === slotId)
    if (!this.props.isSingleBracket && matchup) {
      const tree = pickUtils.bracketPeriodTree
      if (tree) {
        // console.log(`bracketUtils for current:`, matchup.tournamentRound)
        const section = tree.idToSection[matchup.id] // pickUtils.bracketUtils.getSectionFor(matchup)
        const pickedSlotIds = pickUtils.picks.map((pick) => pick.slotId)
        // just in case...
        pickedSlotIds.push(slotId)
        // console.log(`bracketUtils for tree: section: ${section}`)
        const matchupNotPicked = tree[section].find((ms) => !!ms.find((m) => !pickedSlotIds.includes(m.id)))
        // console.log(`matchupNotPicked: ${section}`, matchupNotPicked)
        if (!matchupNotPicked && section !== "mid") {
          const nextSection = (section === "left" && "right") || "mid"
          this.scrollToSection(nextSection)
        }
      }
    }
  }

  public getSectionFor(matchupId: string) {
    const pickUtils = this.buildPickUtils()
    const tree = pickUtils.bracketPeriodTree
    return (tree && tree.idToSection[matchupId]) || defaultSection
    // const section = tree.idToSection[matchupId] // pickUtils.bracketUtils.getSectionFor(matchup)
    // return section || "mid"
  }

  public scrollToSection(section: TBracketSection) {
    const sectionEl = document.getElementById(`${bracketSectionIdPrefix}${section}`)
    if (sectionEl) {
      sectionEl.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      })
    }
  }

  public getNextUnpickedInOrder = (addedSlotId?: string) => {
    const sections = ["left", "right", "mid"]
    const pickUtils = this.buildPickUtils()
    const pickedSlotIds = pickUtils.picks.map(({ slotId }) => slotId)
    const tree = pickUtils.bracketPeriodTree
    if (addedSlotId) {
      pickedSlotIds.push(addedSlotId)
    }
    if (tree) {
      for (const section of sections) {
        const matchupNotPicked = tree[section].find((ms) => !!ms.find((m) => !pickedSlotIds.includes(m.id)))
        // console.log(`matchupNotPicked: ${section}`, matchupNotPicked);
        if (matchupNotPicked) {
          return matchupNotPicked[0]
        }
      }
    }
    return undefined
  }

  public goToNextPeriod = () => {
    const { updateOrder, needsEntryName, openModal, isSingleBracket } = this.props
    // fire off save if need be
    if (this.getTimeoutId("save")) {
      this.persistPendingPicks()
    }
    const nextPeriod = this.getNextPickableUnpickedPeriod(true)
    // console.log(`goToNextPeriod: ${nextPeriod && nextPeriod.order}`)
    if (nextPeriod || needsEntryName) {
      // NOTE qac: this is if the entry already has picks in next (is upsell)
      // hmmm in 2019 this popped the entry name after the FIRST period... lets put that last
      if (nextPeriod) {
        updateOrder(nextPeriod.order)
        window.setTimeout(() => {
          this.scrollToSection("left")
        }, 800)
      } else {
        const modalKeyToOpen = this.getModalToOpenKey()
        if (modalKeyToOpen) {
          this.clearSaveAnimationTimeout()
          openModal(modalKeyToOpen)
        }
      }
    } else {
      // NOTE qac: this is where we do post-save logic for brackets (both conf and bpc)
      if (!isSingleBracket) {
        return this.openUpsellModal()
      }
    }
  }

  public onLastTiebreakerInfoClick = (event: Event) => {
    event.preventDefault()
    this.showScoringBreakdown(null, null, lastTiebreakerInfoKey)
  }

  public getBracketNextBtnText() {
    const nextPeriod = this.getNextPickableUnpickedPeriod(true)
    if (nextPeriod) {
      return "Next"
    } else {
      // promo / upsell, if on manager it will open upsell, if on challenge it will prompt for name
      return !this.props.hasChallengeEntry || this.props.needsEntryName ? "Finish" : undefined
    }
  }

  public getNextPickableUnpickedPeriod(ignoreCurrentPeriod?: boolean) {
    const { period, entryPeriodPointsGlanceQuery, isSingleBracket } = this.props
    if (isSingleBracket) {
      return undefined
    }
    const tournamentIds = this.props.tournamentIds || emptyArray
    const pickablePeriods = period.segment.periods.edges
      .map(edgeToNode)
      .filter(
        (per) =>
          (!ignoreCurrentPeriod || period.id !== per.id) && !per.isInComingSoon && (!per.tournamentId || tournamentIds.includes(per.tournamentId)),
      )
      .sort(sortByOrder)
    const periodPoints = entryPeriodPointsGlanceQuery?.data?.entry.periodPoints.edges.map(edgeToNode) || emptyArray
    return pickablePeriods.find((per) => {
      const periodPoint = periodPoints.find((pp) => pp!.periodId === per.id)
      return !periodPoint || periodPoint.picksCount < periodPoint.maxPicksCount
    })
  }

  public clearModal = () => this.props.openModal(null)
  public isEntryBarOpen = () => this.state.entryBarOpenTop !== null // this.props.openModalKey === entryBarModalKey
  public toggleEntryBarModal = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (event) {
      event.preventDefault()
    }
    const ios = this.props.isIosBrowser
    const isOpen = this.isEntryBarOpen()
    if (isOpen) {
      this.setState({ entryBarOpenTop: null })
    } else {
      const isStuck = this.state.entryBarMobileTop !== null
      const { entryBarRect, scrollParentTop } = this.dims
      const entryBarOpenTop = ios
        ? isStuck
          ? 0
          : -1 * (entryBarRect.top - scrollParentTop)
        : -1 * (isStuck ? scrollParentTop : entryBarRect.top + scrollParentTop)
      // console.log(`setting entryBarOpenTop: ${entryBarOpenTop} (${isStuck})`)
      this.setState({ entryBarOpenTop })
    }
    // this.props.openModal(isOpen ? null : entryBarModalKey)
  }

  public onRemovePickClick = (slotId: string) => {
    const pickUtils = this.buildPickUtils()
    const currentPick = pickUtils.picks.find((p) => p.slotId === slotId)
    invariant(currentPick?.itemId, `derp ${slotId}`)
    return this.pickTeam(slotId, null)
  }

  public getModalToOpenKey() {
    const { needsAuth, needsEntryName, hasSeenNativeAppPromo, isCbsAppWebview, isAndroidBrowser, isIosBrowser } = this.props
    if (needsAuth || needsEntryName) {
      return (needsAuth && AuthModal.modalKey) || EntryNameModal.modalKey
    }
    const canSeeNativeAppPromo = (isAndroidBrowser || isIosBrowser) && !isCbsAppWebview
    if (canSeeNativeAppPromo && hasSeenNativeAppPromo === false) {
      return NativeAppPromoModal.modalKey
    }
    return null
  }

  public clearErrors() {
    if (this.state.errorMessage) {
      this.setState({
        errorMessage: null,
      })
    }
  }

  public showError(message: string) {
    this.setState({
      errorMessage: message,
    })
  }

  public setWeightFor = (eventId: string, additionalPoints: number) => {
    const pickUtils = this.buildPickUtils()
    const currentPick = pickUtils.getPick(eventId)
    if (!currentPick) {
      // console.dir(pickUtils.picks);
      throw new Error(`invalid setWeight for ${eventId}: ${additionalPoints}`)
    }
    return this.pickTeam(eventId, currentPick.itemId, additionalPoints)
  }

  public pickTeam = (slotId: string, itemId: string | null, additionalPoints: null | number = null, isFromMaModal = false) => {
    const { openModal, openModalKey, tiebreakerAnswers, needsToMakePicks } = this.props
    const pickUtils = this.buildPickUtils()
    if (!needsToMakePicks && !pickUtils.supportsPicksWithDifferentSpreadLockTimes()) {
      openModal(clearPicksModalKey)
      return
    }
    const eventId = pickUtils.slotIdForEventId(slotId)
    const currentPick = pickUtils.getPick(eventId)
    if (pickUtils.hasMadeAllPicks() && !currentPick) {
      openModal(clearPicksModalKey)
      return
    }
    // const itemId = teamId; // currentPick && currentPick.itemId === teamId ? null : teamId;
    // console.log(`itemId: ${itemId}`)
    const changes = pickUtils.setChanges(eventId, itemId, additionalPoints)
    // TODO qac: remove when fixed
    // const fixMessages = pickUtils.fixConflictingModifiers(changes);
    // if (fixMessages.length && typeof(window) !== "undefined") {
    //   window.SH_ERROR_NOTIFY(new Error(`${fixMessages.join(", ")} | ${JSON.stringify(changes)}`), "fix-pick-web");
    // }
    const merged = pickUtils.getMergedPicks(changes)
    // console.log("changes")
    // console.log(changes)
    // console.log("merged")
    // console.log(merged)
    try {
      pickUtils.validate(changes)
      this.clearErrors()
    } catch (err) {
      this.showError(err.message)
      window.SH_ERROR_NOTIFY(err)
      return
    }
    this.updateLocalPicks(serializePicks(merged)).then(() => {
      // console.log(`after local picks update commit...`)
      const unpickedPickableSlots = pickUtils.getUnpickedPickableSlots(merged)
      const unpickedPickableSlotsCount = unpickedPickableSlots.length
      this.recalculateDimensions()
      if (!this.requiresManualSubmit()) {
        const isFinishedPicks = unpickedPickableSlotsCount === 0
        const isPrizeEligible = pickUtils.isPrizeEligible(tiebreakerAnswers)
        const isTiebreakerEnabled = pickUtils.isTiebreakerEnabled()
        const tiebreakerIsLocked = pickUtils.tiebreakerIsLocked()
        const secondTiebreakerIsLocked = pickUtils.secondTiebreakerIsLocked()
        // NOTE qac: for conf brackets, we dont want to prompt for auth until the LAST bracket is filled
        // this is so the user notices the "next" button pop
        const allBracketsPicked = !pickUtils.isBracket() || (isFinishedPicks && !this.getNextPickableUnpickedPeriod())
        const pendingTiebreakerInput = !tiebreakerIsLocked && !secondTiebreakerIsLocked && !!isTiebreakerEnabled && !isPrizeEligible
        const modalKeyToOpen = !pendingTiebreakerInput && isFinishedPicks && allBracketsPicked && this.getModalToOpenKey()
        if (modalKeyToOpen) {
          this.clearSaveAnimationTimeout()
          openModal(modalKeyToOpen)
        }
        if (!modalKeyToOpen && openModalKey && !isFromMaModal) {
          openModal(null)
        }
        if (modalKeyToOpen || useSaveBtn) {
          if (modalKeyToOpen) {
            this.persistPendingPicks()
          }
        } else {
          // NOTE qac: making this auto until we fix some things
          this.queuePickPersisting(1200)
        }
      }
      if (this.state.errorMessage) {
        this.setState({
          errorMessage: null,
        })
      }
    })
  }

  public queuePickPersisting = (ms: number) => {
    // console.debug(`queuePickPersisting: ${ms} (${this.canQueuePersistingPicks}, ${this.mounted})`);
    if (this.canQueuePersistingPicks && this.mounted) {
      this.clearSaveTimeout()
      this.timeoutIds.save = setTimeout(this.persistPendingPicks, ms)
    }
  }

  public onTournamentIdSelect = (event: any) => {
    // console.log(`onTournamentIdSelect`, event);
    event.preventDefault()
    const newValue = event.target.value
    this.props.updateOrder(newValue)
  }

  public getLocalPicks(): null | string {
    // if (this.props.hasOwnProperty("localPicks")) {
    //   return this.props.localPicks;
    // } else {
    return this.state.wipSerializedPicks
    // }
  }

  public updateLocalPicks = (value: null | string): Promise<null> => {
    // if (this.props.hasOwnProperty("updateLocalPicks")) {
    //   return this.props.updateLocalPicks(value);
    // } else {
    return new Promise((res) => {
      this.setState({ wipSerializedPicks: value }, () => res(null))
    })
    // }
  }

  public markNativeAppPromoViewed = () => {
    const { mutation, periodId, gameInstanceUid, poolId, entryId, hasSeenNativeAppPromo } = this.props
    if (hasSeenNativeAppPromo === false) {
      const variables = {
        entryId,
        gameInstanceUid,
        poolId,
        periodId,
        hasSeenNativeAppPromo: true,
      }
      mutation({ variables }).catch((err) => window.SH_ERROR_NOTIFY(err, "native-app-promo"))
    }
  }

  public getEventData = (pickUtils: PickUtils) => {
    // DND
    const maxPicksAllowed = pickUtils.getMaxPicksAllowed()
    const inWeightingMode = !!this.state.weightingMode
    const usesWeights = pickUtils.usesWeights()
    const needsDndMode = inWeightingMode || (usesWeights && maxPicksAllowed !== pickUtils.events.length)
    const enableDnd = usesWeights && (!needsDndMode || inWeightingMode)
    // events
    const events = needsDndMode ? (inWeightingMode ? pickUtils.getOrderedEvents() : pickUtils.events) : pickUtils.getOrderedEvents()
    return {
      events,
      enableDnd,
      inWeightingMode,
      needsDndMode,
      maxPicksAllowed,
    }
  }

  public onDragChange = ({ oldIndex, newIndex, targetRect }: any) => {
    const pickUtils = this.buildPickUtils()
    const { events } = this.getEventData(pickUtils)
    const draggedEvent = events[oldIndex]
    const destinationEvent = events[newIndex]
    if (!draggedEvent || !destinationEvent) {
      throw new Error(`unable to find dragged event for ${oldIndex} -> ${newIndex}`)
    }
    const additionalPoints = pickUtils.getAdditionalPointsFromPosition(destinationEvent.id)
    // events.length - (newIndex + 1)
    // console.log(`onDragChange ${oldIndex} -> ${newIndex} (${additionalPoints})`);
    return this.setWeightFor(draggedEvent.id, additionalPoints)
    // const available = pickUtils.getAvailableWeights();
    // const gameWeightChoices = pickUtils.getGameWeightChoices() || emptyArray;
    // let newWeight = newIndex
    // if there is no pick below this one in weight, assign that weight
    // this.setState((prevState: { items: string[] }) => ({
    //   items: arrayMove(prevState.items, oldIndex, newIndex)
    // }))
  }

  public inManagerMode = () => !!this.props.hasManagerRole && !!this.props.mode
  public canEdit = () => !!this.props.isMine || this.inManagerMode()
  public toggleStickyFooterTiebreaker = () =>
    this.setState({
      mobileStickyFooterTiebreakerRevealed: !this.state.mobileStickyFooterTiebreakerRevealed,
    })

  public togglePoolPageActions = () => {
    this.setState({
      poolPageActionsOpen: !this.state.poolPageActionsOpen,
    })
  }

  public calcTieBreakerbFromPosition = () => {
    const footer = document.getElementById("picks-site-footer")
    const tieBreakerFromBottomPosition = Math.max(window.innerHeight - (footer?.getClientRects()?.item(0)?.top || 0), 0)
    this.setState({ tieBreakerFromBottomPosition })
  }

  public handleUpdateMode = (value: boolean) => {
    const { updateMode } = this.props
    updateMode(value, () => {
      window.location.reload()
    })
  }

  public render() {
    const {
      period,
      periodId,
      gameInstanceUid,
      poolId,
      entryName,
      updateOrder,
      mode,
      updateMode,
      poolName,
      ytdFantasyPoints,
      leaderTotalFantasyPoints,
      periodLength,
      entryId,
      isMine,
      openModalKey,
      mutation,
      hasManagerRole,
      deviceType,
      tiebreakerAnswers,
      poolRank,
      lastScoredAt,
      showPicksLoading,
      isChallengePool,
      isIosBrowser,
      poolPeriodRank,
      isCbsAppWebview,
      canUseTeamLogos,
      hasStartedEvent,
      entryPeriodPointsGlanceQuery,
      isSingleBracket,
      needsToMakePicks,
      segmentForArea,
      areaHasMultipleEntriesPerUser,
      isInComingSoon,
      canPreviewBracket,
    } = this.props
    // const localPicks = this.getLocalPicks()

    const { errorMessage, mobileTiebreakerInView, savingAnimationKey, mobileStickyFooterTiebreakerRevealed } = this.state
    // TODO: See note in pick saving on this
    if (this.initialShowOnboard === null) {
      this.initialShowOnboard = this.props.showOnboarding
    }
    const showOnboarding = !!this.initialShowOnboard
    // console.dir(this.props);
    const pickUtils = this.buildPickUtils()

    const picks = pickUtils.picks
    const picksSnapshot = pickUtils.snapshotPicks()
    const tiebreakerFields = pickUtils.getTiebreakerFields()
    const tiebreakerFieldCount = tiebreakerFields.length
    const hasTiebreakerInputFields = !!tiebreakerFieldCount
    const tiebreakerFormValues = this.getTiebreakerInitialValues(tiebreakerFields, picksSnapshot)
    // read not in the following metho
    const parts = this.getOpenModalParts()
    const order = period.order
    const isSinglePeriodGame = periodLength === PeriodLengthEnum.FULL
    const isRoundPeriodGame = periodLength === PeriodLengthEnum.ROUND
    const isPostSeason = period.segment.season.season === SeasonEnumType.post
    const sportType = period.segment.sportType
    const sportTitle = sportType.replace("NCAAF", "College")
    const tournamentIds = this.props.tournamentIds || emptyArray
    // const tournaments = (period.segment.tournaments || emptyArray).filter(({cbsId}) => tournamentIds.includes(cbsId));
    const tournamentId = period.tournamentId || (tournamentIds.length && tournamentIds[0])
    // DND
    const { events, enableDnd, inWeightingMode, needsDndMode, maxPicksAllowed } = this.getEventData(pickUtils)
    const smbModalProps = this.getSMBModalProps(pickUtils, parts)
    const isChallengeWithLockedGame = isChallengePool && hasStartedEvent
    const picksCount = pickUtils.picksMade()
    const requiresManualSubmit = this.requiresManualSubmit()
    const minRequiredPicks = pickUtils.getMinRequiredPicks()
    const currentUnlockedUnpickedSlotsCount = pickUtils.getCurrentUnlockedUnpickedSlotsCount()
    const hasMinRequiredPicks = picksCount >= minRequiredPicks
    const possiblePicksCount = maxPicksAllowed // pickUtils.getMaxPicksAllowed();
    // const gameWeightChoices = pickUtils.getGameWeightChoices();
    const maProps = this.getMAModalProps(pickUtils, parts)
    const canEdit = this.canEdit()
    const isBracket = pickUtils.isBracket()
    const isParlay = pickUtils.isParlay()
    const isMarchMadness = isBracket && areaHasMultipleEntriesPerUser
    const isBottomStickyPickSlip = this.isBottomStickyPickSlip()
    const isSingleOptionParlay = pickUtils.isSingleOptionParlay()
    // const usesLockedInSpread = pickUtils.usesLockedInSpread();
    const supportsPicksWithDifferentSpreadLockTimes = pickUtils.supportsPicksWithDifferentSpreadLockTimes()
    // const pickOrParlay = isParlay ? "Parlay" : "Pick";
    // const isSavingBracket = useSaveBtn && openModalKey === savingBracketPicksModalKey;
    const needsToSave = (isBracket && pickUtils.needsSave) || (requiresManualSubmit && pickUtils.needsSave)
    const slotIdSavingAnimation = savingAnimationKey
    const allPeriodPicksMade = pickUtils.hasMadeAllPicks()
    const ctasNeededToMakePicks = this.getCtasNeededForPicks()
    const tiebreakerIsLocked = pickUtils.tiebreakerIsLocked()
    const secondTiebreakerIsLocked = pickUtils.secondTiebreakerIsLocked()
    const upsellModalProps = this.getUpsellModalProps(isBracket && !useSaveBtn && allPeriodPicksMade && canEdit, parts, periodId)
    const bracketNextBtnText = upsellModalProps.nextBtnText
    const bracketIsUnlocked = isMarchMadness ? canPreviewBracket || !isInComingSoon : pickUtils.periodIsPickable()
    // const showTotalScoreTiebreaker = false; // pickUtils.isTiebreakerPeriod() && pickUtils.needsTotalScoreTiebreaker() && (canEdit || tiebreaker);
    const isPrizeEligible = pickUtils.isPrizeEligible(tiebreakerAnswers)
    const isTiebreakerEnabled = pickUtils.isTiebreakerEnabled()
    const eventOfThePeriod = pickUtils.getEventOfThePeriod()
    const secondEventOfThePeriod = pickUtils.getSecondEventOfThePeriod()
    const pendingTiebreakerInputOnly =
      !tiebreakerIsLocked && !secondTiebreakerIsLocked && !!isTiebreakerEnabled && !isPrizeEligible && allPeriodPicksMade
    const pickedSlotIds = pickUtils.pickedSlotIds()
    const pickedEvents = events.filter(({ id }) => pickedSlotIds.includes(id))
    const earliestPickedEvent = (pickedEvents.length && copyArray(pickedEvents).sort(pickUtils.sortByStartsAtInt)[0]) || null
    const nowAt = Date.now()
    const earliestPickedEventStarted = !!earliestPickedEvent && earliestPickedEvent.startsAt < nowAt
    const parlayStatus = pickUtils.parlayStatus()
    const correctPickMapping = pickUtils.getCorrectPickMapping()
    const season = period.segment.season
    const isEntryBarOpen = this.isEntryBarOpen()
    const isSingleTournament = tournamentIds.length === 1
    const hasPeriodNav = isBracket ? false : true // isBracket || !isMine || hasManagerRole;
    const bracketIsReleased = isMarchMadness ? canPreviewBracket || !isInComingSoon : !pickUtils.period.isInComingSoon
    // console.log(`needsDndMode: (${pendingTiebreakerInputOnly}) ${!tiebreakerIsLocked}, ${!!isTiebreakerEnabled}, ${!isPrizeEligible}`);
    // console.log("state");
    // console.dir(this.state);
    const usesTiebreakerPrompts = hasTiebreakerInputFields && isChallengePool // requiresManualSubmit
    const hideTiebreakerUntilAllPicksMade = requiresManualSubmit
    const usesCustomTiebreaker = isParlay
    const hasLastTiebreakerInputBreakdown = !!pickUtils.customTiebreakerAttr() && sportType !== ENUM_NCAAF
    const isHandheldDevice = deviceType === "handheld"
    const delaySaveCommit = false // deviceType === "handheld"
    const showSaveAnimation = !requiresManualSubmit // && !isIosBrowser
    const useOutsideSaveAnimation = isHandheldDevice || isIosBrowser
    const isIosWebview = isIosBrowser && isCbsAppWebview

    const entryPeriodPointsGlance = entryPeriodPointsGlanceQuery?.data || null
    const periodPointsEdges = entryPeriodPointsGlance?.entry.periodPoints.edges
    const entryBarPeriods =
      (tournamentIds.length &&
        period.segment.periods.edges
          .map(edgeToNode)
          .filter((per) => per.tournamentId && tournamentIds.includes(per.tournamentId))
          .sort(sortByOrder)) ||
      emptyArray
    const periodPoints = (periodPointsEdges && periodPointsEdges.find((edge) => edge.node?.periodId === periodId)) || undefined
    const isPeriodLocked = !!(period.locksAt && period.locksAt < Date.now())
    // TODO qac: add in something like !!entryDetails.id && entryDetails.periodId !== period.id for switching week / bracket (but not for anonymous -> entry create)
    const hasRulesAndPrizingOnboardingElement = !showPicksLoading && showOnboarding && isChallengePool && !isBracket
    const showRulesAndPrizingOnRightRail = isChallengePool && !showOnboarding && !isPostSeason && !isBracket
    const lateToTheGame = minRequiredPicks > currentUnlockedUnpickedSlotsCount && !picks.length

    // console.dir({
    //   isPrizeEligible,
    //   isTiebreakerEnabled,
    //   allPeriodPicksMade,
    //   picksCount,
    //   possiblePicksCount,
    //   period,
    //   hasMinRequiredPicks,
    //   pendingTiebreakerInputOnly,
    // });
    const calloutBar: null | ICalloutBarProps = isSingleBracket
      ? null
      : (errorMessage && {
          children: errorMessage,
          variant: "red",
        }) ||
        (showPicksLoading && {
          children: "Loading Entry...",
          variant: "gray",
          hideForHandheld: isBracket,
        }) ||
        (!isMine && {
          children: `${mode ? "Editing" : "Viewing"}: ${entryName}`,
          variant: "orange2",
        }) ||
        (parlayStatus === "busted" && {
          children: "Sorry, Try Again Next Week!",
          variant: "red",
        }) ||
        (parlayStatus === "correct" && {
          children: "Nice Job, You Earned Points!",
          variant: "blue",
        }) ||
        (parlayStatus === "live" && {
          children: "Your Parlay is Still Alive!",
          variant: "green",
        }) ||
        (pendingTiebreakerInputOnly && {
          children:
            (isSingleOptionParlay && `Enter tiebreaker and complete your entry`) ||
            (usesCustomTiebreaker && `Submit Challenge Questions`) ||
            `Enter your Tiebreaker`,
          variant: "yellow",
        }) ||
        (needsToSave &&
          !hasMinRequiredPicks && {
            children: isSingleOptionParlay ? `${picksCount}/${minRequiredPicks} picks made for parlay` : `Minimum ${minRequiredPicks} Picks Required`,
            variant: "orange",
            hideForHandheld: isBracket,
          }) ||
        (needsToSave &&
          !isBracket && {
            children: "You Have Unsaved Picks",
            variant: "orange2",
          }) ||
        (!isParlay &&
          allPeriodPicksMade &&
          hasMinRequiredPicks && {
            children: "Your picks are in!",
            variant: "blue",
            hideForHandheld: isBracket,
          }) ||
        (isParlay &&
          hasMinRequiredPicks &&
          earliestPickedEvent && {
            children: `Your Parlay is in and will lock at ${DateTime.fromMillis(earliestPickedEvent.startsAt).toFormat("EEE L/dd h:mma ZZZZ")}`,
            variant: "blue",
          }) ||
        (period.skipPeriodText && {
          children: "Season is over!",
          variant: "gray",
        }) ||
        (ctasNeededToMakePicks && {
          children: "Picks Coming Soon!",
          variant: "gray",
        }) ||
        (isSingleOptionParlay && {
          children: `Answer any ${minRequiredPicks} questions to build your parlay`,
          variant: "blue",
        }) || {
          children: "Make your Picks!",
          variant: "gray",
          hideForHandheld: true,
        }
    // TODO qac: extract
    const botEntryBarValues = [] as IDlItemProps[]
    if (isSingleBracket) {
      // nothing
    } else if (isParlay) {
      if (isSingleOptionParlay) {
        botEntryBarValues.push({
          dt: "W-L",
          dd: pickUtils.hasScoredEvent() ? `${pickUtils.getCorrectPicks().length}-${pickUtils.getIncorrectPicks().length}` : "-",
        })
      } else {
        botEntryBarValues.push({
          dt: ptsAvailDt,
          dd: (parlayStatus !== "busted" && pickUtils.parlayScoringMapping[picks.length]) || 0,
        })
      }

      botEntryBarValues.push({
        dt: "Pts Back",
        dd: (!!leaderTotalFantasyPoints && toTruncatedNumber(leaderTotalFantasyPoints - ytdFantasyPoints)) || "-",
      })
      botEntryBarValues.push({
        dt: "In Progress",
        dd: pickedEvents.filter((evt) => evt.startsAt < nowAt && !evt.winningTeamId).length || "-",
      })
    } else if (isSinglePeriodGame) {
      if (isBracket) {
        const prefixAttr = isSingleTournament ? "" : "Overall "
        if (isSingleTournament) {
          botEntryBarValues.push({
            dt: "W-L",
            dd: pickUtils.hasScoredEvent() ? `${pickUtils.getCorrectPicks().length}-${pickUtils.getIncorrectPicks().length}` : "-",
          })
        } else {
          botEntryBarValues.push({
            dt: prefixAttr + "Pts",
            dd: toTruncatedNumber(ytdFantasyPoints) || "-",
            usesScoringBreakdown: false,
          })
        }
        botEntryBarValues.push({
          dt: prefixAttr + "Rank",
          dd: toRank(poolRank) || "-",
        })
        botEntryBarValues.push({
          dt: "Pts Back",
          dd: (!!leaderTotalFantasyPoints && toTruncatedNumber(leaderTotalFantasyPoints - ytdFantasyPoints)) || "-",
        })
      } else {
        botEntryBarValues.push({
          dt: "Points",
          dd: toTruncatedNumber(ytdFantasyPoints) || "-",
        })
        // poolPeriodRank, poolPeriodFantasyPoints,
        botEntryBarValues.push({
          dt: "Rank",
          dd: toRank(poolRank) || "-",
        })
        // const totalScoredPicksCount = (totalPicksCount || 0) - pickedEvents.filter((evt) => !evt.winningTeamId);
        // const totalCorrectPicksCount = ytdFantasyPoints || 0;
        botEntryBarValues.push({
          dt: "W-L",
          dd: pickUtils.hasScoredEvent() ? `${pickUtils.getCorrectPicks().length}-${pickUtils.getIncorrectPicks().length}` : "-",
        })
      }
    } else {
      botEntryBarValues.push({
        dt: "W-L",
        dd: pickUtils.hasScoredEvent()
          ? `${pickUtils.getCorrectPicks().length}-${pickUtils.getIncorrectPicks().length + pickUtils.getMissedPickCount()}`
          : "-",
      })
      botEntryBarValues.push({
        dt: isRoundPeriodGame ? "Round" : "Week",
        dd: constants.DEFAULT_RANK !== poolPeriodRank ? toRank(poolPeriodRank) || "-" : "-",
      })
      // poolPeriodRank, poolPeriodFantasyPoints,
      botEntryBarValues.push({
        dt: isPostSeason ? "Overall" : "Season",
        dd: toRank(poolRank) || "-",
      })
    }

    const headerVals = {
      left: period.description,
      right: `${possiblePicksCount} Games`,
      className: `is-hidden-for-bottom-pick-slip--true`,
    }
    // const LeftColsGroupComponent = isBracket ? <div className="col__group"></div>
    const usesMultipleHorizSections = !!calloutBar
    const mobileStickyFooterTiebreakerHidden = isBottomStickyPickSlip && !mobileStickyFooterTiebreakerRevealed
    if (calloutBar && mobileStickyFooterTiebreakerHidden) {
      calloutBar.isProminentVersion = true
    }
    const isMiddleColDimmed = isBottomStickyPickSlip && !mobileStickyFooterTiebreakerHidden
    const layoutSharedClassNames = [
      `is-ios--${isIosBrowser}`,
      `is-multiple-horiz-sections--${usesMultipleHorizSections}`,
      `is-bottom-sticky-pick-slip--${isBottomStickyPickSlip}`,
      `is-middle-col-dimmed--${isMiddleColDimmed}`,
    ].join(" ")
    // ``;
    this.log("PicksInner")
    // console.log(`${isChallengeWithLockedGame} && hasRulesAndPrizingOnboardingElement: ${hasRulesAndPrizingOnboardingElement}`)
    // this.log(tiebreakerFields)
    // this.log(tiebreakerAnswers)

    const inlineAdProps = BidBarrel.getDomValues({
      adLayoutProps: this.props.layoutProps,
      adSlot: "gambling_partner",
      requestedPos: "dynamic",
    })
    let impressionTrackingExtra = {}
    if (isMarchMadness) {
      impressionTrackingExtra = isChallengePool
        ? { sponsorName: "nissan logo", nissanLogoImpression: 1 }
        : { sponsorName: "att logo", attLogoImpression: 1 }
    }
    return (
      <PoolPage className={`${layoutSharedClassNames} is-entry-bar-open--${isEntryBarOpen} needs-dnd-mode--${needsDndMode}`}>
        {isIosBrowser && (
          <Helmet>
            <body className="disable-ios-tablet-overflowscroll" />
          </Helmet>
        )}
        {isMarchMadness ? (
          <AnalyticScreen feature="brackets" subfeature={`make-picks`} title={`Bracket`} deviceType={deviceType} extra={impressionTrackingExtra} />
        ) : (
          <AnalyticScreen feature="picks" subfeature={`make picks`} title={`Picks - Make Picks - List view`} />
        )}
        {season && (
          <GameOgMeta gameInstanceUid={gameInstanceUid} seasonType={season.season} year={season.year} poolName={poolName} entryName={entryName} />
        )}

        {((hasPeriodNav || hasManagerRole) && !areaHasMultipleEntriesPerUser && (
          <>
            <PoolPageActionsToggleButton>
              <IconButton type="button" onClick={this.togglePoolPageActions} aria-label="Toggle Actions">
                <Options />
                <CarrotSvg variant={this.state.poolPageActionsOpen ? "up" : "down"} />
              </IconButton>
            </PoolPageActionsToggleButton>
            <PoolPageActions poolPageActionsOpen={this.state.poolPageActionsOpen}>
              {(hasManagerRole && (
                <Toggle onChange={(newValue) => this.handleUpdateMode(newValue)} label="Manager Mode" checked={!!mode} name="manager-mode" />
              )) ||
                null}
              {(hasPeriodNav && (
                <PeriodSelect
                  order={order}
                  updateOrder={updateOrder}
                  period={period}
                  disabled={tournamentIds.length === 1}
                  className={`is-bracket--${isBracket}`}
                />
              )) ||
                null}
            </PoolPageActions>
          </>
        )) ||
          null}

        <PicksPageLayout
          handheldUsesSticky={isIosBrowser || isBottomStickyPickSlip}
          className={`${layoutSharedClassNames} is-bracket--${isBracket}`}
          topCenterElement={
            (hasRulesAndPrizingOnboardingElement && !isBottomStickyPickSlip && (
              <RulesAndPrizing
                segmentForArea={segmentForArea}
                isChallengeWithLockedGame={isChallengeWithLockedGame}
                lateToTheGame={lateToTheGame}
                className="dt-handheld--true"
                id="top"
              />
            )) ||
            null
          }
          leftCol={
            (usesMultipleHorizSections && (
              <EntryBar
                id={this.elementIds.entryBar}
                isAlwaysCollapsed={isBracket}
                isOpenTop={this.state.entryBarOpenTop}
                stickyTop={this.state.entryBarMobileTop}
                isBottomStickyPickSlip={isBottomStickyPickSlip}
                childrenMaxHeight={this.state.entryBarDesktopInnerHeight}
                hasStartedEvent={hasStartedEvent}
                desktopPaddingBottom={isBracket ? 95 : 30}
                toggle={this.toggleEntryBarModal}
                preLockPromo={
                  (!needsToMakePicks && useWhInPickSlip && (
                    <WillHillCalloutContainer>
                      <div>
                        <section>
                          <header>Confident in your picks?</header>
                          <p>With a simple $10 bet your could win:</p>
                        </section>
                        <section>
                          <header>Winnings</header>
                          <p>
                            <strong>$12,300</strong>
                          </p>
                        </section>
                      </div>
                      <ButtonBase type="button">Place Wager @ Caesars Sportsbook</ButtonBase>
                    </WillHillCalloutContainer>
                  )) ||
                  null
                }
                banner={(calloutBar && <CalloutBar {...calloutBar} />) || null}
                hideBannerForHandheld={calloutBar?.hideForHandheld || false}
                cta={(isBracket && `View Tournaments`) || (isParlay && `View Parlay Slip`) || `View Pick Slip`}
                isIosBrowser={isIosBrowser}
                isHandheldDeviceType={isHandheldDevice}
                heading={
                  isBracket ? (
                    <ThreeCol>
                      <h4>{(period && period.description) || ""}</h4>
                      {(isSingleTournament && (
                        <h5>
                          <DlItem
                            dt={isPeriodLocked ? "Locked" : "Lock"}
                            dd={
                              (period.locksAt &&
                                DateTime.fromMillis(period.locksAt).toFormat(Math.abs(Date.now() - period.locksAt) < oneDay ? "L/dd" : "h:mma")) ||
                              null
                            }
                          />
                        </h5>
                      )) ||
                        (isPeriodLocked && (
                          <h5>
                            <DlItem dt={"Conf Rank"} dd={toRank(periodPoints?.node?.poolRank) || "-"} />
                          </h5>
                        )) || (
                          <>
                            <h5>{allPeriodPicksMade && !pendingTiebreakerInputOnly ? "Complete" : "Incomplete"}</h5>
                          </>
                        )}
                      {(isSingleTournament && isPeriodLocked && (
                        <h5>
                          <DlItem dt={"Pts"} dd={toTruncatedNumber(ytdFantasyPoints) || "0"} />
                        </h5>
                      )) ||
                        (isSingleTournament && (
                          <h5>
                            <DlItem dt={"Picks"} dd={`${picksCount}/${maxPicksAllowed}`} />
                          </h5>
                        )) ||
                        (isPeriodLocked && isChallengePool && (
                          <h5>
                            <DlItem dt={"Conf %"} dd={toPercentile(periodPoints?.node?.percentile) || "-"} />
                          </h5>
                        )) ||
                        (isPeriodLocked && (
                          <h5>
                            <DlItem dt="Conf Pts" dd={toTruncatedNumber(periodPoints?.node?.fantasyPoints) || "0"} />
                          </h5>
                        )) || <h5>{`${picksCount}/${maxPicksAllowed} Picks`}</h5>}
                    </ThreeCol>
                  ) : (
                    <ThreeCol>
                      <h4 className="hide-on-scrolling--true">Pick Slip</h4>
                      <h4 className="hide-on-scrolling--false">
                        <DlItem {...botEntryBarValues[0]} />
                      </h4>
                      {(isParlay && false && (
                        <div>
                          Status: <OneMillionSvg disabled={!isPrizeEligible} id="entrybar" />
                        </div>
                      )) ||
                        (earliestPickedEventStarted && (
                          <h5>
                            <DlItem
                              className="hide-on-scrolling--true"
                              dt={`${isPostSeason ? "Tot" : "YTD"} Pts`}
                              dd={ytdFantasyPoints}
                              showScoringBreakdown={this.showScoringBreakdown}
                            />
                            <DlItem className="hide-on-scrolling--false" {...botEntryBarValues[1]} showScoringBreakdown={this.showScoringBreakdown} />
                          </h5>
                        )) || <h5>{(allPeriodPicksMade && !pendingTiebreakerInputOnly) || period.skipPeriodText ? "Complete" : "Incomplete"}</h5>}
                      <h5>{`${picksCount}/${period.skipPeriodText ? 0 : maxPicksAllowed} Picks`}</h5>
                    </ThreeCol>
                  )
                }
                preScroll={
                  <ThreeCol as="dl">
                    {botEntryBarValues.map((val) => (
                      <DlItem as="div" key={val.dt} showScoringBreakdown={this.showScoringBreakdown} {...val} />
                    ))}
                  </ThreeCol>
                }
              >
                {((showPicksLoading || !period) && <LoadingView />) ||
                  (isBracket && (
                    <>
                      {(!isSingleTournament &&
                        entryBarPeriods.map((per) => {
                          const isEntryBarPeriodLocked = !!(per.locksAt && per.locksAt < nowAt)
                          return (
                            <EntryBarTournamentLink
                              entryPeriodPointsGlance={entryPeriodPointsGlance}
                              isHighlighted={period.id === per.id}
                              isLocked={isEntryBarPeriodLocked}
                              key={per.id}
                              location={this.props.location}
                              maxPicksCount={period.id === per.id ? possiblePicksCount : per.maxPicksCount}
                              period={per}
                              picksCount={period.id === per.id ? picksCount : undefined}
                            />
                          )
                        })) ||
                        null}
                      {(tournamentIds.length < 3 && <BcgPromo />) || null}
                    </>
                  )) ||
                  (pickedEvents.length && (
                    <div>
                      {pickedEvents.map((event) => {
                        const pick = picks.find((pk) => pk.slotId === event.id)
                        const itemId = (pick && pick.itemId) || null
                        const additionalPoints = pick && pick.additionalPoints
                        const cacheBuster = pickUtils.cacheKeyFor(event) + additionalPoints + itemId
                        return (
                          <EntryBarTeam
                            slotId={event.id}
                            key={event.id}
                            itemId={itemId}
                            pickUtils={pickUtils}
                            onClick={this.onRemovePickClick}
                            correctItemId={correctPickMapping[event.id]}
                            isValid={hideTiebreakerUntilAllPicksMade && hasMinRequiredPicks && !needsToSave}
                            canUseTeamLogos={canUseTeamLogos}
                            cacheBuster={cacheBuster}
                          />
                        )
                      })}
                      {(!period.skipPeriodText && eventOfThePeriod && secondEventOfThePeriod && (
                        <PicksParlayBonusMultipleGames
                          className="dt-handheld--true in-modal--true"
                          fields={emptyArray}
                          canUseTeamLogos={canUseTeamLogos}
                          fauxFields={(isTiebreakerEnabled && tiebreakerFields) || undefined}
                          isMinimal={true}
                          values={tiebreakerFormValues}
                          canEnter={isTiebreakerEnabled}
                          picksCount={picksCount}
                          totalPicksCount={possiblePicksCount}
                          isParlay={isParlay}
                          eventOfThePeriod={eventOfThePeriod}
                          secondEventOfThePeriod={secondEventOfThePeriod}
                          onLastTiebreakerInfoClick={(hasLastTiebreakerInputBreakdown && isParlay && this.onLastTiebreakerInfoClick) || undefined}
                          onEditTiebreakerClick={(requiresManualSubmit && isPrizeEligible && this.editTiebreakerClick) || undefined}
                          isIosWebview={isIosWebview}
                        />
                      )) ||
                        (!period.skipPeriodText && (
                          <PicksParlayBonus
                            className="dt-handheld--true in-modal--true"
                            fields={emptyArray}
                            canUseTeamLogos={canUseTeamLogos}
                            fauxFields={(isTiebreakerEnabled && tiebreakerFields) || undefined}
                            isMinimal={true}
                            values={tiebreakerFormValues}
                            canEnter={isTiebreakerEnabled}
                            picksCount={picksCount}
                            totalPicksCount={possiblePicksCount}
                            isParlay={isParlay}
                            eventOfThePeriod={eventOfThePeriod}
                            onLastTiebreakerInfoClick={(hasLastTiebreakerInputBreakdown && isParlay && this.onLastTiebreakerInfoClick) || undefined}
                            onEditTiebreakerClick={(requiresManualSubmit && isPrizeEligible && this.editTiebreakerClick) || undefined}
                            isIosWebview={isIosWebview}
                          />
                        ))}
                    </div>
                  )) || (
                    <CtaModule className="need-picks-el" iconSvg={<ErrorSvg />}>
                      {period.skipPeriodText
                        ? period.skipPeriodText
                        : isMine
                        ? "You still need to make your picks for this week."
                        : "No viewable picks"}
                    </CtaModule>
                  )}
              </EntryBar>
            )) ||
            null
          }
          rightCol={
            (!isBracket && (
              <>
                {/* {!showPicksLoading && showOnboarding && hasRules && !showRulesInCenter && (
                  <RulesAndPrizing segmentForArea={segmentForArea} id="right-top" rules={rules} />
                )} */}
                <SportslineAd>
                  {deviceType === "desktop" && constants.sportslineIframes[sportType] && (
                    <iframe title="Sportsline Articles" height="400px" width="auto" seamless={true} src={constants.sportslineIframes[sportType]} />
                  )}
                </SportslineAd>
                <FantasyArticlesSidebar
                  sportTitle={sportTitle}
                  sportType={sportType}
                  gameInstanceUid={gameInstanceUid}
                  deviceType={deviceType}
                  season={season}
                />
                {(deviceType === "desktop" && <Ad adSlot={"mpu_top"} useShSkybox={false} {...BidBarrel.extractProps(this.props)} />) || null}
                {/*sportType === "NFL" && <AllAccessAd /> || null*/}
                {/* {!showPicksLoading && !showOnboarding && hasRules && <RulesAndPrizing period={period} id="right-bot" rules={rules} />} */}

                {showRulesAndPrizingOnRightRail && (
                  <RulesAndPrizing
                    segmentForArea={segmentForArea}
                    isChallengeWithLockedGame={isChallengeWithLockedGame}
                    lateToTheGame={lateToTheGame}
                    className={isBottomStickyPickSlip ? undefined : `dt-handheld--false font-size--small`}
                    id="bottom_right"
                  />
                )}
              </>
            )) ||
            null
          }
          bottomCenterElement={
            (!isBracket && !showPicksLoading && hasTiebreakerInputFields && canUseDom && (
              <Formik initialValues={tiebreakerFormValues} enableReinitialize={!requiresManualSubmit} onSubmit={this.onTiebreakerSubmit}>
                {(formikBag) => {
                  const { isSubmitting, status, values, dirty, handleSubmit, handleReset } = formikBag
                  // const bottom = 0
                  // console.dir(`'formikBag'`)
                  // console.dir(formikBag)
                  // console.dir(values)
                  // console.dir(tiebreakerFormValues)
                  const picksChanged = requiresManualSubmit && values.picks !== picksSnapshot
                  const isDirty = picksChanged || dirty
                  const tiebreakerUnansweredCount =
                    (!!values &&
                      Object.values(values.tiebreakerAnswers || emptyObject).filter((x) => {
                        if (x == null) {
                          // ignore null & undefined
                          return true
                        }
                        return x < 0
                      }).length) ||
                    0
                  const tiebreakerAnsweredCount = tiebreakerFieldCount - tiebreakerUnansweredCount
                  const allFieldsFilled = tiebreakerUnansweredCount === 0
                  const isWarningState = pendingTiebreakerInputOnly && isChallengePool
                  const hasSubmittedEntry = isPrizeEligible && !needsToMakePicks
                  const hasTiebreakerPendingChanges = allFieldsFilled && dirty
                  const submitIsDisabled = requiresManualSubmit
                    ? !hasMinRequiredPicks || (!hasTiebreakerPendingChanges && !picksChanged)
                    : !hasTiebreakerPendingChanges
                  // console.debug(
                  //   `submitIsDisabled: allFieldsFilled: ${allFieldsFilled}, dirty: ${dirty}, needsToMakePicks: ${needsToMakePicks}, isPrizeEligible: ${isPrizeEligible}`,
                  // )
                  // !(requiresManualSubmit && hasSubmittedEntry) &&
                  // (!hasMinRequiredPicks ||
                  //   !isDirty ||
                  //   (isWarningState && !allFieldsFilled));
                  const inertMsg = requiresManualSubmit
                    ? (hasSubmittedEntry && !mobileStickyFooterTiebreakerHidden && "Save Tiebreaker") ||
                      // (hasSubmittedEntry && "Change Picks") ||
                      (isWarningState && "Submit Entry") ||
                      // (isPrizeEligible && "Save Changes") ||
                      "Submit Picks"
                    : "Submit Tiebreaker"
                  // console.dir(`allFieldsFilled: ${allFieldsFilled} isDirty: ${isDirty}`)
                  // usesTiebreakerPrompts = (isParlay || season === "post") && isChallengePool // requiresManualSubmit
                  // const inertMsg = isWarningState ? "" : inertDefault; // hasMinRequiredPicks ? inertDefault : `Minimum ${pickUtils.getMinRequiredPicks()} Picks Required`;
                  const tiebreakerQuestionTitle = period.tiebreakerQuestionTitle || period.description
                  const showStartMakingPicksCta = canEdit && picksCount === 0 && isBottomStickyPickSlip && isHandheldDevice
                  const hasNoActions =
                    !canEdit || showStartMakingPicksCta || (isParlay ? parlayStatus !== "unlocked" : tiebreakerIsLocked && secondTiebreakerIsLocked)
                  const hasToggleTiebreakerCta = !hasNoActions && isBottomStickyPickSlip && requiresManualSubmit && mobileStickyFooterTiebreakerHidden
                  const hasPersistPicksCta = !period.skipPeriodText && !hasNoActions
                  const showChangeBtn = hasSubmittedEntry && requiresManualSubmit && mobileStickyFooterTiebreakerHidden
                  const showSaveBtn = !hasNoActions && !showChangeBtn
                  const tiebreakerDisabled = tiebreakerIsLocked
                  const secondTiebreakerDisabled = secondTiebreakerIsLocked
                  const showEditTiebreakerPrompt = tiebreakerDisabled && isPrizeEligible && !hasNoActions
                  const toggleTiebreakerBtnDisabled = !isWarningState && !showEditTiebreakerPrompt
                  const showPicksProgress = isBottomStickyPickSlip && !hasSubmittedEntry
                  const bottomPickSlipMode =
                    (canEdit && picksCount === 0 && isBottomStickyPickSlip && "hidden") ||
                    (hasSubmittedEntry && mobileStickyFooterTiebreakerHidden && "skinny") ||
                    "normal"
                  const submitClassname = [
                    `is-warning-state--${isWarningState}`,
                    `all-feilds-filled--${allFieldsFilled}`,
                    `show-start-making-picks-cta--${showStartMakingPicksCta}`,
                    `is-dirty--${isDirty}`,
                    `has-no-actions--${hasNoActions}`,
                    `has-toggle-tiebreaker-cta--${hasToggleTiebreakerCta}`,
                    `has-persist-picks-cta--${hasPersistPicksCta}`,
                    `is-mobile-in-view--${mobileTiebreakerInView}`,
                    `is-custom-tiebreaker--${usesCustomTiebreaker}`,
                    `show-both-btns--${showSaveBtn && showEditTiebreakerPrompt}`,
                    `show-toggle-tiebreaker--${(!isPrizeEligible || showEditTiebreakerPrompt) && mobileStickyFooterTiebreakerHidden}`,
                  ].join(" ")
                  const warningStateElClassname =
                    usesTiebreakerPrompts && [`warning-state-el`, `is-outside-form--false`, `${submitClassname}`].join(" ")
                  const warningStateText =
                    (usesTiebreakerPrompts &&
                      (allFieldsFilled
                        ? requiresManualSubmit
                          ? "Submit Completed Entry"
                          : "Save Tiebreaker Answers"
                        : "Enter Tiebreaker Answers")) ||
                    undefined
                  const toggleTiebreakerBtnOnlyClassnames = [
                    submitClassname,
                    "picks-submit__toggle-tiebreaker-btn",
                    "is-submitting--false",
                    `is-link-version--${showEditTiebreakerPrompt}`,
                  ].join(" ")
                  const submitBtnOnlyClassnames = [
                    submitClassname,
                    "picks-submit__submit-btn",
                    `is-mobile-tiebreaker-hidden--${mobileStickyFooterTiebreakerHidden}`,
                    `is-change-version--${hasSubmittedEntry && requiresManualSubmit}`,
                  ].join(" ")
                  const submitBtnContainerClassnames = [
                    `is-bottom-sticky-pick-slip--${isBottomStickyPickSlip}`,
                    `bottom-sticky-pick-slip-mode--${bottomPickSlipMode}`,
                    `is-ios--${isIosBrowser}`,
                    `is-entry-bar-open--${isEntryBarOpen}`,
                    `is-mobile-tiebreaker-hidden--${mobileStickyFooterTiebreakerHidden}`,
                  ].join(" ")
                  // console.debug(`submitClassname: ${submitClassname} (pendingTiebreakerInputOnly: ${tiebreakerIsLocked}, ${showSaveBtn}, ${isChallengePool})`)
                  // console.dir(period)
                  return (
                    <>
                      <PicksSubmitBtnContainerStyled
                        className={submitBtnContainerClassnames}
                        style={{ bottom: this.state.tieBreakerFromBottomPosition }}
                      >
                        <div className="picks-submit__wrapper">
                          <form
                            className="picks-submit__container"
                            action="#"
                            onSubmit={handleSubmit}
                            onReset={handleReset}
                            id={this.elementIds.entryBarStickyFooter}
                            ref={this.entryBarStickyFooterRef}
                          >
                            <div className="picks-submit__tiebreaker-container">
                              <div className="picks-submit__tiebreaker">
                                <div className="picks-submit__tiebreaker-cap">
                                  <h6>
                                    <strong>Tiebreaker</strong>
                                  </h6>
                                  <p>If you tie with someone else, these answers will decide the winner.</p>
                                </div>
                                {(!period.skipPeriodText && eventOfThePeriod && secondEventOfThePeriod && (
                                  <PicksParlayBonusMultipleGames
                                    {...formikBag}
                                    className="picks-submit__tiebreaker-form"
                                    fields={tiebreakerFields}
                                    canUseTeamLogos={canUseTeamLogos}
                                    isMinimal={true}
                                    canEnter={isTiebreakerEnabled}
                                    picksCount={picksCount}
                                    totalPicksCount={possiblePicksCount}
                                    isParlay={isParlay}
                                    eventOfThePeriod={eventOfThePeriod}
                                    secondEventOfThePeriod={secondEventOfThePeriod}
                                    tiebreakerQuestionTitle={tiebreakerQuestionTitle}
                                    firstTiebreakerDisabled={tiebreakerDisabled}
                                    secondTiebreakerDisabled={secondTiebreakerDisabled}
                                    onLastTiebreakerInfoClick={
                                      (hasLastTiebreakerInputBreakdown && isParlay && this.onLastTiebreakerInfoClick) || undefined
                                    }
                                    isIosWebview={isIosWebview}
                                  />
                                )) ||
                                  (!period.skipPeriodText && (
                                    <PicksParlayBonus
                                      {...formikBag}
                                      className="picks-submit__tiebreaker-form"
                                      fields={tiebreakerFields}
                                      canUseTeamLogos={canUseTeamLogos}
                                      isMinimal={true}
                                      canEnter={isTiebreakerEnabled}
                                      picksCount={picksCount}
                                      totalPicksCount={possiblePicksCount}
                                      isParlay={isParlay}
                                      eventOfThePeriod={eventOfThePeriod}
                                      tiebreakerQuestionTitle={tiebreakerQuestionTitle}
                                      disabled={tiebreakerDisabled}
                                      onLastTiebreakerInfoClick={
                                        (hasLastTiebreakerInputBreakdown && isParlay && this.onLastTiebreakerInfoClick) || undefined
                                      }
                                      isIosWebview={isIosWebview}
                                    />
                                  ))}
                              </div>
                            </div>
                            <div className="picks-submit__submit-container">
                              {(showStartMakingPicksCta && (
                                <div className="picks-submit__submit-make-picks-cta">
                                  <ArrowSvg variant="up" icon={true} />
                                  <div>
                                    <h2>
                                      <strong>{headerVals.left}</strong>
                                    </h2>
                                    <p>Make your picks now!</p>
                                  </div>
                                </div>
                              )) ||
                                ((!hasNoActions || !isBottomStickyPickSlip) && (
                                  <>
                                    {(hasPersistPicksCta && (
                                      <FormButtonSpinnerBase
                                        as={MainSaveBtn}
                                        inert={inertMsg}
                                        success="Submitted!"
                                        type={"submit"}
                                        status={status}
                                        disabled={submitIsDisabled}
                                        isSubmitting={isSubmitting}
                                        className={submitBtnOnlyClassnames}
                                        isValid={true}
                                      />
                                    )) ||
                                      null}
                                    {(hasToggleTiebreakerCta && (
                                      <MainSaveBtn
                                        type="button"
                                        className={toggleTiebreakerBtnOnlyClassnames}
                                        disabled={toggleTiebreakerBtnDisabled}
                                        onClick={this.toggleStickyFooterTiebreaker}
                                      >
                                        {showEditTiebreakerPrompt ? "Edit Tiebreaker" : "Submit Picks"}
                                      </MainSaveBtn>
                                    )) ||
                                      null}
                                  </>
                                )) ||
                                null}
                              {hasSubmittedEntry && mobileStickyFooterTiebreakerHidden && (
                                <IconButton
                                  type="button"
                                  className="is-inline--true picks-submit__footer-toggle-pick-slip-cta"
                                  onClick={this.toggleEntryBarModal}
                                >
                                  <CarrotSvg icon={true} variant="up" />
                                  <strong>Pick Slip</strong>
                                  <div className="picks-submit__counter">{picksCount}</div>
                                </IconButton>
                              )}
                              {(showPicksProgress && !mobileStickyFooterTiebreakerHidden && (
                                <div className="picks-submit__footer-right">
                                  <div className="picks-submit__picks-count">
                                    {tiebreakerAnsweredCount} / {tiebreakerFieldCount} Answered
                                  </div>
                                  <div className="picks-submit__fill-bar-container">
                                    <div
                                      className="picks-submit__fill-bar"
                                      style={{
                                        width: `${Math.round((tiebreakerAnsweredCount / tiebreakerFieldCount) * 100)}%`,
                                      }}
                                    />
                                  </div>
                                </div>
                              )) ||
                                (showPicksProgress && (
                                  <div className="picks-submit__footer-right">
                                    <div className="picks-submit__picks-count">
                                      {picksCount} / {possiblePicksCount} Picks
                                    </div>
                                    <div className="picks-submit__fill-bar-container">
                                      <div
                                        className="picks-submit__fill-bar"
                                        style={{
                                          width: `${Math.round((picksCount / possiblePicksCount) * 100)}%`,
                                        }}
                                      />
                                    </div>
                                  </div>
                                )) ||
                                null}
                              {(usesTiebreakerPrompts && (
                                <WarningStateEl className={submitClassname || undefined}>
                                  <ArrowSvg />
                                  <span>{warningStateText}</span>
                                </WarningStateEl>
                              )) ||
                                null}
                            </div>
                          </form>
                        </div>
                      </PicksSubmitBtnContainerStyled>
                      {(usesTiebreakerPrompts && (
                        <WarningStateEl className={warningStateElClassname || undefined}>
                          <ArrowSvg />
                          <span>{warningStateText}</span>
                        </WarningStateEl>
                      )) ||
                        null}
                    </>
                  )
                }}
              </Formik>
            )) ||
            null
          }
        >
          {(isBracket && (
            <BracketForm
              className={`${layoutSharedClassNames} bracket-container`}
              as={"form" as any}
              disabled={showPicksLoading || !(canEdit && bracketIsUnlocked)}
              onSubmit={this.onBracketSubmit}
              noValidate={true}
            >
              {(showPicksLoading && <LoadingView />) ||
                (bracketIsReleased && (
                  <Bracket
                    tournamentId={tournamentId}
                    onInputChange={this.onInputChange}
                    showMatchupAnalysis={this.toggleMatchupAnalysis}
                    slotIdSavingAnimation={slotIdSavingAnimation}
                    pickUtils={pickUtils}
                    canEdit={canEdit && bracketIsUnlocked}
                    scrollToSection={this.scrollToSection}
                    bracketNextBtnText={bracketNextBtnText}
                    onBracketSubmit={this.onBracketSubmit}
                    isSingleBracket={isSingleBracket}
                    hasManagerRole={hasManagerRole}
                    mode={mode}
                    updateMode={updateMode}
                    gameInstanceUid={gameInstanceUid}
                  />
                )) || (
                  <CtaModule iconSvg={<EmptyPageFillerSvg sportType={period.segment.sportType} />}>
                    <span>This bracket will be available after all seeds are finalized. Check back soon!</span>
                  </CtaModule>
                )}
            </BracketForm>
          )) ||
            (ctasNeededToMakePicks && <>{ctasNeededToMakePicks}</>) || (
              <>
                {(hasRulesAndPrizingOnboardingElement && (
                  <RulesAndPrizing
                    segmentForArea={segmentForArea}
                    isChallengeWithLockedGame={isChallengeWithLockedGame}
                    lateToTheGame={lateToTheGame}
                    className={isBottomStickyPickSlip ? undefined : `dt-handheld--false static-copy`}
                    id="middle"
                  />
                )) ||
                  null}
                <PicksPageHeader {...headerVals} />
                {(needsDndMode && (
                  <Toggle
                    before={false}
                    onChange={this.toggleWeightingMode}
                    label="Rank your picks mode"
                    checked={!!inWeightingMode}
                    name="dnd-mode"
                  />
                )) ||
                  null}
                {!isParlay && constants.SHOW_GAMBLING_PARTNER_BRANDING && <GamblingPartnerPickTileIntegration />}
                {(showPicksLoading && <LoadingView />) ||
                  (enableDnd && (
                    <List
                      getScrollContainerEl={getScrollContainerEl}
                      values={events}
                      onChange={this.onDragChange}
                      lockVertically={true}
                      renderList={({ children, props: dndProps, isDragged }) => (
                        <div style={(isDragged && { cursor: "grabbing" }) || undefined} className={`events-container`} {...dndProps}>
                          {children}
                        </div>
                      )}
                      renderItem={(_dndProps) => {
                        const { value: event, props: dndPropsWithRef, isDragged, isSelected, moveItem } = _dndProps
                        const eventIndex = events.indexOf(event)
                        return (
                          <PickemEvent
                            key={eventIndex}
                            eventId={event.id}
                            pickTeam={this.pickTeam}
                            showScoringBreakdown={this.showScoringBreakdown}
                            showMatchupAnalysis={this.toggleMatchupAnalysis}
                            cacheBuster={pickUtils.cacheKeyFor(event)}
                            isMine={canEdit}
                            lastScoredAt={lastScoredAt}
                            pickUtils={pickUtils}
                            showSaveAnimation={showSaveAnimation}
                            delaySaveCommit={delaySaveCommit}
                            log={this.log}
                            canUseTeamLogos={canUseTeamLogos}
                            useOutsideSaveAnimation={useOutsideSaveAnimation}
                            setWeightFor={this.setWeightFor}
                            isDragged={isDragged}
                            isSelected={isSelected}
                            moveItem={moveItem}
                            eventIndex={eventIndex}
                            mode={inWeightingMode ? "weighting" : undefined}
                            needsDndMode={needsDndMode}
                            {...dndPropsWithRef}
                          />
                        )
                      }}
                    />
                  )) || (
                    <div className={`events-container`}>
                      {events.map((event, index) => {
                        return (
                          <>
                            <PickemEvent
                              key={event.id as any}
                              eventId={event.id}
                              pickTeam={this.pickTeam}
                              showScoringBreakdown={this.showScoringBreakdown}
                              showMatchupAnalysis={this.toggleMatchupAnalysis}
                              cacheBuster={pickUtils.cacheKeyFor(event)}
                              isMine={canEdit}
                              lastScoredAt={lastScoredAt}
                              pickUtils={pickUtils}
                              showSaveAnimation={showSaveAnimation}
                              delaySaveCommit={delaySaveCommit}
                              useOutsideSaveAnimation={useOutsideSaveAnimation}
                              log={this.log}
                              canUseTeamLogos={canUseTeamLogos}
                              setWeightFor={this.setWeightFor}
                              needsDndMode={needsDndMode}
                            />
                            {index === 3 && deviceType === "desktop" && (
                              <Ad
                                adSlot={"gambling_partner"}
                                useShSkybox={false}
                                {...BidBarrel.extractProps(inlineAdProps)}
                                className="gambling-partner-inline-ad"
                              />
                            )}
                          </>
                        )
                      })}
                    </div>
                  )}
                {/*showTotalScoreTiebreaker && (
                <TiebreakerForm
                  initialValues={{tiebreaker}}
                  onSubmit={this.onTiebreakerSubmit}
                  canEdit={canEdit && !tiebreakerIsLocked}
                />
              )*/}
                {/*!showPicksLoading && hideTiebreakerUntilAllPicksMade && (
                  <PicksParlayBonus
                    className="dt-handheld--false"
                    canUseTeamLogos={canUseTeamLogos}
                    fauxFields={tiebreakerFields}
                    isMinimal={false}
                    canEnter={picksCount === possiblePicksCount && !pendingTiebreakerInputOnly}
                    picksCount={picksCount}
                    totalPicksCount={possiblePicksCount}
                    isParlay={isParlay}
                    eventOfThePeriod={eventOfThePeriod}
                    isIosWebview={isIosWebview}
                  />
                )*/}
                {(isMiddleColDimmed && <button type="button" className="picks-page-layout__dimmer" onClick={this.toggleStickyFooterTiebreaker} />) ||
                  null}
              </>
            )}
        </PicksPageLayout>
        {!isCbsAppWebview && (
          <NativeAppPromoModal
            isOpen={openModalKey === NativeAppPromoModal.modalKey}
            close={this.closeModal}
            afterOpen={this.markNativeAppPromoViewed}
            afterClose={this.afterModalClose}
            gameInstanceUid={gameInstanceUid}
            season={season}
          />
        )}
        <AuthModal
          isOpen={openModalKey === AuthModal.modalKey}
          close={this.closeModal}
          season={season}
          productAbbrev={season.productAbbrev}
          masterProductId={season.masterProductId}
          gameInstanceUid={gameInstanceUid}
          isCbsAppWebview={isCbsAppWebview}
          afterClose={this.afterModalClose}
        />
        <EntryNameModal
          isOpen={openModalKey === EntryNameModal.modalKey}
          mutation={mutation}
          entryId={entryId}
          periodId={periodId}
          poolId={poolId}
          gameInstanceUid={gameInstanceUid}
          onFinish={this.closeModal}
          season={season}
          invitationId={null}
          invitedByEntryId={null}
          invitedVia={null}
          joinKey={null}
          encryptedPassword={null}
          afterClose={this.afterEntryNameModalClose}
        />
        <ClearPicksModal
          isOpen={openModalKey === clearPicksModalKey}
          pickUtils={pickUtils}
          requiresPicksClear={!needsToMakePicks && !supportsPicksWithDifferentSpreadLockTimes}
          clearPicks={this.clearPicks}
          closeModal={this.closeModal}
          afterClose={this.afterModalClose}
        />
        <ScoringBreakdownModal {...smbModalProps} pickUtils={pickUtils} closeModal={this.closeModal} afterClose={this.afterModalClose} />
        {this.hasUpsellModal() && <UpsellModal {...upsellModalProps} close={this.closeModal} />}
        <MatchupAnalysis
          {...maProps}
          closeModal={this.closeModal}
          afterModalClose={this.afterModalClose}
          pickUtils={pickUtils}
          pickTeam={this.pickTeam}
          periodId={periodId}
          isMine={canEdit}
          gameInstanceUid={gameInstanceUid}
          lastScoredAt={lastScoredAt}
          showSaveAnimation={!requiresManualSubmit}
          log={this.log}
          canUseTeamLogos={canUseTeamLogos}
          season={season}
          isCbsAppWebview={isCbsAppWebview}
        />
        {!isBracket && (
          <GamblingPartnerUpsellModal
            isOpen={parts?.[0] === GamblingPartnerUpsellModal.modalKey && this.isModalOpen()}
            layoutProps={this.props.layoutProps}
            pickUtils={pickUtils}
            gameInstanceUid={gameInstanceUid}
            calloutBar={
              (parts?.[0] === GamblingPartnerUpsellModal.modalKey && this.isModalOpen() && isPrizeEligible && calloutBar?.children) || undefined
            }
            closeModal={this.closeModal}
            afterClose={this.afterModalClose}
          />
        )}
      </PoolPage>
    )
  }
}

export default Picks
