import React from "react"
import PickUtils from "../../../../common/pick-utils"
import { ButtonBase } from "../../../components/Button"
import ErrorMdSvg from "../../../components/icons/Error"
import { FormButtonSpinnerBase, FormSuccessStatus, IFormStatus, FormSubmittingStatus } from "../../../components/Form"
import Modal, { IModalProps, ModalWrapper } from "../../../components/Modal"

interface IClearPicksModal extends IModalProps {
  pickUtils: PickUtils
  requiresPicksClear: boolean
  closeModal: () => void
  clearPicks: () => Promise<any>
}

function ClearPicksModal(props: IClearPicksModal) {
  const { clearPicks, closeModal, analyticsScreen, requiresPicksClear, pickUtils, ...rest } = props
  const [status, setStatus] = React.useState<IFormStatus>("")
  const onClick = async () => {
    if (requiresPicksClear) {
      setStatus(FormSubmittingStatus)
      await clearPicks()
      setStatus(FormSuccessStatus)
      setTimeout(() => {
        setStatus("")
        closeModal()
      }, 800)
    }
  }
  if (!requiresPicksClear && !status) {
    const maxPicksAllowed = pickUtils.getMaxPicksAllowed()
    const isParlay = pickUtils.isParlay()
    return (
      <Modal modalType="dialog" analyticsScreen={analyticsScreen || "Max picks Modal"} {...rest}>
        <ModalWrapper header="Max Picks Made" isDialog={true} closeBtnAction={closeModal} icon={<ErrorMdSvg />}>
          <div>
            <header>
              <strong>You've already made {maxPicksAllowed} picks!</strong>
            </header>
            <p>
              {(isParlay &&
                `We know you can hit a bigger parlay, but all you need is ${maxPicksAllowed} games! Tap one of your previous answers to deselect it and then you can
              answer another question.`) ||
                `This pool is set up to only allow picking ${maxPicksAllowed} games in the slate. To select this game, you must deselect a previously selected game.`}
            </p>
          </div>
          <ButtonBase onClick={closeModal}>Got It</ButtonBase>
        </ModalWrapper>
      </Modal>
    )
  }
  return (
    <Modal modalType="dialog" analyticsScreen={analyticsScreen || "Clear Picks"} {...rest}>
      <ModalWrapper header="Confirm Action" isDialog={true} closeBtnAction={closeModal} icon={<ErrorMdSvg />}>
        <div>
          <header>
            <strong>Are you sure you want to unlock your picks?</strong>
          </header>
          <p>
            If you unlock your current entry, we will keep the original picks highlighted but spreads may have changed, and you will have to resubmit
            your new entry.
          </p>
        </div>

        <FormButtonSpinnerBase
          as={ButtonBase}
          inert="Unlock Picks"
          success="Picks Unlocked!"
          type="button"
          status={status}
          disabled={status === FormSubmittingStatus}
          isSubmitting={status === FormSubmittingStatus}
          onClick={onClick}
          isValid={true}
        />
      </ModalWrapper>
    </Modal>
  )
}

export default ClearPicksModal
