import * as React from "react"
import styled from "styled-components/macro"

const SVG = styled.svg`
  &.is-icon--true {
    height: 1.5em;
    width: 1.5em;
  }
`

interface IProps extends React.SVGProps<SVGSVGElement> {
  color?: string
  icon?: boolean
}

const SvgComponent = React.forwardRef<SVGSVGElement, IProps>(({ color = "currentColor", icon = false, className, ...props }, ref) => {
  const classNames = [] as string[]
  if (className) {
    classNames.push(className)
  }
  if (icon) {
    classNames.push("is-icon--true")
  }
  return (
    <SVG viewBox="0 0 512 512" className={classNames.join(" ")} {...props} ref={ref}>
      <path fill={color} d="M32 464h448L256 48 32 464zm248-64h-48v-48h48v48zm0-80h-48v-96h48v96z" />
    </SVG>
  )
})

const MemoizedComponent = React.memo(SvgComponent)
export default MemoizedComponent
