import { useQuery } from "@apollo/client"
import * as React from "react"
import { EntryPeriodPointsGlanceQuery, EntryPeriodPointsGlanceQueryVariables } from "../../../../__generated__/EntryPeriodPointsGlanceQuery"
import { serializePicks } from "../../../../common/common-utils-helpers"
import { ENUM_CHALLENGE, ENUM_MANAGER, SubsectionEnum } from "../../../../common/enums"
import { emptyArray } from "../../../../common/misc-utils"
import { withoutDomain } from "../../../../common/url-utils"
import { TPoolRouteProps } from "../../../../routes.d"
import { useDeviceType } from "../../../Base/DeviceType"
import { useLocalKey } from "../../../Base/LocalKey"
import LoadingView from "../../../components/LoadingView"
import useControls from "../../../hooks/useControls"
import { buildPollingProps, extractPoolOgProps, extractPoolSettings } from "../../../hooks/usePoolData"
import BidBarrel from "../../../utils/bid-barrel"
import { placeholderEntry, placeholderPeriodPoint } from "../../../utils/data-utils"
import { getLayoutId } from "../../../utils/url-utils"
import { defaultProps } from "../../Layout/components/Layout"
import { ENTRY_PERIOD_POINTS_GLANCE_QUERY } from "../../queries"
import { IPicksProps } from "./IPicksProps"
import Picks from "./PicksInner"
import useUpsertEntryMutation from "../../../hooks/useUpsertEntryMutation"
import constants from "../../../../common/constants"
import { canUseDom } from "../../../utils/misc-utils"
import { Redirect, useRouteMatch } from "react-router-dom"

// const debugLabel = "Picks";

function PicksWrapper(props: TPoolRouteProps) {
  // console.time(debugLabel);
  // console.debug('pickswrapper')
  // console.dir(props);
  const { location, history, match, poolData } = props
  const { params } = match
  const { pathname } = location
  const deviceType = useDeviceType()
  const controls = useControls(props)
  const { updateOrder } = controls
  const {
    allCurrentEntries,
    gameInstancesForArea,
    gameInstanceUid,
    poolId,
    isMine,
    entryId,
    hasAutogeneratedName,
    hasManagerRole,
    isManagerModeActive,
    isIosBrowser,
    isAndroidBrowser,
    isCbsAppWebview,
    hasCbsAccount,
    hasFantasyUser,
    centralTeams,
    poolDetail,
    detailedPeriod,
    detailedEntry,
    detailedPeriodIsLoadingOrChanging,
    productSeason,
    poolRoot,
    isInComingSoon,
    isChallengePool,
    segmentForArea,
    isBracket,
    gamblingPartnerInfo,
    entryDetailsIsLoadingOrChanging,
    entryDetailsVariables,
    areaHasMultipleEntriesPerUser,
    centralBracketState,
    toggleManagerMode,
  } = poolData
  // NOTE qac: we do NOT need to wait for detailedEntry to initialize since we dont want to show loading
  // after the first submit (creating the entry for the user)
  // only ensure we have detailedPeriod
  const tournamentsCount = (
    detailedPeriod?.segment.tournaments ||
    (segmentForArea?.subsection === SubsectionEnum.ConferenceTournament && [1, 2, 3, 4, 5, 6]) ||
    emptyArray
  ).length
  const isMultiTournamentTournament = tournamentsCount > 1
  const isSingleBracket = isBracket && !isMultiTournamentTournament

  const { upsertEntryMutation, entryPeriodPointsGlanceQueryVariables, entryPeriodPointsGlanceQuerySkip } = useUpsertEntryMutation(
    detailedEntry?.id,
    detailedPeriod?.segment.season.season,
    detailedPeriod?.segment.season.year,
    tournamentsCount,
    true,
  )
  const entryPeriodPointsGlanceQuery = useQuery<EntryPeriodPointsGlanceQuery, EntryPeriodPointsGlanceQueryVariables>(
    ENTRY_PERIOD_POINTS_GLANCE_QUERY,
    {
      variables: entryPeriodPointsGlanceQueryVariables,
      skip: entryPeriodPointsGlanceQuerySkip,
    },
  )
  // const [openModalKey, openModal] = React.useState<string | null>(null);
  // const localPickProps = useLocalKey("picks");
  // localPicks={localPickProps.value}
  // updateLocalPicks={localPickProps.update}
  const modalActions = useLocalKey("modal")
  const openModalKey = modalActions.value
  const openModal = modalActions.update
  const mensBracketLobbyMatch = useRouteMatch(constants.BRACKET_LOBBY_ROUTE)

  // END HOOKS

  // const requireAuthGate = (gameRequiresAuthGateForPickPage || isChallengeWithLockedGame || isOpenPoolCreate) && !hasCbsAccount;
  const mergedPoolSettings = extractPoolSettings(poolData)
  if (!detailedPeriod || !mergedPoolSettings) {
    return <LoadingView />
  }
  let canPreviewBracket: boolean | null = false
  if (isSingleBracket && areaHasMultipleEntriesPerUser && isInComingSoon && poolData.poolDetail) {
    canPreviewBracket = poolData.poolDetail.canViewEarly
    if (canUseDom && !canPreviewBracket) {
      // NOTE LL: Countdown page to go here
      return <Redirect to={`${withoutDomain(poolData.poolDetail.url)}/standings`} />
    }
  }

  const { isLocked } = centralBracketState?.bracketState || {}
  if (isSingleBracket && areaHasMultipleEntriesPerUser && !isChallengePool && !isLocked && !isManagerModeActive && !isMine) {
    return <Redirect to={mensBracketLobbyMatch?.url || ""} />
  }
  /*
  // NOTE qac: this doesnt work since it renders diff hooks based on loading above
  if (isSingleBracket) {
    console.timeLog(debugLabel, "bracket");
    const timeoutIds = React.useRef<TNumberMapping>({
      save: 0,
    });
    const [wipPicks, setWipPicks] = React.useState<IPickUtilsPicks>(detailedEntry?.picks || emptyArray);
    // const modalActions = useLocalKey("modal");
    const [isPersistingPicks, setIsPersistingPicks] = React.useState<boolean>(false);
    const [versionOfUi, setVersionOfUi] = React.useState<"svg" | "html">("html");
    console.timeLog(debugLabel, "pickUtils");
    const currentPickUtils = React.useRef<PickUtils | null>(null);
    const pickUtils = buildPickUtils(poolData, currentPickUtils.current, wipPicks, detailedEntry?.id);
    if (currentPickUtils.current !== pickUtils) {
      currentPickUtils.current = pickUtils;
    }
    const clearTimeoutById = (id: string) => {
      const curr = timeoutIds.current[id];
      if (curr && canUseDom) {
        window.clearTimeout(curr);
        timeoutIds.current[id] = 0;
      }
    };
    const setTimeoutById = (id: string, ms: number, handler: any) => {
      if (timeoutIds.current && canUseDom) {
        clearTimeoutById(id);
        timeoutIds.current[key] = window.setTimeout(handler, ms);
      }
    };
    // const getPendingPicks = () => pickUtils?.getDiffFrom(wipPicks);
    const persistPendingPicks = async (_id: number, force = false) => {
      const persistedPicks = detailedEntry?.picks || emptyArray;
      const picksToPersist = pickUtils?.getDiffFrom(persistedPicks) || emptyArray;
      if (picksToPersist.length || force === true) {
        setIsPersistingPicks(true);
        const periodId = detailedPeriod.id;
        const variables = {
          entryId,
          gameInstanceUid,
          poolId,
          periodId,
          picks: picksToPersist,
          tiebreakerAnswers: null,
        };
        if (!picksToPersist.length && !!tiebreakerAnswers) {
          // NOTE qac: this is a tiebreaker only submital (no changes)
          delete variables.picks;
        }
        try {
          const res = await upsertEntryMutation({variables});
          console.dir(res);
        } catch (ee) {
          console.error(ee);
          window.SH_ERROR_NOTIFY(ee, "pick-saving");
        }
        clearTimeoutById("save");
        setIsPersistingPicks(false);
      } else {
        clearTimeoutById("save");
      }
    };
    const queuePickPersisting = (ms: number) => {
      // console.debug(`queuePickPersisting: ${ms} (${this.canQueuePersistingPicks}, ${this.mounted})`);
      setTimeoutById("save", ms, persistPendingPicks);
    };
    const onItemClick = React.useCallback<TOnInputChange>((slotId, itemId, event) => {
      event.preventDefault();
      event.stopPropagation();
      // console.timeLog(savePicksLabel, `onItemClick: start`);
      const eventId = pickUtils?.slotIdForEventId(slotId);
      // console.log(`onItemClick: ${slotId} -> ${itemId} -> ${eventId}`);
      // console.timeLog(savePicksLabel, `onItemClick: eventId`);
      if (eventId && pickUtils) {
        const changes = pickUtils.setChanges(eventId, itemId);
        const merged = pickUtils.getMergedPicks(changes);
        // console.timeLog(savePicksLabel, `onItemClick: merged`);
        try {
          pickUtils.validate(changes);
          // this.clearErrors();
          // console.timeLog(savePicksLabel, `setting picks`);
          // console.dir(changes);
          setWipPicks(merged);
          // console.timeLog(savePicksLabel, `set picks`);
          queuePickPersisting(2200);
        } catch (err) {
          // this.showError(err.message);
          window.SH_ERROR_NOTIFY(err);
        }
      }
      // console.timeEnd(savePicksLabel);
      return Promise.resolve();
    }, []);
    const onFormSubmit = React.useCallback<React.FormEventHandler>((event) => {
      event.preventDefault();
      console.log(`onFormSubmit:`);
    }, []);
    if (!pickUtils || !pickUtils.bracketPeriodTree) {
      return (
        <GameRoot {...props}>
          <LoadingView />
        </GameRoot>
      );
    }
    console.timeLog(debugLabel, "buildCoordinateMap start");
    const visualOptions = React.useMemo(
      () => ({
        width: 1600,
        height: 0,
        scale: versionOfUi === "html" ? 1500.0 : 100.0,
        isTree: true,
        isStaggered: false,
        matchupHtoWRatio: 0.5,
        gutterToColumnWRatio: 0.03,
      } as IBracketVisualOptions),
      [versionOfUi],
    );
    pickUtils.bracketUtils.buildCoordinateMap(
      pickUtils.bracketPeriodTree, visualOptions,
    );
    console.timeLog(debugLabel, "buildCoordinateMap end");
    if (canUseDom) {
      window.pickUtils = pickUtils;
    }
    console.timeEnd(debugLabel);
    return (
      <form noValidate={true} onSubmit={onFormSubmit}>
          <BracketSkeleton
            onItemClick={onItemClick}
            pickUtils={pickUtils}
            versionOfUi={versionOfUi}
          />
        </form>
    );
  }
  */

  // All these go to PicksInner component
  const { hasFinaledGames, hasStartedEvent, eventsCacheKey } = buildPollingProps(poolData)
  const picks = detailedEntry?.picks || emptyArray
  const ogProps = extractPoolOgProps(poolData)
  const gameInstanceUids = gameInstancesForArea.map((gi) => gi.uid)
  const challengeGI = gameInstancesForArea.find((gi) => gi.poolType === ENUM_CHALLENGE)
  const managerGI = gameInstancesForArea.find((gi) => gi.poolType === ENUM_MANAGER)
  const entriesInSport = allCurrentEntries.filter((entr) => gameInstanceUids.includes(entr.gameInstanceUid))
  const hasChallengeEntry = !!entriesInSport.find((etr) => etr.gameInstanceUid === (challengeGI && challengeGI.uid))
  const onMount =
    location.pathname !== poolRoot
      ? () => history.replace({ ...location, ...{ pathname: poolRoot || "" } })
      : areaHasMultipleEntriesPerUser
      ? () => undefined
      : null
  const hasManagerEntry = !!entriesInSport.find((etr) => etr.gameInstanceUid === (managerGI && managerGI.uid))
  const entryOrBlank = detailedEntry || placeholderEntry
  const needsToMakePicks = detailedEntry?.needsToMakePicks ?? true
  const canUseTeamLogos = !!poolDetail && poolDetail.canUseTeamLogos
  const leaderTotalFantasyPoints = poolDetail ? poolDetail.leaderTotalFantasyPoints : null
  const lastScoredAt = poolDetail && poolDetail.lastScoredAt
  const gameType = (segmentForArea && segmentForArea.gameType) || ""
  const periodPoint = entryOrBlank.periodPoint || placeholderPeriodPoint
  const poolRootPathname = (poolDetail && withoutDomain(poolDetail.url)) || undefined
  const layoutId = getLayoutId(params, ogProps.seasonType)
  const isPoolMember = !!detailedEntry
  const layoutProps = Object.assign({}, defaultProps, {
    layoutId,
    isChallengePool,
    isPoolMember,
    hasManagerRole,
    poolRootPathname,
    pathname,
    hasCbsAccount,
    hasFantasyUser,
    isInComingSoon,
    isCbsAppWebview,
    isIosBrowser,
    isAndroidBrowser,
    productAbbrev: (productSeason && productSeason.productAbbrev) || "",
    deviceType,
  })
  const sideAdProps = BidBarrel.getDomValues({
    adLayoutProps: layoutProps,
    adSlot: "mpu_top",
    requestedPos: "sidebar",
  })
  const gamblingPartnerUpsellPlacementName =
    (gamblingPartnerInfo?.isPromoAvailable &&
      detailedPeriod &&
      gamblingPartnerInfo?.placements
        .map((pl) => pl.name)
        .find((name) => {
          return (
            name.includes(constants.BET_SLIP_PLACEMENT_SUFFIX) &&
            (name.includes(detailedPeriod.segment.season.productAbbrev) || name.includes(detailedPeriod.segment.gameType.toLowerCase()))
          )
        })) ||
    ""
  // NOTE qac: need key to rerender if these items change (mainly for tiebreaker support)
  // we dont want entryId since it will go from "" -> "wefaw123=" when they create for the first time (first pick anon)
  const key = `${isMine}-${poolDetail?.id || null}-${detailedPeriod.id}`
  const entryLoading = isMine ? !!detailedEntry && detailedEntry.id !== entryDetailsVariables.entryId : entryDetailsIsLoadingOrChanging
  const showPicksLoading = detailedPeriodIsLoadingOrChanging || entryLoading
  // console.debug(`key: '${key}'`);
  const entryName = entryOrBlank.name
  const tiebreakerAnswers = entryOrBlank.tiebreakerAnswers
  const poolRank = entryOrBlank.poolRank
  const poolPeriodRank = periodPoint.poolRank
  const poolPeriodFantasyPoints = periodPoint.fantasyPoints
  const ytdFantasyPoints = entryOrBlank.fantasyPoints
  const totalPicksCount = entryOrBlank.totalPicksCount
  const showOnboarding = !entryOrBlank.hidePicksOnboarding
  const hasSeenNativeAppPromo = entryOrBlank.hasSeenNativeAppPromo
  const mutation = upsertEntryMutation
  const needsAuth = !hasCbsAccount
  const needsEntryName = hasAutogeneratedName
  const mode = isManagerModeActive
  const updateMode = toggleManagerMode
  const picksProps: IPicksProps = {
    ...sideAdProps,
    ...mergedPoolSettings,
    ...ogProps,
    key,
    entryPeriodPointsGlanceQuery,
    eventsCacheKey,
    hasFinaledGames,
    hasStartedEvent,
    isCbsAppWebview,
    gamblingPartnerUpsellPlacementName,
    periodId: detailedPeriod.id,
    period: detailedPeriod,
    picks: serializePicks(picks),
    entryId,
    showPicksLoading,
    detailedPeriodIsLoadingOrChanging,
    updateOrder,
    entryName,
    tiebreakerAnswers,
    poolRank,
    poolPeriodRank,
    poolPeriodFantasyPoints,
    ytdFantasyPoints,
    totalPicksCount,
    leaderTotalFantasyPoints,
    showOnboarding,
    hasSeenNativeAppPromo,
    lastScoredAt,
    canUseTeamLogos,
    isMine,
    mutation,
    poolId,
    isChallengePool,
    hasManagerEntry,
    hasChallengeEntry,
    gameInstanceUid,
    openModalKey,
    openModal,
    needsAuth,
    needsEntryName,
    hasManagerRole,
    mode,
    updateMode,
    centralTeams,
    gameType,
    onMount,
    isIosBrowser,
    isAndroidBrowser,
    deviceType,
    location,
    isSingleBracket,
    needsToMakePicks,
    segmentForArea,
    areaHasMultipleEntriesPerUser,
    isInComingSoon,
    canPreviewBracket,
    layoutProps,
  }
  return <Picks {...picksProps} />
}

export default PicksWrapper
