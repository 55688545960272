import { Field } from "formik"
import * as React from "react"
import InformationOutlineSvg from "../../../components/icons/InformationOutline"
import OneMillion from "../../../components/icons/OneMillion"
import TeamJewel from "../../../components/TeamJewel"
import { BoxWhiteInputLabel, FormikBoxWhiteInput } from "../styles/BoxWhiteInput.styles"

import { IPickUtilsEvent } from "../../../../common/common-utils-types.d"
import { emptyArray } from "../../../../common/misc-utils"
import { ButtonBase } from "../../../components/Button"
import CardWrapper from "../../../shared/atoms/Card/CardWrapper"
import NexusSelect from "../../../shared/molecules/Form/Select"
import { toDateTime } from "../../../utils/data-utils"
import { PicksParlayBonusStyled, TiebreakLabelWrap } from "../styles/Picks.styles"

interface IPicksParlayBonus {
  fields?: any[]
  fauxFields?: any[]
  isMinimal: boolean
  canEnter: boolean
  picksCount: number
  totalPicksCount: number
  className?: string
  isParlay: boolean
  eventOfThePeriod: IPickUtilsEvent | null
  tiebreakerQuestionTitle?: string | null
  values?: any
  disabled?: boolean
  onLastTiebreakerInfoClick?: (event: Event) => void
  onEditTiebreakerClick?: () => void
  canUseTeamLogos?: boolean
  isIosWebview: boolean
  titleOfChallenge?: string
}

const PicksParlayBonus = (props: IPicksParlayBonus) => {
  const {
    canEnter,
    disabled,
    isMinimal,
    fields,
    fauxFields,
    values,
    picksCount,
    tiebreakerQuestionTitle,
    isIosWebview,
    totalPicksCount,
    className,
    isParlay,
    eventOfThePeriod,
    onLastTiebreakerInfoClick,
    onEditTiebreakerClick,
    canUseTeamLogos,
    titleOfChallenge,
    ...rest
  } = props
  const usesCustomTiebreaker = isParlay
  // console.log('PicksParlayBonus')
  // console.dir(props)
  // console.dir(fields)
  // console.dir(fauxFields)
  // console.dir(values)
  // const canEnter = !false;
  // const isMinimal = false;
  // const fields = props.fields || [] as any;
  // const subText = !canEnter ? `Pick every game to reveal the questions.` : `Submit your answers for a chance to win.`;
  const isCTA = !isMinimal
  const hasAllPicks = picksCount === totalPicksCount
  const dt = eventOfThePeriod && toDateTime(eventOfThePeriod.startsAt) // 1578243600000
  const classNameFull = `is-enabled--${canEnter} is-minimal--${isMinimal} is-custom--${isParlay} ${className || ""} is-disabled--${!!disabled}`
  const _titleOfChallenge =
    titleOfChallenge ||
    tiebreakerQuestionTitle ||
    (eventOfThePeriod?.weekNumber && `Week ${eventOfThePeriod.weekNumber}`) ||
    eventOfThePeriod?.homeTeam?.sportType ||
    "The"
  const _fields = fauxFields || fields || emptyArray
  // const uniqueId = `bonus-${isCTA}-${(fields || emptyArray).length}`;
  return (
    <PicksParlayBonusStyled as={CardWrapper} className={classNameFull} {...rest}>
      {(usesCustomTiebreaker && isCTA && (
        <div className="header row">
          <OneMillion className="img" disabled={!canEnter} />
          <div className="flex1 flxcol">
            <div className="row jstAround algCenter">
              <p className="title flex1 bold f14">{_titleOfChallenge} Challenge</p>
              <div className="gray sub txtCtr">
                {(canEnter && <div className="bold">Entry Submitted</div>) ||
                  (hasAllPicks && <div className="bold">Pending Challenge Questions</div>) ||
                  `${picksCount} of ${totalPicksCount}`}
              </div>
            </div>
          </div>
        </div>
      )) || (
        <div className="fields">
          <div className="fields-container">
            {(!eventOfThePeriod && (
              <div className="row header jstCenter">
                <div className="flex1 bold sub row algCenter">{tiebreakerQuestionTitle || "Tiebreaker"}</div>
                {(dt && (
                  <span className="gray sub txtCtr">
                    {(dt && dt.toFormat(`EEE, L/dd`)) || "Gametime"}
                    <br />
                    {(dt && dt.toFormat(`h:mma`)) || "TBD"}
                  </span>
                )) ||
                  null}
              </div>
            )) ||
              null}
            {(eventOfThePeriod && eventOfThePeriod.awayTeam && eventOfThePeriod.homeTeam && (
              <div className="row header jstCenter">
                <div className="flex1 row header algCenter">
                  <TeamJewel team={eventOfThePeriod.awayTeam} />
                  <span>&nbsp;@&nbsp;</span>
                  <TeamJewel team={eventOfThePeriod.homeTeam} />
                </div>
                {(dt && <span className="gray sub algCenter row">{dt.toFormat(`EEE, L/dd @ h:mma ZZZZ`)}</span>) || null}
              </div>
            )) ||
              null}
            <InputEl
              isIosWebview={isIosWebview}
              values={values}
              isFaux={!!fauxFields && !!fauxFields.length}
              fields={_fields}
              disabled={disabled}
              onLastTiebreakerInfoClick={onLastTiebreakerInfoClick}
            />
          </div>
          {onEditTiebreakerClick && (
            <div className="dt-handheld--true row header jstCenter">
              <ButtonBase type="button" onClick={onEditTiebreakerClick}>
                Edit Tiebreaker
              </ButtonBase>
            </div>
          )}
        </div>
      )}
    </PicksParlayBonusStyled>
  )
}

const ee = {}
const ff = { placeholder: "Choose a Team" }

const TiebreakLabel = ({ label, title }: { label: string; title: string }) => {
  return (
    <TiebreakLabelWrap>
      <span className="tb-title">{title}</span>
      <span className="tb-label">{label}</span>
    </TiebreakLabelWrap>
  )
}

function InputEl(props) {
  const { isFaux, fields, disabled, values, onLastTiebreakerInfoClick } = props
  if (!fields) {
    return null
  }
  const lastFieldI = (onLastTiebreakerInfoClick && fields.length - 1) || -1
  const showTitle = fields?.length > 1
  return (
    <>
      {fields.map(({ id, label, input }, idx) => {
        const { options, ...restInput } = input
        const name = `tiebreakerAnswers[${id}]`
        const InputComponent = options ? NexusSelect : FormikBoxWhiteInput
        const value = (values && values.tiebreakerAnswers[id]) || ""
        const others = options ? ff : ee
        return (
          <BoxWhiteInputLabel
            className={`field has-value--${!!value}`}
            isRow={true}
            key={id}
            label={
              lastFieldI === idx ? (
                <>
                  {label}
                  <button type="button" onClick={onLastTiebreakerInfoClick} className="last-answer-help">
                    <InformationOutlineSvg />
                  </button>
                </>
              ) : showTitle ? (
                <TiebreakLabel title={`Tiebreaker ${idx + 1}`} label={label} />
              ) : (
                label
              )
            }
            disabled={!isFaux && disabled}
            field={
              isFaux ? (
                <InputComponent
                  name={name}
                  value={value}
                  options={options}
                  submitCount={0}
                  field={{ name, value, disabled }}
                  {...others}
                  {...restInput}
                  readOnly={true}
                  disabled={true}
                />
              ) : (
                <Field
                  name={name}
                  tabIndex={idx + 1}
                  component={InputComponent}
                  disabled={disabled}
                  options={options}
                  defaultValue={value}
                  {...others}
                  {...restInput}
                />
              )
            }
          />
        )
      })}
    </>
  )
}

export default PicksParlayBonus
