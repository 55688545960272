import React from "react"
import styled from "styled-components/macro"
import CarrotSvg from "./icons/Carrot"
import Link, { IProps } from "./Link"
// import {pxToRem} from './style-utils'

const Styled = styled(Link)`
  display: flex;
  flex-flow: row nowrap;
  & .carrot {
    transition: all 0.2s ease-out;
    min-width: 1em;
    height: 1em;
    top: 0;
    right: 0;
    flex: 0 1 0%;
    align-self: center;
    margin: 0.75em;
  }
  @media (hover: hover) {
    &:hover:not(:active) {
      & .carrot {
        transform: translateX(0.6em);
      }
    }
  }
  &:active {
    & .carrot {
      transform: translateX(0.4em);
    }
  }
`

export class A extends React.PureComponent<IProps> {
  public render() {
    const { children, ...linkProps } = this.props
    return (
      <Styled {...linkProps}>
        {children}
        <CarrotSvg className="carrot" />
      </Styled>
    )
  }
}

export default A
