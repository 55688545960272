import React from "react"
import { toRank } from "../../../../common/misc-utils"
import EntryBarPeriodDetail from "./EntryBarPeroidDetail"

interface IEntryBarPeriodDetails {
  fantasyPoints: number
  isLocked: boolean
  maxPicksCount: number
  picksCount: number
  poolRank: number
}

const EntryBarPeriodDetails = (props: IEntryBarPeriodDetails) => {
  const { isLocked, fantasyPoints, maxPicksCount, picksCount, poolRank } = props

  let picksOrRankTitle = "Picks"
  let picksOrRankText = `${picksCount}/${maxPicksCount}`

  // Once we are locked, we want to show rank instead of picks
  if (isLocked) {
    picksOrRankTitle = "Rank"
    picksOrRankText = toRank(poolRank) || "-"
  }

  return (
    <>
      {isLocked ? <EntryBarPeriodDetail title={"Points"} text={`${fantasyPoints}`} /> : null}
      <EntryBarPeriodDetail title={picksOrRankTitle} text={picksOrRankText} />
    </>
  )
}

export default EntryBarPeriodDetails
