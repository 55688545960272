import React, { FC } from "react"
import { Document, Page, Image, View, StyleSheet, Text } from "@react-pdf/renderer"
import Banner from "./Banner"
import Bracket from "./Bracket"
import { canUseDom } from "../../../../utils/misc-utils"
import {
  IHavePickUtils,
  Sponsor,
  Variant,
  CBS_SPORTS_LOGO_URL,
  FF_LOGO_URL,
  FF_WOMENS_LOGO_URL,
  SPONSOR_LOGOS,
  DEFAULT_FONT_FAMILY,
  WOMENS_BRACKET_GAMES_LOGO,
  MENS_BRACKET_GAMES_LOGO,
} from "./PrintableTypes"

const styles = StyleSheet.create({
  page: {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    fontFamily: DEFAULT_FONT_FAMILY,
    position: "relative",
  },
  cbsSportsLogo: {
    position: "absolute",
    width: 138,
    height: 16,
    top: 565,
    left: 327,
  },
  cbsLogo: { position: "absolute", width: 41, height: 12, top: 437, left: 375 },
  sponsorBox: {
    position: "absolute",
    top: 126,
    left: 335,
    width: 130,
    height: 41,
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
  },
  divider: {
    width: 1,
    height: 40,
    backgroundColor: "#DAE0E5",
  },
  bracketGamesLogo: {
    width: "auto",
    height: 40,
    marginRight: 14,
  },
  sponsortLogo: {
    width: "auto",
    height: 40,
    marginLeft: 14,
  },
  ffWomensLogo: {
    position: "absolute",
    width: 80,
    height: 80,
    top: 210,
    left: 356,
  },
  ffLogo: {
    position: "absolute",
    width: 67,
    height: 67,
    top: 212,
    left: 363,
  },
  nationalChampionship: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontWeight: 700,
    fontSize: 10,
    textAlign: "center",
    width: 65,
    height: 30,
    position: "absolute",
    top: 297,
    left: 363,
  },
  siteDate: {
    position: "absolute",
    height: 24,
    width: 80,
    left: 360,
    top: 401,
    textAlign: "center",
  },
  site: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontWeight: 700,
    fontSize: 10,
  },
  date: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontWeight: 500,
    fontSize: 8,
    textAlign: "center",
  },
  entryNameBox: {
    position: "absolute",
    height: 16,
    width: 132,
    left: 330,
    top: 184,
  },
  entryName: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontWeight: 700,
    fontSize: 12,
    textAlign: "center",
  },
})

export interface IPrintableBracketProps extends IHavePickUtils {
  title?: string
  sponsor?: Sponsor
  variant?: Variant
}

const PrintableBracket: FC<IPrintableBracketProps> = ({ title, pickUtils, sponsor = "NISSAN", variant = "REGULAR" }) => {
  if (!canUseDom) {
    return null
  }

  const championshipLocation = variant === "WOMENS" ? "Cleveland, OH" : "Phoenix, AZ"
  const championshipDate = variant === "WOMENS" ? "April 7" : "April 8"
  const logoUrl = variant === "WOMENS" ? FF_WOMENS_LOGO_URL : FF_LOGO_URL

  return (
    <Document title={title}>
      <Page orientation="landscape" size="LETTER" style={styles.page} wrap={false}>
        <Banner variant={sponsor} />
        <Bracket pickUtils={pickUtils} variant={variant} />
        <View style={styles.sponsorBox}>
          <Image src={variant === "WOMENS" ? WOMENS_BRACKET_GAMES_LOGO : MENS_BRACKET_GAMES_LOGO} style={styles.bracketGamesLogo} />
          <View style={styles.divider}></View>
          <Image src={SPONSOR_LOGOS[sponsor]} style={styles.sponsortLogo} />
        </View>
        <View style={styles.entryNameBox}>
          <Text style={styles.entryName}>{title}</Text>
        </View>
        <Image src={logoUrl} style={variant === "WOMENS" ? styles.ffWomensLogo : styles.ffLogo} />
        <Text style={styles.nationalChampionship}>National Championship</Text>
        <View style={styles.siteDate}>
          <Text style={styles.site}>{championshipLocation}</Text>
          <Text style={styles.date}>{championshipDate}</Text>
        </View>
        <Image src={CBS_SPORTS_LOGO_URL} style={styles.cbsSportsLogo} />
      </Page>
    </Document>
  )
}

export default PrintableBracket
