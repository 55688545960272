import { useQuery } from "@apollo/client"
import React from "react"
import { FantasyArticlesQuery, FantasyArticlesQueryVariables } from "../../../../__generated__/FantasyArticlesQuery"
import { GameSportTypeEnumType } from "../../../../__generated__/globalTypes"
import { buildTtag } from "../../../../common/game-text"
import { emptyArray } from "../../../../common/misc-utils"
import A from "../../../components/A"
import LoadingView from "../../../components/LoadingView"
import { FANTASY_ARTICLES_QUERY } from "../../queries"
import { CardCallout } from "../styles/Picks.styles"
import { getNewsSearchSlugFromSportType } from "../../../utils/data-utils"

interface IFantasyArticlesSidebarComponent {
  deviceType: string
  sportType: GameSportTypeEnumType
  season: any
  sportTitle: string
  gameInstanceUid: string
}

const FantasyArticlesSidebar: React.FC<IFantasyArticlesSidebarComponent> = (props) => {
  const { deviceType, sportType, season, sportTitle, gameInstanceUid } = props
  const skip = deviceType !== "desktop"
  // NOTE LL: NCAAB March Madness searchSlug = brackets-picks-advice. Sport Type might not be enough, might need to get more granular
  const searchSlug = getNewsSearchSlugFromSportType(sportType)
  const variables = { sportType, searchSlug }

  const fantasyArticlesQuery = useQuery<FantasyArticlesQuery, FantasyArticlesQueryVariables>(FANTASY_ARTICLES_QUERY, {
    skip,
    variables,
  })
  const hasData = !!fantasyArticlesQuery.data
  const children = !hasData ? <LoadingView /> : undefined
  const ttag = buildTtag(season.productAbbrev, season.year, "FF", `${gameInstanceUid}-picksmodule`)
  const items =
    fantasyArticlesQuery.data?.fantasyArticles?.map((article) => {
      return {
        dt: <A to={`${article.url}?ttag=${ttag}`}>{article.promoTitle || article.title}</A>,
      }
    }) || emptyArray
  if (items.length < 6 && !!hasData) {
    return null
  }
  return <CardCallout header={`${sportTitle} Picks Advice`} items={items} children={children} />
}

export default FantasyArticlesSidebar
