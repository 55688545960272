import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Button, { StyledButton } from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import bracketTheme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import { MenuBase } from "@cbs-sports/sports-shared-client/build/cjs/components/Menu"
import Popover, { ANCHORS } from "@cbs-sports/sports-shared-client/build/cjs/components/Popover"
import { picksAutoFillCallout } from "../../../../../../src/common/cookies"
import CookieUtils from "../../../../../../src/client/utils/cookie-utils"
import Analytics from "../../../../utils/analytics"

const Container = styled.div`
  padding: 0.5rem 0.75rem 0.25rem 0.75rem;

  & .new-tag {
    color: ${bracketTheme.colors.overLight.blue2};
    font-weight: ${bracketTheme.fonts.weights.bold};
    text-transform: uppercase;
    margin-right: 0.25rem;
  }
  & .title {
    font-weight: ${bracketTheme.fonts.weights.bold};
  }
  & .body {
    font-size: 0.75rem;
    font-weight: ${bracketTheme.fonts.weights.semireg};
    color: ${bracketTheme.colors.overLight.white50};
    margin: 0.25rem 0;
  }

  & > .cta-wrapper {
    width: 100%;
    display: flex;
    margin-top: 0.5rem;
    & > :first-child {
      margin-right: 0.75rem;
    }
  }

  & > .variant--participant {
    justify-content: end;
  }
`
const defaultPaperProps = {
  style: {
    paddingTop: "0.25rem",
    paddingBottom: "0.5rem",
    minWidth: "10rem",
  },
}

const Content = ({ onClose, handleExploreMore }) => {
  return (
    <MenuBase>
      <Container>
        <div>
          <span className="new-tag" data-cy="auto-fill_hdr">
            AUTO-FILL
          </span>
          <span className="title" data-cy="auto-fill_title">
            Your Bracket!
          </span>
        </div>
        <div className="body" data-cy="auto_fill_txt">
          Save time by using one of our experts or quick pick options. You can still make changes!
        </div>
        <div className={`cta-wrapper variant--media`}>
          <>
            <Button variant="secondary" onClick={onClose} data-cy="close_btn">
              Close
            </Button>
            <StyledButton onClick={() => handleExploreMore()} className="variant--primary cta" data-cy="view_options_btn">
              View Options
            </StyledButton>
          </>
        </div>
      </Container>
    </MenuBase>
  )
}

const AutofillCallOut = ({ target, onMenuClick, poolId }) => {
  const date = new Date()
  const sixMonth = new Date(date.setMonth(date.getMonth() + 6))
  const calloutCookie = CookieUtils.getCookie(picksAutoFillCallout)

  const [show, setShow] = useState(false)

  useEffect(() => {
    if (!calloutCookie) {
      // no cookie, create one
      const calloutIds = poolId ? [poolId] : []
      CookieUtils.setCookie(picksAutoFillCallout, JSON.stringify(calloutIds), sixMonth)
      setShow(true)
    } else {
      const calloutIds = JSON.parse(calloutCookie)
      if (!calloutIds.includes(poolId)) {
        // not found add to list and show PopOver
        calloutIds.push(poolId)
        CookieUtils.setCookie(picksAutoFillCallout, JSON.stringify(calloutIds), sixMonth)
        setShow(true)
      }
    }
  }, [])
  const handleClose = () => {
    setShow(false)
  }
  const handleExploreMore = () => {
    Analytics.trackAction("bracket autofill", "autofill options modal", "view options")
    handleClose()
    onMenuClick("autofill")
  }

  return (
    <Popover
      anchor={ANCHORS.BOTTOM_CENTER}
      bodyProps={defaultPaperProps}
      showAnchor={true}
      active={show}
      target={target.current}
      origin={"right"}
      content={<Content onClose={handleClose} handleExploreMore={handleExploreMore} />}
      padded={true}
      onClose={handleClose}
      onBackgroundClick={handleClose}
      variant="menu"
      closeOnScroll={false}
    />
  )
}
export default AutofillCallOut
