import React from "react"
import { ButtonSmallBase } from "../../../components/Button"
import BracketGamesSvg from "../../../components/icons/BracketGames"
import Link from "../../../components/Link"
import { BcgPromoSection, BcgSubtext, BcgTitle, Row } from "../styles/BcgPromo.styles"

const BcgPromo = React.memo(() => (
  <BcgPromoSection>
    <BracketGamesSvg />
    <BcgTitle>The Madness is coming!</BcgTitle>
    <BcgSubtext>Get in before they announce the field of 68</BcgSubtext>
    <Row>
      <ButtonSmallBase as={Link} to={`/college-basketball/ncaa-tournament/bracket/create-pool`}>
        Create Pool
      </ButtonSmallBase>
      <ButtonSmallBase as={Link} to={`/college-basketball/ncaa-tournament/bracket`}>
        Play for Prizes
      </ButtonSmallBase>
    </Row>
  </BcgPromoSection>
))
export default BcgPromo
