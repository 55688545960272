import { Field } from "formik"
import * as React from "react"
import OneMillion from "../../../components/icons/OneMillion"
import TeamJewel from "../../../components/TeamJewel"
import { BoxWhiteInputLabel, FormikBoxWhiteInput } from "../styles/BoxWhiteInput.styles"

import { IPickUtilsEvent } from "../../../../common/common-utils-types.d"
import { emptyArray } from "../../../../common/misc-utils"
import { ButtonBase } from "../../../components/Button"
import CardWrapper from "../../../shared/atoms/Card/CardWrapper"
import NexusSelect from "../../../shared/molecules/Form/Select"
import { toDateTime } from "../../../utils/data-utils"
import { PicksParlayBonusStyled, TiebreakLabelWrap } from "../styles/Picks.styles"

interface IPicksParlayBonusMultipleGames {
  fields?: any[]
  fauxFields?: any[]
  isMinimal: boolean
  canEnter: boolean
  picksCount: number
  totalPicksCount: number
  className?: string
  isParlay: boolean
  eventOfThePeriod: IPickUtilsEvent
  secondEventOfThePeriod: IPickUtilsEvent
  tiebreakerQuestionTitle?: string | null
  values?: any
  firstTiebreakerDisabled?: boolean
  secondTiebreakerDisabled?: boolean
  onLastTiebreakerInfoClick?: (event: Event) => void
  onEditTiebreakerClick?: () => void
  canUseTeamLogos?: boolean
  isIosWebview: boolean
  titleOfChallenge?: string
}

const PicksParlayBonusMultipleGames = (props: IPicksParlayBonusMultipleGames) => {
  const {
    canEnter,
    firstTiebreakerDisabled,
    secondTiebreakerDisabled,
    isMinimal,
    fields,
    fauxFields,
    values,
    picksCount,
    tiebreakerQuestionTitle,
    isIosWebview,
    totalPicksCount,
    className,
    isParlay,
    eventOfThePeriod,
    secondEventOfThePeriod,
    onLastTiebreakerInfoClick,
    onEditTiebreakerClick,
    canUseTeamLogos,
    titleOfChallenge,
    ...rest
  } = props
  const usesCustomTiebreaker = isParlay
  const isCTA = !isMinimal
  const hasAllPicks = picksCount === totalPicksCount
  const dt = eventOfThePeriod && toDateTime(eventOfThePeriod.startsAt) // 1578243600000
  const secondDt = secondEventOfThePeriod && toDateTime(secondEventOfThePeriod.startsAt) // 1578243600000
  const classNameFull = `is-enabled--${canEnter} is-minimal--${isMinimal} is-custom--${isParlay} ${className || ""} is-disabled--${
    !!firstTiebreakerDisabled && !!secondTiebreakerDisabled
  }`
  const _titleOfChallenge =
    titleOfChallenge ||
    tiebreakerQuestionTitle ||
    (eventOfThePeriod?.weekNumber && `Week ${eventOfThePeriod.weekNumber}`) ||
    eventOfThePeriod?.homeTeam?.sportType ||
    "The"
  const _fields = fauxFields || fields || emptyArray
  const tiebreakerValues = _fields.map((field) => values?.tiebreakerAnswers[field.id] || null)

  return (
    <PicksParlayBonusStyled as={CardWrapper} className={classNameFull} {...rest}>
      {(usesCustomTiebreaker && isCTA && (
        <div className="header row">
          <OneMillion className="img" disabled={!canEnter} />
          <div className="flex1 flxcol">
            <div className="row jstAround algCenter">
              <p className="title flex1 bold f14">{_titleOfChallenge} Challenge</p>
              <div className="gray sub txtCtr">
                {(canEnter && <div className="bold">Entry Submitted</div>) ||
                  (hasAllPicks && <div className="bold">Pending Challenge Questions</div>) ||
                  `${picksCount} of ${totalPicksCount}`}
              </div>
            </div>
          </div>
        </div>
      )) || (
        <div className="fields">
          <div className="fields-container">
            {eventOfThePeriod.awayTeam && eventOfThePeriod.homeTeam && (
              <div className="row header jstCenter">
                <div className="flex1 row header algCenter">
                  <TeamJewel team={eventOfThePeriod.awayTeam} />
                  <span>&nbsp;@&nbsp;</span>
                  <TeamJewel team={eventOfThePeriod.homeTeam} />
                </div>
                {(dt && <span className="gray sub algCenter row">{dt.toFormat(`EEE, L/dd @ h:mma ZZZZ`)}</span>) || null}
              </div>
            )}
            <InputEl
              isIosWebview={isIosWebview}
              value={tiebreakerValues?.[0]}
              isFaux={!!fauxFields && !!fauxFields.length}
              field={_fields && _fields[0]}
              disabled={firstTiebreakerDisabled}
              idx={0}
            />
            <InputEl
              isIosWebview={isIosWebview}
              value={tiebreakerValues?.[1]}
              isFaux={!!fauxFields && !!fauxFields.length}
              field={_fields && _fields[1]}
              disabled={firstTiebreakerDisabled}
              idx={1}
            />
            {secondEventOfThePeriod.awayTeam && secondEventOfThePeriod.homeTeam && (
              <div className="row header jstCenter">
                <div className="flex1 row header algCenter">
                  <TeamJewel team={secondEventOfThePeriod.awayTeam} />
                  <span>&nbsp;@&nbsp;</span>
                  <TeamJewel team={secondEventOfThePeriod.homeTeam} />
                </div>
                {(secondDt && <span className="gray sub algCenter row">{secondDt.toFormat(`EEE, L/dd @ h:mma ZZZZ`)}</span>) || null}
              </div>
            )}
            <InputEl
              isIosWebview={isIosWebview}
              value={tiebreakerValues?.[2]}
              isFaux={!!fauxFields && !!fauxFields.length}
              field={_fields && _fields[2]}
              disabled={secondTiebreakerDisabled}
              idx={2}
            />
            <InputEl
              isIosWebview={isIosWebview}
              value={tiebreakerValues?.[3]}
              isFaux={!!fauxFields && !!fauxFields.length}
              field={_fields && _fields[3]}
              disabled={secondTiebreakerDisabled}
              idx={3}
            />
          </div>
          {onEditTiebreakerClick && (
            <div className="dt-handheld--true row header jstCenter">
              <ButtonBase type="button" onClick={onEditTiebreakerClick}>
                Edit Tiebreaker
              </ButtonBase>
            </div>
          )}
        </div>
      )}
    </PicksParlayBonusStyled>
  )
}

const ee = {}
const ff = { placeholder: "Choose a Team" }

const TiebreakLabel = ({ label, title }: { label: string; title: string }) => {
  return (
    <TiebreakLabelWrap>
      <span className="tb-label">{label}</span>
      <span className="tb-title">{title}</span>
    </TiebreakLabelWrap>
  )
}

function InputEl(props) {
  const { isFaux, field, disabled, value, idx } = props
  if (!field) {
    return null
  }
  const { id, label, input } = field
  const { options, ...restInput } = input
  const name = `tiebreakerAnswers[${id}]`
  const InputComponent = options ? NexusSelect : FormikBoxWhiteInput
  const others = options ? ff : ee
  return (
    <BoxWhiteInputLabel
      className={`field`}
      isRow={true}
      key={id}
      label={<TiebreakLabel title={`Tiebreaker ${idx + 1}`} label={label} />}
      disabled={!isFaux && disabled}
      field={
        isFaux ? (
          <InputComponent
            name={name}
            value={value}
            options={options}
            submitCount={0}
            field={{ name, value, disabled }}
            {...others}
            {...restInput}
            readOnly={true}
            disabled={true}
          />
        ) : (
          <Field
            name={name}
            tabIndex={idx + 1}
            component={InputComponent}
            disabled={disabled}
            options={options}
            defaultValue={value}
            {...others}
            {...restInput}
          />
        )
      }
    />
  )
}

export default PicksParlayBonusMultipleGames
