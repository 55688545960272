import { DateTime } from "luxon"
import * as React from "react"
import { IPickUtilsEvent, IPickUtilsEventSpreadChange } from "../../../../common/common-utils-types"
import { emptyObject, recordFor } from "../../../../common/misc-utils"
import { EventSides, ReversedEventSides } from "../../../../common/pool-settings"
import { ButtonWithAfterContentInteraction, IconButton, LinkButton } from "../../../components/Button"
import AlignTopIcon from "../../../components/icons/AlignTopIcon"
import CheckmarkSvg from "../../../components/icons/Checkmark"
import InformationOutlineSvg from "../../../components/icons/InformationOutline"
// import LockSvg from "../../../components/icons/Lock";
import MoveIcon from "../../../components/icons/MoveIcon"
import WarningSvg from "../../../components/icons/Warning"
import TeamJewel from "../../../components/TeamJewel"
import NexusSelect from "../../../shared/molecules/Form/Select"
import { teamName } from "../../../utils/data-utils"
import { formatGamePeriod, isEventInHalftime } from "../../../utils/misc-utils"
import { AnimatedSaveStyles, EventRowStyled, PickemEventMatchStyled, PickemEventSpreadChangeStyled } from "../styles/Picks.styles"
import EventTeam from "./EventTeam"
import { spreadInfoKey } from "../containers/ScoringBreakdownModal"
import { IPickemEventProps, IPickemEventState, IPickemEventTeamProps } from "./picks-types"
import { Nbsp } from "./PoolPage"
import palette from "../../../../common/palette"
import { invalidFinalGameStatuses } from "../../../../common/common-utils-helpers"
import { ENUM_INPROGRESS, ENUM_SCHEDULED } from "../../../../common/enums"
import Analytics from "../../../utils/analytics"

const homeSide = "home"

const sides = EventSides
const reversedSides = ReversedEventSides

const hideMA = false // !constants.IS_PRODISH;
const versions = ["team", "btn"] as Array<IPickemEventTeamProps["version"]>
function mapChoiceToOption(i: number) {
  const value = i + 1
  return {
    label: `+${value} pt${value === 1 ? "" : "s"}`,
    value,
  }
}

function AnimatedSave({ className, isSaving, ...rest }) {
  const fullClassName = `is-saving--${!!isSaving} ${className || ""}`
  return (
    <AnimatedSaveStyles className={fullClassName} {...rest}>
      <CheckmarkSvg bgColor="currentColor" color="#fff" />
      <div>Saved!</div>
    </AnimatedSaveStyles>
  )
}

interface IPickemEventMatchProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  event: IPickUtilsEvent
}
const PickemEventMatch = ({ event, ref, ...rest }: IPickemEventMatchProps) => {
  const hasScores = event.gameStatusDesc !== "SCHEDULED" || undefined
  return (
    <PickemEventMatchStyled {...rest}>
      {sides.map((side, i) => {
        const team = (side === "home" && event.homeTeam) || event.awayTeam
        if (!team) {
          return null
        }
        return (
          <React.Fragment key={side}>
            <TeamJewel team={team} score={hasScores && event[`${side}TeamScore`]} />
            <small className="pickem-event__record">({recordFor(team)})</small>
            {(i === 0 && <div className="pickem-event__separator">@</div>) || null}
          </React.Fragment>
        )
      })}
    </PickemEventMatchStyled>
  )
}

interface IPickemEventTimeOrScoreProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  event: IPickUtilsEvent
  hasScoringBreakdown?: boolean
  lastScoredAt?: number | null
}

const PickemEventTimeOrScore = ({ event, hasScoringBreakdown, lastScoredAt, ...rest }: IPickemEventTimeOrScoreProps) => {
  const { startsAt, gameStatusDesc, timeRemaining, gamePeriod, markedFinalAt, tvInfoName } = event
  const isHalftime = gamePeriod ? isEventInHalftime(gamePeriod, event.homeTeam?.sportType, timeRemaining) : false
  if (gameStatusDesc === ENUM_INPROGRESS) {
    return (
      <div {...rest}>
        {gamePeriod && `${formatGamePeriod(gamePeriod, event.homeTeam?.sportType, timeRemaining) || "-"} ${isHalftime ? "" : timeRemaining}`}
      </div>
    )
  }
  if (gameStatusDesc === ENUM_SCHEDULED) {
    const startsAtDT = DateTime.fromMillis(startsAt)
    return (
      <div {...rest}>
        {`${startsAtDT.toFormat("EEE L/dd h:mma ZZZZ")}`} - {tvInfoName}
        {event?.extra?.gameTitle && hasScoringBreakdown ? <span>&nbsp;{event?.extra.gameTitle}</span> : null}
      </div>
    )
  }
  if (markedFinalAt && (!lastScoredAt || lastScoredAt < markedFinalAt)) {
    return <div {...rest}>Scoring...</div>
  }
  const isWarningStatus = invalidFinalGameStatuses.includes(gameStatusDesc || "")
  const warningText = (isWarningStatus && `Any pick made will not be awarded any points for any player`) || undefined
  return (
    <div {...rest} style={isWarningStatus ? { alignItems: "center" } : undefined} title={warningText}>
      {(isWarningStatus && <WarningSvg color={palette.orange} style={{ marginRight: "0.5em" }} icon={true} />) || null}
      {gameStatusDesc}
    </div>
  )
}

interface IPickemEventSpreadChangeProps
  extends IPickUtilsEventSpreadChange,
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  onClick?: () => void
}

export const PickemEventSpreadChange = ({
  favoredTeam,
  openingOrPickFavoredTeamSpread,
  latestFavoredTeamSpread,
  directionSpreadMove,
  openingOrPickItemIsFavoredTeam,
  spreadNotAvailable,
  onClick,
  hasAPick,
  ref,
  ...rest
}: IPickemEventSpreadChangeProps) => {
  const as = onClick ? ButtonWithAfterContentInteraction : undefined
  const otherProps = onClick ? { type: "button" } : emptyObject
  return (
    <PickemEventSpreadChangeStyled onClick={onClick} as={as} {...rest} {...otherProps}>
      {(spreadNotAvailable && (
        <b>
          <WarningSvg /> Spread
        </b>
      )) || (
        <>
          <div>{favoredTeam.abbrev} Spread:</div>
          <div>
            {`${hasAPick ? "Picked" : "Open"}`}
            <span className="cardactions__prop-line-value">{openingOrPickFavoredTeamSpread}</span>
          </div>
          <div>&#183;</div>
          <div>
            {`Latest`}
            <span className={`cardactions__prop-line-value cardactions__prop-line-value--${directionSpreadMove}`}>{latestFavoredTeamSpread}</span>
          </div>
        </>
      )}
      {(onClick && !spreadNotAvailable && <InformationOutlineSvg />) || <span />}
    </PickemEventSpreadChangeStyled>
  )
}

class PickemEvent extends React.PureComponent<IPickemEventProps, IPickemEventState> {
  public state = {
    isSaving: false,
  }

  public clearSaving = () => this.setState({ isSaving: false })
  public startSaving = () => this.setState({ isSaving: true })

  public toggleMA = (event) => {
    event.preventDefault()
    Analytics.trackAction("matchup analysis", "make_picks", "matchup_analysis")
    if (this.props.showMatchupAnalysis) {
      this.props.showMatchupAnalysis(this.getEventId())
    }
  }

  public showScoringBreakdown = () => {
    const eventId = this.getEventId()
    const { pickUtils } = this.props
    if (this.props.showScoringBreakdown) {
      if (pickUtils.isParlay()) {
        this.props.showScoringBreakdown(eventId, undefined, spreadInfoKey)
      } else {
        const event = this.getEvent()
        this.props.showScoringBreakdown(eventId, event.homeTeam.id)
      }
    }
  }

  // public shouldComponentUpdate(nextProps) {
  //   return this.props.cacheBuster !== nextProps.cacheBuster
  // }

  public getEvent() {
    return this.props.pickUtils.getEventById(this.props.eventId)
  }

  public getEventId() {
    return this.props.eventId || this.getEvent()?.id
  }

  public setFieldValue = (_fieldName: string, value: number) => {
    // const eventId = this.getEventId();
    // console.log(`setFieldValue for ${eventId}: ${value}`);
    // console.dir(this.props.event);
    // NOTE qac: these are always +1 since we need to display the weight, not the actual additionalPoints
    const additionalPoints = value - 1
    const newIndex = this.props.pickUtils.getPositionForAdditionalPoints(additionalPoints)
    // this.props.setWeightFor(eventId, additionalPoints);
    // console.log(`going from ${this.props.eventIndex} -> ${newIndex} (${additionalPoints})`)
    if (this.props.moveItem) {
      this.props.moveItem(this.props.eventIndex || 0, newIndex)
    } else if (this.props.setWeightFor) {
      this.props.setWeightFor(this.getEventId(), additionalPoints)
    }
  }

  public onSelectChange = (event) => {
    this.setFieldValue(event.target.name, event.target.value)
  }

  public setMaxWeight = (event) => {
    event.preventDefault()
    // return this.props.moveItem(this.props.eventIndex, 0).then(() => {
    // console.log('nice!')
    const pickUtils = this.props.pickUtils
    const max = Math.max(...pickUtils.getGameWeightChoices())
    // console.log(`max: ${max}`)
    this.setFieldValue("", max + 1)
    // })
  }

  public render() {
    // console.log(`Event`)
    // console.dir(this.props)
    const {
      pickTeam,
      isMine = true,
      eventId,
      pickUtils,
      showSaveAnimation,
      delaySaveCommit,
      lastScoredAt,
      log,
      canUseTeamLogos,
      isDragged,
      isSelected,
      isOutOfBounds,
      mode: propMode,
      className: origClassname,
      useOutsideSaveAnimation,
      cacheBuster,
      showMatchupAnalysis,
      forwardedRef,
      needsDndMode,
      matchupId,
      ...rest
    } = this.props

    const event = pickUtils.getEventById(eventId)
    // const event = pickUtils.events.find((evt) => evt.id === eventId);
    if (!event) {
      throw new Error(`missing event for id: ${eventId}`)
    }

    const isDisabled = pickUtils.isPickingDisabledFor(event)
    if (isDisabled) {
      return null
    }
    const isLocked = pickUtils.isEventLocked(eventId)
    const pick = pickUtils.getPick(eventId)
    const additionalPoints = pick?.additionalPoints
    const itemId = pick?.itemId || null
    const mode = propMode || "regular"
    const isParlay = pickUtils.isParlay()
    const usesWeights = pickUtils.usesWeights() && pickUtils.events.length >= 1
    const hasActionBtns = !hideMA || usesWeights || isParlay
    const hasScoringBreakdown = !isParlay && pickUtils.hasModifiers()

    const classNames = [
      `pickem-event--${mode}`,
      `is-prop--${isParlay}`,
      `uses-weights--${usesWeights}`,
      `is-dragging--${!!isDragged}`,
      `is-selected--${!!isSelected}`,
      `is-picked--${!!itemId}`,
      `needs-dnd-mode--${!!needsDndMode}`,
    ]
    if (origClassname) {
      classNames.push(origClassname)
    }
    let className = classNames.join(" ")

    const possiblePoints = pickUtils.getPossiblePointsFor(event.id)
    // const additionalPoints = pickUtils.getAdditionalPoints(event.id)
    const usesSpread = pickUtils.usesSpread()
    const homeSpread = pickUtils.getSpreadFor(event, true)
    const awaySpread = pickUtils.getSpreadFor(event, false)
    const showSpreadWarning = usesSpread && !homeSpread
    const { id, startsAt, timeRemaining } = event
    const isStarted = startsAt < Date.now()
    const hasRanks = pickUtils.hasRanks(event)
    if (this.props.log) {
      this.props.log(`'rendered' ${id} (${timeRemaining}, ${startsAt}) ${pickUtils.humanPick(eventId, itemId)}`)
    }
    if (mode === "ma") {
      return (
        <EventRowStyled className={className} {...rest} ref={forwardedRef}>
          {(event.extra.gameTitle && (
            <div className="game-title-container">
              <h5 className="game-title">{event.extra.gameTitle}</h5>
            </div>
          )) ||
            null}
          {sides.map((sd) => {
            return (
              <div className={`${mode}-row side--${sd}`} key={sd}>
                {versions.map((version) => (
                  <EventTeam
                    key={sd + version}
                    pickTeam={pickTeam}
                    eventId={id}
                    matchupId={matchupId}
                    isMine={isMine}
                    side={sd}
                    version={version}
                    isStarted={isStarted}
                    showSaveAnimation={showSaveAnimation}
                    delaySaveCommit={delaySaveCommit}
                    canUseTeamLogos={canUseTeamLogos}
                    useOutsideSaveAnimation={useOutsideSaveAnimation}
                    clearSaving={this.clearSaving}
                    startSaving={this.startSaving}
                    pickUtils={pickUtils}
                    maContextRecord={this.props[`${sd}MAContextRecord`]}
                    cacheBuster={cacheBuster}
                    disabled={showSpreadWarning}
                  />
                ))}
              </div>
            )
          })}
        </EventRowStyled>
      )
    }

    const gameWeightChoices = usesWeights && pickUtils.getGameWeightChoices()
    const fauxField = {} as any
    const fauxForm = {} as any
    if (gameWeightChoices) {
      fauxForm.setFieldValue = this.setFieldValue
      fauxField.onChange = this.onSelectChange
      // see note on weight display above
      fauxField.value = (additionalPoints || 0) + 1
      fauxField.name = eventId + "weight"
    }
    const isTopChoice = !!gameWeightChoices && Math.max(...gameWeightChoices) === additionalPoints

    if (mode === "weighting") {
      return (
        <EventRowStyled className={className} {...rest} ref={forwardedRef}>
          <div className="cardbody">
            <div className="weighting__weight">
              <NexusSelect field={fauxField} form={fauxForm} options={gameWeightChoices.map(mapChoiceToOption).reverse()} />
            </div>
            <div className="cardbodyleft">
              {sides.map((sd) => (
                <div key={sd} className="cardteam cardbodyrow">
                  {hasRanks ? <div className="cardteam__team-rank">{event.extra[`${sd}TeamRank`] || ""}</div> : null}
                  <div>{teamName(event[`${sd}Team`])}</div>
                  <i>{event.extra[`${sd}TeamPickemPercentOwned`]}%</i>
                  <div>{sd === homeSide ? homeSpread : awaySpread}</div>
                </div>
              ))}
            </div>
            <div className="cardbodyright">
              <IconButton as="button" type="button" className="rightactionbtn" onClick={this.setMaxWeight} disabled={isTopChoice}>
                <AlignTopIcon />
              </IconButton>
              <IconButton className={`rightactionbtn is-dragged--${!!isDragged}`} as="div" data-movable-handle="true">
                <MoveIcon />
              </IconButton>
            </div>
          </div>
        </EventRowStyled>
      )
    }

    // PickEmProp if isParlay
    if (isParlay) {
      const propPickEmQuestion = pickUtils.getPickEmPropQuestionForEvent(event)
      const spreadMovementInfo = pickUtils.getSpreadMovementInfoFor(event, pick?.itemId)
      const isPropDisabled = spreadMovementInfo.spreadNotAvailable
      const sideIter = spreadMovementInfo.favoredTeamIsHomeTeam ? reversedSides : sides
      if (isPropDisabled) {
        className += " pickem-event__is-disabled--true"
      }
      return (
        <EventRowStyled className={className} {...rest} ref={forwardedRef}>
          <div className="cardheader">
            <PickemEventMatch className="cardheader__match" event={event} />
            <PickemEventTimeOrScore event={event} lastScoredAt={lastScoredAt} hasScoringBreakdown={hasScoringBreakdown} />
            {(useOutsideSaveAnimation && <AnimatedSave isSaving={this.state.isSaving} className="static-animated-save" />) || null}
          </div>
          <div className="cardbody">
            <p className="cardbody__prop-question">
              {(isPropDisabled && (
                <b>
                  <WarningSvg /> Spread
                </b>
              )) ||
                null}
              {propPickEmQuestion}
            </p>
            <div className="cardbody__prop-options-container">
              {
                // we want to show home team on the left
                sideIter.map((sd) => {
                  return (
                    <EventTeam
                      version="prop"
                      pickTeam={pickTeam}
                      eventId={id}
                      isMine={isMine}
                      side={sd}
                      key={sd}
                      isStarted={isStarted}
                      showSaveAnimation={showSaveAnimation}
                      delaySaveCommit={delaySaveCommit}
                      canUseTeamLogos={canUseTeamLogos}
                      useOutsideSaveAnimation={useOutsideSaveAnimation}
                      clearSaving={this.clearSaving}
                      startSaving={this.startSaving}
                      pickUtils={pickUtils}
                      cacheBuster={cacheBuster}
                    />
                  )
                })
              }
            </div>
          </div>
          {(hasActionBtns && <hr />) || null}
          {(hasActionBtns && (
            <div className="cardactions">
              {fauxField.name && (
                <div className="cardactions__group">
                  <NexusSelect field={fauxField} form={fauxForm} disabled={isLocked} options={gameWeightChoices.map(mapChoiceToOption).reverse()} />
                  <div className="cardactions__divider" />
                  {(!needsDndMode && !isLocked && (
                    <IconButton as="button" type="button" className="cardactions__align-top" onClick={this.setMaxWeight} disabled={isTopChoice}>
                      <AlignTopIcon />
                    </IconButton>
                  )) ||
                    null}
                </div>
              )}
              {!hideMA && (
                <LinkButton className="cardactions__ma-btn" type="button" onClick={this.toggleMA} title={`Game Info`}>
                  Game Analysis
                </LinkButton>
              )}
              <PickemEventSpreadChange
                className="cardactions__prop-lines"
                {...spreadMovementInfo}
                onClick={(this.props.showScoringBreakdown && this.showScoringBreakdown) || null}
              />
            </div>
          )) ||
            null}
        </EventRowStyled>
      )
    }

    return (
      <EventRowStyled className={className} {...rest} ref={forwardedRef}>
        <div className="cardheader">
          <PickemEventTimeOrScore event={event} lastScoredAt={lastScoredAt} hasScoringBreakdown={hasScoringBreakdown} />
          {(hasScoringBreakdown && (
            <button className="cardheader__pts-desc" type="button" onClick={this.showScoringBreakdown}>
              {(showSpreadWarning && (
                <b>
                  <WarningSvg /> Spread
                </b>
              )) ||
                null}
              <i>
                {possiblePoints} PT{possiblePoints === 1 ? "" : "S"} <InformationOutlineSvg />
              </i>
            </button>
          )) ||
            (showSpreadWarning && (
              <span className="cardheader__right">
                Pending:
                <Nbsp />
                <Nbsp />
                <b>
                  <WarningSvg /> No Spread
                </b>
              </span>
            )) ||
            (event.extra.gameTitle && <span className="cardheader__right">{event.extra.gameTitle}</span>) ||
            null}
          {(useOutsideSaveAnimation && <AnimatedSave isSaving={this.state.isSaving} className="static-animated-save" />) || null}
        </div>
        <div className="cardbody">
          {fauxField.name && !needsDndMode && (
            <IconButton disabled={isLocked} className={`cardbodyleftaction is-dragging--${!!isDragged}`} data-movable-handle="true">
              <MoveIcon />
            </IconButton>
          )}
          <div className="cardbodyleft">
            {sides.map((sd) => (
              <EventTeam
                pickTeam={pickTeam}
                eventId={id}
                isMine={isMine}
                side={sd}
                key={sd}
                version="team"
                isStarted={isStarted}
                showSaveAnimation={showSaveAnimation}
                delaySaveCommit={delaySaveCommit}
                canUseTeamLogos={canUseTeamLogos}
                useOutsideSaveAnimation={useOutsideSaveAnimation}
                clearSaving={this.clearSaving}
                startSaving={this.startSaving}
                pickUtils={pickUtils}
                cacheBuster={cacheBuster}
              />
            ))}
          </div>
          <div className="cardbodyright">
            {sides.map((sd) => (
              <EventTeam
                pickTeam={pickTeam}
                eventId={id}
                isMine={isMine}
                key={sd}
                side={sd}
                version="btn"
                isStarted={isStarted}
                showSaveAnimation={showSaveAnimation}
                delaySaveCommit={delaySaveCommit}
                useOutsideSaveAnimation={useOutsideSaveAnimation}
                clearSaving={this.clearSaving}
                startSaving={this.startSaving}
                pickUtils={pickUtils}
                cacheBuster={cacheBuster}
                disabled={showSpreadWarning}
              />
            ))}
          </div>
        </div>
        {(hasActionBtns && <hr />) || null}
        {(hasActionBtns && (
          <div className="cardactions">
            {fauxField.name && (
              <div className="cardactions__group">
                <NexusSelect field={fauxField} form={fauxForm} disabled={isLocked} options={gameWeightChoices.map(mapChoiceToOption).reverse()} />
                <div className="cardactions__divider" />
                {(!needsDndMode && !isLocked && (
                  <IconButton as="button" type="button" className="cardactions__align-top" onClick={this.setMaxWeight} disabled={isTopChoice}>
                    <AlignTopIcon />
                  </IconButton>
                )) ||
                  null}
              </div>
            )}
            {!hideMA && (
              <LinkButton type="button" onClick={this.toggleMA} title={`Game Info`}>
                Matchup Analysis
              </LinkButton>
            )}
          </div>
        )) ||
          null}
      </EventRowStyled>
    )
  }
}

function PickemEventForwardRef(props, ref) {
  return <PickemEvent {...props} forwardedRef={ref} />
}

const ForwardedPickemEventProps = React.forwardRef<HTMLDivElement, IPickemEventProps>(PickemEventForwardRef)

export default ForwardedPickemEventProps
