import * as React from "react"
import PickUtils from "../../../../common/pick-utils"
import A from "../../../components/A"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import { FlexRow } from "../../../components/FlexComponents"
import Modal, { IModalProps, ModalWrapper } from "../../../components/Modal"
import { teamLongName } from "../../../utils/data-utils"
import PickemEvent, { PickemEventSpreadChange } from "../components/PickemEvent"
import {
  ScoringBreakdownContainer,
  ScoringBreakdownHr,
  ScoringBreakdownModalLine,
  ScoringBreakdownTitle,
  ScoringBreakdownSpreadContainer,
  ScoringBreakdownTabs,
} from "../styles/ScoringBreakdownModal.styles"
import { OpacityBtn } from "../../../components/Button"
import ArrowNarrow from "../../../components/icons/ArrowNarrow"
import { IPickUtilsPicks, IPickUtilsEvent } from "../../../../common/common-utils-types"
import { oneDay } from "../../../../common/misc-utils"
import { getVisualOptions } from "@cbs-sports/sports-shared-client/build/cjs/utils/VisualOptions"
import { ENUM_WILLIAM_HILL_NEW_JERSEY } from "../../../../common/enums"

export const scoringBreakdownInfoKey = "scoringbreakdown" as IScoringModalBreadkownVariants
export const lastTiebreakerInfoKey = "tiebreakerdescription" as IScoringModalBreadkownVariants
export const spreadInfoKey = "spread" as IScoringModalBreadkownVariants
export type IScoringModalBreadkownVariants = "scoringbreakdown" | "tiebreakerdescription" | "spread" | null

export interface IScoringModalBreadkownProps {
  isOpen: boolean
  eventId: string
  teamId?: string | null
  poolSettingsUrl: string
  tiebreakerQuestionId?: string
  variant: IScoringModalBreadkownVariants
}
interface IProps extends IModalProps, IScoringModalBreadkownProps {
  closeModal: () => void
  pickUtils: PickUtils
}
interface IPropsSpread {
  closeModal: () => void
  pickUtils: PickUtils
  eventId: string
}

const gameExample = {
  id: "iv3gk3tuhiztcnrqhazdm===",
  startsAt: 1600016400000,
  markedFinalAt: null,
  homeTeamSpread: -3.5,
  winningTeamId: null,
  homeTeamScore: 0,
  awayTeamScore: 0,
  gameStatusDesc: "SCHEDULED",
  timeRemaining: "15:00",
  gamePeriod: null,
  tvInfoName: "FOX",
  possession: "NONE",
  weekNumber: 1,
  extra: {
    id: "iv3gk3tuiv4hi4tbhjuxmm3hnmzxi5ljoy2gq2juorrgq2l2orrw44trnbqxuzdnhu6t2===",
    homeTeamPickemPercentOwned: 50,
    awayTeamPickemPercentOwned: 50,
    homeTeamRank: null,
    awayTeamRank: null,
    gameTitle: null,
  },
  awayTeam: {
    id: "krswc3j2gqyts===",
    abbrev: "MIN",
    location: "Minnesota",
    nickName: "Vikings",
    sportType: "NFL",
    mediumName: "Minnesota",
    colorHexDex: "360651",
    colorPrimaryHex: "4f2683",
    colorSecondaryHex: "ffc62f",
    conferenceAbbrev: "NFC",
    wins: 0,
    losses: 0,
    ties: 0,
    cbsTeamId: 2,
    imageUrl: "",
  },
  homeTeam: {
    id: "krswc3j2gqyti===",
    abbrev: "GB",
    location: "Green Bay",
    nickName: "Packers",
    sportType: "NFL",
    colorHexDex: "003B2A",
    colorPrimaryHex: "203731",
    colorSecondaryHex: "ffb612",
    conferenceAbbrev: "NFC",
    wins: 0,
    losses: 0,
    mediumName: "Green Bay",
    ties: 0,
    cbsTeamId: 1,
    imageUrl: "",
  },
  oddsMarket: {
    id: "iv3gk3tuj5sgi42nmfzgwzluhjhwizdtgmytmmbygi3duv2ijzfa====",
    bookUsed: {
      id: "iv3gk3tuj5sgi42cn5xwwospmrshgqtpn5vtuv2ijzfa====",
      name: ENUM_WILLIAM_HILL_NEW_JERSEY,
      displayName: "William Hill New Jersey",
    },
    spreads: [
      {
        teamId: "krswc3j2gqyts===",
        openingSpread: "+2.5",
        openingOdds: "-106",
        odds: "-180",
      },
      {
        teamId: "krswc3j2gqyti===",
        openingSpread: "-2.5",
        openingOdds: "-114",
        odds: "-180",
      },
    ],
    moneyLines: [
      {
        teamId: "krswc3j2gqyts===",
        odds: "-108",
        openingOdds: "+129",
      },
      {
        teamId: "krswc3j2gqyti===",
        odds: "-111",
        openingOdds: "-150",
      },
    ],
  },
} as IPickUtilsEvent

const ScoringBreakdownSpread = ({ pickUtils, closeModal }: IPropsSpread) => {
  const [tabNum, changeTab] = React.useState<number>(1)
  const scrollRef = React.useRef<HTMLAnchorElement>(null)
  const tabs = [
    { id: 1, children: "What is it?", prompt: "Learn what the spread is" },
    { id: 2, children: "How it changes", prompt: "See how spreads change" },
  ]
  const goToTab = (id: number) => {
    changeTab(id)
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth", block: "end" })
    }
  }
  const nextTab = tabs.find((t) => t.id !== tabNum) || tabs[0]
  // const event = pickUtils.getEventById(eventId)
  const nowAt = Date.now()
  const event = gameExample
  event.startsAt = nowAt + oneDay
  const event2 = Object.assign(JSON.parse(JSON.stringify(event)), {
    id: "2",
    startsAt: nowAt - oneDay,
    winningTeamId: event.homeTeam?.id,
    homeTeamScore: 26,
    awayTeamScore: 21,
    gameStatusDesc: "FINAL",
  }) as IPickUtilsEvent
  const event3 = Object.assign(JSON.parse(JSON.stringify(event2)), {
    id: "3",
    winningTeamId: event.homeTeam?.id,
    homeTeamScore: 23,
    awayTeamScore: 21,
  }) as IPickUtilsEvent
  const event4 = Object.assign(JSON.parse(JSON.stringify(event)), {
    id: "4",
  }) as IPickUtilsEvent
  const originalSpread = event.homeTeamSpread || 0
  event4.homeTeamSpread = originalSpread + 1.0
  const event5 = Object.assign(JSON.parse(JSON.stringify(event)), {
    id: "5",
  }) as IPickUtilsEvent
  event5.homeTeamSpread = originalSpread - 1.0
  const picks = [
    {
      slotId: event2.id,
      itemId: event2.homeTeam?.id,
    },
    {
      slotId: event3.id,
      itemId: event3.homeTeam?.id,
    },
    {
      slotId: event4.id,
      itemId: event4.homeTeam?.id,
      spreadForItem: originalSpread,
    },
    {
      slotId: event5.id,
      itemId: event5.homeTeam?.id,
      spreadForItem: originalSpread,
    },
  ] as IPickUtilsPicks
  const initialVisualOptions = getVisualOptions("")

  const fakePickUtils = new PickUtils(
    picks,
    [event, event2, event3, event4, event5],
    pickUtils.period,
    pickUtils.matchups,
    pickUtils.teams,
    pickUtils.poolSettings,
    initialVisualOptions,
  )
  const firstSpreadInfo = fakePickUtils.getSpreadMovementInfoFor(event4, fakePickUtils.getPick(event4.id)?.itemId)
  const secondSpreadQuestion = fakePickUtils.getPickEmPropQuestionForEvent(event5, false)
  // const secondSpreadInfo = fakePickUtils.getSpreadMovementInfoFor(event5, fakePickUtils.getPick(event5.id)?.itemId)
  // window.pickUtils = fakePickUtils
  // const events = fakePickUtils.events
  return (
    <ModalWrapper
      variant="white"
      padded={false}
      closeBtnAction={closeModal}
      header={
        <div>
          <h3>The Spread</h3>
          <ScoringBreakdownTabs>
            {tabs.map((tab) => {
              const isSelected = tab.id === tabNum
              const classNames = ["tabs__tab", `tabs__tab-is-selected--${isSelected}`]
              return (
                <button key={tab.id} type="button" className={classNames.join(" ")} disabled={isSelected} onClick={() => goToTab(tab.id)}>
                  {tab.children}
                </button>
              )
            })}
          </ScoringBreakdownTabs>
        </div>
      }
    >
      <ScoringBreakdownSpreadContainer>
        <a ref={scrollRef} id="scoring-breakdown-top" href="#">
          &nbsp;
        </a>
        <section>
          <div className="spread__well">
            {(tabNum === 1 && (
              <>
                <h5>
                  A spread is like adding or subtracting points from a team's final score.
                  <br />
                  <br />
                  They are used to make the game more even.
                </h5>
              </>
            )) || (
              <>
                <h5>
                  Spreads can change throughout the week based on various factors.
                  <br />
                  <br />
                  Once you submit your entry, you lock in the current spreads.
                </h5>
              </>
            )}
          </div>
        </section>
        {(tabNum === 1 && (
          <>
            <section>
              <h5 className="spread__padded">Picking with spreads:</h5>
            </section>
            <section className="spread__max-w">
              <div className="spread__pickem-event-container">
                <PickemEvent pickUtils={fakePickUtils} eventId={event.id} />
                <div className="spread__locator-container spread__color--blue spread__locator-container--first spread__locator-container--number">
                  <div className="spread__locator"></div>
                  <div className="spread__locator-connector"></div>
                  <div className="spread__locator"></div>
                </div>
              </div>
              <h6 className="spread__padded">Questions are based on the latest spreads.</h6>
            </section>
            <section>
              <h5 className="spread__padded">After the final scores are in:</h5>
            </section>
            <section className="spread__max-w">
              {/* <h6 className="spread__padded spread__color--green spread__text-c">Correct pick</h6> */}
              <div className="spread__pickem-event-container">
                <PickemEvent pickUtils={fakePickUtils} eventId={event2.id} />
                <div className="spread__locator-container spread__color--green spread__locator-container--score">
                  <div className="spread__locator spread__locator--smaller"></div>
                </div>
                <div className="spread__locator-container spread__color--green spread__locator-container--number">
                  <div className="spread__locator spread__locator--smaller"></div>
                </div>
              </div>
              <h6 className="spread__padded">
                <strong className="spread__color--green">Correct pick!</strong> Because GB won, AND it covered the spread (ie. it won by 4 points).
              </h6>
            </section>
            <section className="spread__max-w">
              {/* <h6 className="spread__padded spread__color--red spread__text-c">Incorrect pick</h6> */}
              <div className="spread__pickem-event-container">
                <PickemEvent pickUtils={fakePickUtils} eventId={event3.id} />
                <div className="spread__locator-container spread__color--red spread__locator-container--score">
                  <div className="spread__locator spread__locator--smaller"></div>
                </div>
                <div className="spread__locator-container spread__color--red spread__locator-container--number">
                  <div className="spread__locator spread__locator--smaller"></div>
                </div>
              </div>
              <h6 className="spread__padded">
                <strong className="spread__color--red">Incorrect pick!</strong> Because GB won, BUT it didn’t cover the spread (ie. it didn’t win by
                more than 4 points).
              </h6>
            </section>
          </>
        )) || (
          <>
            <section>
              <h5 className="spread__padded">Normal State:</h5>
            </section>
            <section className="spread__max-w">
              <div className="spread__pickem-event-container">
                <PickemEvent pickUtils={fakePickUtils} eventId={event.id} />
                <div className="spread__locator-container spread__color--blue spread__locator-container--first spread__locator-container--number">
                  <div className="spread__locator"></div>
                  <div className="spread__locator-connector"></div>
                  <div className="spread__locator"></div>
                </div>
              </div>
              <h6 className="spread__padded">
                When picking, you always use the <strong>latest</strong> value. The <strong>open</strong> value simply is the first announced value.
              </h6>
            </section>
            <section>
              <h5 className="spread__padded">Changed State:</h5>
            </section>
            <section className="spread__max-w">
              <div className="spread__pickem-event-container spread__spread-change-container">
                <PickemEventSpreadChange {...firstSpreadInfo} />
              </div>
              <h6 className="spread__padded">
                If the spread changes <strong>after</strong> making a pick, you will see an indicator showing your chances of being correct are{" "}
                <strong className="spread__color--green">better</strong> or <strong className="spread__color--red">worse</strong> according to the new
                prediction.
              </h6>
            </section>
            <section className="spread__max-w">
              <div className="spread__pickem-event-container">
                <PickemEvent pickUtils={fakePickUtils} eventId={event5.id} />
                <div className="spread__locator-container spread__color--red spread__locator-container--second spread__locator-container--number">
                  <div className="spread__locator"></div>
                  <div className="spread__locator-connector"></div>
                  <div className="spread__locator"></div>
                </div>
              </div>
              <h6 className="spread__padded">
                This indicator shows <strong className="spread__color--red">worse</strong> because the <strong>latest</strong> spread would change the
                question to:
                <br />
                <strong className="spread__prop-question">{secondSpreadQuestion}</strong>
                <br />
                By editing your parlay picks, if you select <strong>Yes</strong> again, you have an increased chance of{" "}
                <strong className="spread__color--red">being wrong!</strong>
              </h6>
            </section>
            {/* <section>
              <h5 className="spread__padded">What changes spreads:</h5>
            </section>
            <section className="spread__max-w">
              <h6 className="spread__padded">
                Spreads are based off of wagers placed on who is <strong>favored</strong> in a matchup, so any information that may sway wager-makers
                will shift the spread.
                <br />
                <strong className="spread__prop-question">Examples include:</strong>
              </h6>
              <ul className="spread__ul">
                <li>Player injuries</li>
                <li>News on Team</li>
                <li>Lineup changes</li>
                <li>Weather</li>
              </ul>
            </section> */}
          </>
        )}
        <section>
          <OpacityBtn className="spread__padded spread__color--blue" onClick={() => goToTab(nextTab.id)}>
            {nextTab.prompt}&nbsp;&nbsp;&nbsp;
            <ArrowNarrow icon={true} variant="right" />
          </OpacityBtn>
        </section>
      </ScoringBreakdownSpreadContainer>
    </ModalWrapper>
  )
}

class ScoringBreakdownModal extends React.PureComponent<IProps> {
  public static modalKey = "ScoringBreakdownModal"

  public render() {
    const { isOpen, eventId, teamId, pickUtils, afterClose, closeModal, poolSettingsUrl, variant } = this.props
    const event = (isOpen && pickUtils.events.find((e) => e.id === eventId)) || undefined
    const team = (event && [event.awayTeam, event.homeTeam].find((e) => !!e && e.id === teamId)) || undefined
    const multiplier = event && pickUtils.getMultiplier(event.id)
    const spread = event && pickUtils.getSpreadFor(event)
    const parlayScoringMapping = pickUtils.parlayScoringMapping
    // const pick = event && pickUtils.picks.find((pk) => pk.slotId == event.id);
    const possiblePoints = event && pickUtils.getPossiblePointsFor(event.id)
    const additionalPoints = event && pickUtils.getAdditionalPoints(event.id)
    const multiplierSource = pickUtils.getHumanMultiplierSource()
    const weightSource = pickUtils.getHumanAdditionalPointsSource((team && event?.id) || undefined)
    const opponentSide = team?.id === event?.homeTeam?.id ? "away" : "home"
    const opponent = event && pickUtils.getTeam(event, opponentSide)
    const isTiebreakerBreakdown = variant === lastTiebreakerInfoKey
    const isSpreadBreakdown = variant === spreadInfoKey
    const isBracket = pickUtils.isBracket()
    const isParlay = pickUtils.isParlay()
    let title =
      (isTiebreakerBreakdown && "Tiebreaker") ||
      (variant === scoringBreakdownInfoKey && isParlay && "Parlay Points") ||
      (isSpreadBreakdown && "Spread") ||
      "Scoring"
    title = title + " Breakdown"
    const header = (isTiebreakerBreakdown && "Total Offensive Yards Breakdown") || (isSpreadBreakdown && "The Spread") || "Scoring Breakdown"
    return (
      <Modal isOpen={isOpen} modalType="dialog" afterClose={afterClose}>
        <AnalyticScreen subfeature={`scoring breakdown`} title={title} isModal={true} />
        {(isTiebreakerBreakdown && (
          <ModalWrapper header={header} closeBtnAction={closeModal}>
            <ScoringBreakdownTitle>Total offensive yards formula:</ScoringBreakdownTitle>
            <ScoringBreakdownContainer>
              <ScoringBreakdownModalLine>&nbsp;&nbsp;&nbsp;&nbsp;Net Rushing Yards</ScoringBreakdownModalLine>
              <ScoringBreakdownModalLine>+&nbsp;&nbsp;Net Passing Yards</ScoringBreakdownModalLine>
              <ScoringBreakdownHr />
              <ScoringBreakdownModalLine>
                <strong>Total Offensive Yards</strong>
              </ScoringBreakdownModalLine>
            </ScoringBreakdownContainer>
          </ModalWrapper>
        )) ||
          (isSpreadBreakdown && <ScoringBreakdownSpread pickUtils={pickUtils} eventId={eventId} closeModal={closeModal} />) ||
          (isParlay && (
            <ModalWrapper header={header} closeBtnAction={closeModal}>
              <ScoringBreakdownTitle>The more games you pick, your potential score increases in the following formula:</ScoringBreakdownTitle>
              <ScoringBreakdownContainer>
                {parlayScoringMapping.map((pt, i) => (
                  <div key={i}>
                    {i} Game{i === 1 ? "" : "s"} picked: <strong>{`${pt} point${pt === 1 ? "" : "s"}`}</strong>
                  </div>
                ))}
              </ScoringBreakdownContainer>
            </ModalWrapper>
          )) ||
          (isBracket && (
            <ModalWrapper header={header} closeBtnAction={closeModal}>
              <ScoringBreakdownTitle>Each correct pick will award you:</ScoringBreakdownTitle>
              <ScoringBreakdownContainer>
                {(weightSource && (
                  <ScoringBreakdownModalLine>
                    <span>1-x pts</span>
                    <i>(Based on Team Seed)</i>
                  </ScoringBreakdownModalLine>
                )) || (
                  <ScoringBreakdownModalLine>
                    <span>1 pt</span>
                    <i>(No bonus)</i>
                  </ScoringBreakdownModalLine>
                )}
                {(multiplierSource && (
                  <ScoringBreakdownModalLine>
                    <span>x 1 - x</span>
                    <i>(based on Round of pick)</i>
                  </ScoringBreakdownModalLine>
                )) || (
                  <ScoringBreakdownModalLine>
                    <span>x 1</span>
                    <i>(No round bonus)</i>
                  </ScoringBreakdownModalLine>
                )}
                <ScoringBreakdownHr />
                <ScoringBreakdownTitle>Incorrect picks give 0 points no matter what</ScoringBreakdownTitle>
              </ScoringBreakdownContainer>
              <FlexRow>
                <A to={poolSettingsUrl}>Review Pool Rules</A>
              </FlexRow>
            </ModalWrapper>
          )) || (
            <ModalWrapper header={header} closeBtnAction={closeModal}>
              <ScoringBreakdownTitle>
                {(spread &&
                  team &&
                  opponent &&
                  spread !== "0" &&
                  `If ${teamLongName(team)} beat ${teamLongName(opponent)} by ${spread} or more points:`) ||
                  (team && opponent && `If ${teamLongName(team)} beat ${teamLongName(opponent)}:`) ||
                  null}
              </ScoringBreakdownTitle>
              <ScoringBreakdownContainer>
                {(weightSource && (
                  <ScoringBreakdownModalLine>
                    <span>{Math.round(additionalPoints || 0) + 1}</span>
                    <i>(from {weightSource})</i>
                  </ScoringBreakdownModalLine>
                )) || (
                  <ScoringBreakdownModalLine>
                    <span>1</span>
                    <i>(for correct pick)</i>
                  </ScoringBreakdownModalLine>
                )}
                {(multiplierSource && (
                  <ScoringBreakdownModalLine>
                    <span>x {multiplier}</span>
                    <i>(from {multiplierSource})</i>
                  </ScoringBreakdownModalLine>
                )) ||
                  null}
                <ScoringBreakdownHr />
                <ScoringBreakdownModalLine>
                  <span>= {possiblePoints}</span>
                  <i>Point{possiblePoints === 1 ? "" : "s"}</i>
                </ScoringBreakdownModalLine>
              </ScoringBreakdownContainer>
              <FlexRow>
                <A to={poolSettingsUrl}>Review Pool Rules</A>
              </FlexRow>
            </ModalWrapper>
          )}
      </Modal>
    )
  }
}

export default ScoringBreakdownModal
