import * as React from "react"
import { LinkButton, OpacityBtn } from "../../../components/Button"
import CarrotSvg from "../../../components/icons/Carrot"
import LoadingView from "../../../components/LoadingView"
import Modal from "../../../components/Modal"
import CardActions from "../../../shared/atoms/Card/CardActions"
import CardDivider from "../../../shared/atoms/Card/CardDivider"
import {
  EntryBarBanner,
  EntryBarModalWrapper,
  EntryBarShrinkingArea,
  EntryBarStickyContainer,
  EntryBarStickyWrapper,
  EntryBarWrapper,
} from "../styles/EntryBar.styles"

interface IEntryBarProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  banner: React.ReactElement | null
  hideBannerForHandheld: boolean
  preLockPromo: React.ReactElement | null
  hasStartedEvent: boolean
  heading: React.ReactElement
  children: React.ReactElement
  preScroll: React.ReactElement
  cta: React.ReactElement | string
  isOpenTop: boolean | null
  stickyTop: boolean | null
  toggle: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  childrenMaxHeight: number | null
  isIosBrowser: boolean
  isBottomStickyPickSlip: boolean | null
  isHandheldDeviceType: boolean
  isAlwaysCollapsed: boolean
  desktopPaddingBottom: number
  className?: string
}

export const EntryBar = React.forwardRef<HTMLElement, IEntryBarProps>((props, ref) => {
  const {
    banner,
    hideBannerForHandheld,
    heading,
    children,
    preScroll,
    isOpenTop,
    preLockPromo,
    stickyTop,
    hasStartedEvent,
    toggle,
    childrenMaxHeight,
    cta,
    isIosBrowser,
    isBottomStickyPickSlip = false,
    isHandheldDeviceType = true,
    isAlwaysCollapsed = false,
    className = "",
    desktopPaddingBottom,
    ...rest
  } = props
  const isOpen = isOpenTop !== null
  const isStuck = stickyTop !== null && !isBottomStickyPickSlip
  const stickyStyles = {} as React.CSSProperties
  const childrenStyles = {} as React.CSSProperties
  // entryBarMobileTop: null,
  // entryBarDesktopInnerHeight: null,
  if (isIosBrowser) {
    // stickyStyles.top = `${stickyTop}px`;
  } else if (isStuck) {
    stickyStyles.position = "fixed"
    stickyStyles.top = `${stickyTop}px`
  }
  // if (isOpen) {
  //   if (isIosBrowser) {
  //     stickyStyles.transform = `translateY(${isOpenTop}px)`;
  //   } else {
  //     stickyStyles.position = "fixed";
  //     stickyStyles.transform = `translateY(${isOpenTop}px)`;
  //     stickyStyles.top = `${-1 * isOpenTop}px`;
  //   }
  // }
  if (childrenMaxHeight) {
    childrenStyles.maxHeight = `${childrenMaxHeight + desktopPaddingBottom}px`
    childrenStyles.paddingBottom = `${desktopPaddingBottom}px`
  }
  // console.dir(stickyStyles);
  const classNames = [
    className,
    `is-ios--${isIosBrowser}`,
    `is-open--${false}`,
    `is-always-stuck--${isAlwaysCollapsed}`,
    `is-stuck--${isStuck || isAlwaysCollapsed}`,
    `is-always-stuck--${isAlwaysCollapsed}`,
    `has-banner--${!!banner}`,
    `is-locked--${hasStartedEvent}`,
    `has-pre-lock-promo--${!!preLockPromo}`,
    `is-callout-only--${isBottomStickyPickSlip}`,
    `hide-banner-for-handheld--${hideBannerForHandheld}`,
  ]
  return (
    <>
      <EntryBarWrapper ref={ref as any} style={childrenStyles} className={classNames.join(" ")} {...rest}>
        <div className="placeholder">
          <EntryBarStickyWrapper className="sticky-wrapper" style={stickyStyles}>
            <EntryBarBanner className="banner">{banner}</EntryBarBanner>
            <EntryBarStickyContainer className="sticky-container">
              <div className="heading">{heading}</div>
              <CarrotSvg className="expander" variant="down" />
              <EntryBarShrinkingArea className="shrinking-area">
                <div className="prescroll">{preScroll}</div>
                <CardDivider isFullWidth={false} />
              </EntryBarShrinkingArea>
              <CardActions className="actions">
                <LinkButton type="button" onClick={toggle}>
                  {cta}
                </LinkButton>
              </CardActions>
            </EntryBarStickyContainer>
          </EntryBarStickyWrapper>
        </div>
        <div className="children">
          <div className="children-inner">
            {isHandheldDeviceType ? <LoadingView /> : children}
            {isHandheldDeviceType ? <LoadingView /> : preLockPromo}
          </div>
        </div>
      </EntryBarWrapper>
      {isHandheldDeviceType ? (
        <Modal isOpen={isOpen} modalType="entryBar">
          <EntryBarModalWrapper className={`is-reversed-toggle--${isBottomStickyPickSlip}`}>
            <EntryBarStickyWrapper className="sticky-wrapper">
              <EntryBarStickyContainer className="sticky-container">
                <OpacityBtn as="button" type="button" onClick={toggle} className="heading">
                  {heading}
                </OpacityBtn>
                <CarrotSvg className="expander" variant="down" />
                <EntryBarShrinkingArea className="shrinking-area">
                  <div className="prescroll">{preScroll}</div>
                </EntryBarShrinkingArea>
              </EntryBarStickyContainer>
              <EntryBarBanner className="banner">{banner}</EntryBarBanner>
            </EntryBarStickyWrapper>
            {children}
            {preLockPromo}
          </EntryBarModalWrapper>
        </Modal>
      ) : null}
    </>
  )
})

export default EntryBar
