import * as React from "react"

const SportsLineSvg = ({ color = "#00ff12", className = "sports-line-svg" }) => (
  <svg className={className} viewBox="0 0 28 40" xmlns="http://www.w3.org/2000/svg">
    <g fill={color} fillRule="evenodd">
      {/* tslint:disable-next-line */}
      <path d="M27.86 15.917l-7.514 1.597v10.398L5.45 31.078v8.502l22.368-4.754v-.01l.042-.009v-18.89M5.45 24.821l7.54-1.603V12.811l14.87-3.16V1.156L5.494 5.912v.01l-.042.009v18.89" />
      {/* tslint:disable-next-line */}
      <path
        d="M22.767.074l-6.694 1.423-7.516 1.597h.002l-.685.146-.008-.001L.35 4.837v18.898l5.1 1.083V5.92h-.003l3.773-.802h.004l18.636-3.961v.002l.003-.001L22.767.074M22.757 14.84l-7.514 1.597h-.001v10.387L.352 29.989H.352v8.5l5.1 1.085v-8.501H5.45l12.11-2.575 2.786-.593V17.521l-.005-.001.005-.001v-.005l7.497-1.594-5.086-1.08"
        opacity=".6"
      />
    </g>
  </svg>
)

export default SportsLineSvg
