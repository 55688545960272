import * as React from "react"
import { IBracketKeyValMapping, TBracketSection } from "../../../../common/common-utils-types.d"
import palette from "../../../../common/palette"
import PickUtils from "../../../../common/pick-utils"
import { pxToRem } from "../../../utils/style-utils"
import { TScrollToSection } from "../containers/IPicksProps"
import { BracketMinimapLi, BracketMinimapUl, BracketMinimapWrapper, SvgMinimapWrapper } from "../styles/BracketMinimap.styles"

interface IMinimap {
  highlightedSection: TBracketSection
  roundCount: number
  bracketMapping: IBracketKeyValMapping
  pickUtils: PickUtils
  scrollToSection: TScrollToSection
}

const ratio = 0.2
const scale = 25.0
const dims = {
  width: 350 * 3 * ratio,
  height: 350 * ratio,
  scale,
  matchupHtoWRatio: 0.75,
}
const useFill = true

const minimapSelectedHex = palette.blue1
const minimapDefaultHex = palette.gray3
const isMobileFixed = true
const showMinimap = false

const gwidth = dims.width
const gheight = dims.height
const svgStyles = { width: pxToRem(gwidth), height: pxToRem(gheight), display: "block" }
const colors = {
  unpicked: palette.gray3,
  correct: palette.green,
  incorrect: palette.red,
  picked: palette.blue1,
}

function MatchupRect({ x, y, width, height, text, color }) {
  return (
    <g>
      <rect
        className="minimap__matchup"
        stroke={useFill ? (color === colors.unpicked && palette.gray2) || color : color}
        fill={useFill ? color + "88" : "transparent"}
        x={x}
        y={y}
        width={width}
        height={height}
      />
      {(text && (
        <text x={x + width / 2} y={y + height / 2} style={{ fontSize: "0.1em" }} fill="#0f0f0f" dominantBaseline="middle" textAnchor="middle">
          {text}
        </text>
      )) ||
        null}
    </g>
  )
  // return (
  //   <>
  //     <rect stroke={"#000"} strokeWidth="0.1" x={x} y={y} width={width} height={height}/>
  //     <text stroke={"#000"} x={x} y={y} width={width} height={height} textLength={`${percentOfViewBox}%`} textAchor="middle" alignmentBaseline="central">{text}</text>
  //   </>
  // )
}

const BracketMinimap = ({ highlightedSection, scrollToSection, roundCount, pickUtils, bracketMapping }: IMinimap) => {
  // console.log(`CALLED: ${highlightedSection}`);
  const bracketUtils = pickUtils.bracketUtils
  if (!showMinimap) {
    const bracketPeriodTree = pickUtils && pickUtils.bracketPeriodTree && bracketUtils.buildCoordinateMap(pickUtils.bracketPeriodTree, dims)
    if (bracketPeriodTree) {
      const {
        visualOptions: { viewBoxHeight, viewBoxWidth },
        matchups,
      } = bracketPeriodTree
      // if (typeof(window) !== "undefined") {
      //   // console.log('nice')
      //   window.pickUtils = pickUtils;
      //   window.bracketPeriodTree = bracketPeriodTree;
      // }
      const multiplier = (highlightedSection === "right" && 2) || (highlightedSection === "mid" && 1) || 0
      const pdd = 0
      const { picks } = bracketMapping
      return (
        <SvgMinimapWrapper roundCount={roundCount}>
          <svg style={svgStyles} xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}>
            <g fill="none" fillRule="evenodd">
              {matchups.map((matchup) => {
                const coords = bracketPeriodTree.idToCoordinates[matchup.id]!
                const itemId = picks[matchup.id]
                const color =
                  (matchup.winnerId && matchup.winnerId === itemId && colors.correct) ||
                  (matchup.winnerId && matchup.winnerId !== itemId && colors.incorrect) ||
                  (itemId && colors.picked) ||
                  colors.unpicked
                return (
                  <MatchupRect
                    key={matchup.id}
                    x={coords.x}
                    y={coords.y}
                    width={bracketPeriodTree.matchupWidth}
                    height={bracketPeriodTree.matchupHeight}
                    color={color}
                    text={null && `M-${matchup.tournamentRound}-${matchup.roundOrdinal}`}
                  />
                )
              })}
              <rect
                className={`minimap__viewport`}
                x={(viewBoxWidth / 3) * multiplier - pdd}
                y={0 - pdd}
                width={viewBoxWidth / 3 + pdd * 2}
                height={viewBoxHeight + pdd * 2}
              />
            </g>
          </svg>
        </SvgMinimapWrapper>
      )
    } else {
      return null
    }
  }
  return (
    <BracketMinimapWrapper className={`is-mobile-fixed--${isMobileFixed}`} roundCount={roundCount}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128.252 27.032">
        <g fill="none" strokeWidth="2">
          <g stroke={highlightedSection === "left" ? minimapSelectedHex : minimapDefaultHex}>
            <path d="M0 1h16.253v7.96H0M14.175 18.073h16.253v7.96H14.175" />
            <path d="M16.253 4.98h17.128v5.675h15.2v11.4h-18.1" />
          </g>
          <g stroke={highlightedSection === "right" ? minimapSelectedHex : minimapDefaultHex}>
            <path d="M128.253 1H112v7.96h16.253M114.077 18.073H97.824v7.96h16.253" />
            <path d="M112 4.98H94.872v5.675h-15.2v11.4h18.1" />
          </g>
          <path
            d="M59.124 15.956h-9.552M78.706 15.956h-9.095M69.611 10.326H59.124"
            stroke={highlightedSection === "mid" ? minimapSelectedHex : minimapDefaultHex}
          />
        </g>
      </svg>
      {!isMobileFixed && (
        <BracketMinimapUl>
          {bracketUtils.bracketSections.map((section) => (
            <BracketMinimapLi
              type="button"
              isHighlighted={highlightedSection === section}
              key={section}
              title={`Go to section`}
              onClick={() => scrollToSection(section)}
            />
          ))}
        </BracketMinimapUl>
      )}
    </BracketMinimapWrapper>
  )
}

export default BracketMinimap
