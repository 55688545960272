import { DateTime } from "luxon"
import React from "react"
import { PoolPeriodQuery_gameInstance_period_segment_periods_edges_node } from "../../../../__generated__/PoolPeriodQuery"
import LockSvg from "../../../components/icons/Lock"
import { palette } from "../../../utils/style-utils"
import { LockContainer } from "../styles/EntryBarTournamentLink.styles"

interface IEntryBarPeriodContainer {
  period: PoolPeriodQuery_gameInstance_period_segment_periods_edges_node
  isInComingSoon: boolean
  isLocked: boolean
}

const EntryBarPeriodContainer = (props: IEntryBarPeriodContainer) => {
  const { isLocked, isInComingSoon, period } = props
  const { description, locksAt } = period

  const lockTime = locksAt ? DateTime.fromMillis(locksAt).toFormat("L/dd h:mma") : null

  if (isInComingSoon) {
    return (
      <>
        <div className={"entryBarPeriod"}>
          <div className={"periodDescription"}>
            <strong>{description}</strong>
          </div>
        </div>
        <div className={"upperCased comingSoon"}>
          <div>Bracket Coming Soon</div>
        </div>
      </>
    )
  }

  return (
    <div className={"entryBarPeriod withData"}>
      <div className={"periodDescription"}>
        <strong>{description}</strong>
        {isLocked ? (
          <LockContainer>
            <LockSvg color={palette.gray2} />
          </LockContainer>
        ) : null}
      </div>
      <div className={"upperCased lockContainer"}>{!isLocked ? <small>{`Locks: ${lockTime}`}</small> : null}</div>
    </div>
  )
}

export default EntryBarPeriodContainer
