import * as React from "react"
import * as ReactDOM from "react-dom"
import { IItemProps, IProps, TEvent } from "./types"
import { binarySearch, checkIfInteractive, getTranslateOffset, isTouchEvent, schd, setItemTransition, transformItem } from "./utils"

const AUTOSCROLL_ACTIVE_OFFSET = 200
const AUTOSCROLL_SPEED_RATIO = 10
const hiddenStyles = { visibility: "hidden" } as React.CSSProperties
const hiddenSelectedStyles = { visibility: "hidden", zIndex: 5000 } as React.CSSProperties

class List<Value> extends React.Component<IProps<Value>> {
  public static defaultProps = {
    transitionDuration: 300,
    lockVertically: false,
    removableByMove: false,
    voiceover: {
      item: (position: number) => `You are currently at a draggable item at position ${position}. Press space bar to lift.`,
      lifted: (position: number) =>
        `You have lifted item at position ${position}. Press j to move down, k to move up, space bar to drop and escape to cancel.`,
      moved: (position: number, up: boolean) =>
        `You have moved the lifted item ${
          up ? "up" : "down"
        } to position ${position}. Press j to move down, k to move up, space bar to drop and escape to cancel.`,
      dropped: (from: number, to: number) => `You have dropped the item. It has moved from position ${from} to ${to}.`,
      canceled: (position: number) => `You have cancelled the movement. The item has returned to its starting position of ${position}.`,
    },
  }
  public listRef = React.createRef<HTMLElement>()
  public ghostRef = React.createRef<HTMLElement>()
  public topOffsets: number[] = []
  public itemTranslateOffsets: number[] = []
  public initialYOffset = 0
  public lastScroll = 0
  public lastYOffset = 0
  public lastListYOffset = 0
  public dropTimeout?: number
  public needle = -1
  public afterIndex = -2
  public state = {
    itemDragged: -1,
    itemDraggedOutOfBounds: -1,
    selectedItem: -1,
    initialX: 0,
    initialY: 0,
    targetX: 0,
    targetY: 0,
    targetHeight: 0,
    targetWidth: 0,
    liveText: "",
    scrollingSpeed: 0,
    scrollWindow: false,
  }
  public schdOnMouseMove: (e: MouseEvent) => void
  public schdOnTouchMove: (e: TouchEvent) => void
  public schdOnEnd: (e: Event) => void

  constructor(props: IProps<Value>) {
    super(props)
    this.schdOnMouseMove = schd(this.onMouseMove)
    this.schdOnTouchMove = schd(this.onTouchMove)
    this.schdOnEnd = schd(this.onEnd)
  }

  public componentDidMount() {
    this.calculateOffsets()
    document.addEventListener("touchstart", this.onMouseOrTouchStart as any, {
      passive: true,
      capture: false,
    })
    document.addEventListener("mousedown", this.onMouseOrTouchStart as any)
  }

  public componentDidUpdate(_prevProps: any, prevState: { scrollingSpeed: number }) {
    // console.log('componentDidUpdate')
    // console.dir(this.state)
    if (prevState.scrollingSpeed !== this.state.scrollingSpeed && prevState.scrollingSpeed === 0) {
      this.doScrolling()
    }
  }

  public componentWillUnmount() {
    document.removeEventListener("touchstart", this.onMouseOrTouchStart as any)
    document.removeEventListener("mousedown", this.onMouseOrTouchStart as any)
  }

  public doScrolling = () => {
    const { scrollingSpeed, scrollWindow } = this.state
    window.requestAnimationFrame(() => {
      const scrollContainer = this.getScrollContainerEl()
      scrollContainer.style.scrollBehavior = "auto"
      // console.log(`'doScrolling': ${scrollContainer.scrollTop} ${scrollWindow} ${scrollingSpeed}`, scrollContainer)
      if (scrollWindow) {
        scrollContainer.scrollTo(0, scrollContainer.scrollTop + scrollingSpeed * 1.5)
        // scrollContainer.scrollTop += scrollingSpeed;
      } else {
        this.listRef.current!.scrollTop += scrollingSpeed
      }
      if (scrollingSpeed !== 0) {
        this.doScrolling()
      } else {
        scrollContainer.style.scrollBehavior = ""
      }
    })
  }

  public getChildren = () => {
    if (this.listRef && this.listRef.current) {
      return Array.from(this.listRef.current.children)
    }
    console.warn("No items found in the List container. Did you forget to pass & spread the `props` param in renderList?")
    return []
  }

  public calculateOffsets = () => {
    this.topOffsets = this.getChildren().map((item) => item.getBoundingClientRect().top)
    this.itemTranslateOffsets = this.getChildren().map((item) => getTranslateOffset(item))
  }

  public getTargetIndex = (e: TEvent) => {
    return this.getChildren().findIndex((child) => child === e.target || child.contains(e.target as Node))
  }

  public onMouseOrTouchStart = (e: MouseEvent & TouchEvent) => {
    if (this.dropTimeout && this.state.itemDragged > -1) {
      window.clearTimeout(this.dropTimeout)
      this.finishDrop()
    }
    const isTouch = isTouchEvent(e)
    if (!isTouch && e.button !== 0) {
      return
    }
    // console.log(`preventing? ${isTouch}`)
    const targetEl = e.target as HTMLElement
    if (targetEl && !targetEl.dataset.movableHandle) {
      return
    }
    const index = this.getTargetIndex(e as any)
    if (index === -1 || (this.props.values[index] as any).disabled) {
      return
    }
    const listItemTouched = this.getChildren()[index]
    // const handle = listItemTouched.querySelector("[data-movable-handle]");
    // if (handle && !handle.contains(e.target as any)) {
    //   return;
    // }
    if (checkIfInteractive(e.target as HTMLElement, listItemTouched)) {
      return
    }
    // console.log(`preventing... ${isTouch}`)
    // e.preventDefault();
    if (this.props.beforeDrag) {
      this.props.beforeDrag({
        elements: this.getChildren(),
        index,
      })
    }
    if (isTouch) {
      const opts = { passive: false }
      document.addEventListener("touchend", this.schdOnEnd, opts)
      document.addEventListener("touchmove", this.schdOnTouchMove, opts)
      document.addEventListener("touchcancel", this.schdOnEnd, opts)
    } else {
      document.addEventListener("mousemove", this.schdOnMouseMove)
      document.addEventListener("mouseup", this.schdOnEnd)
    }
    this.onStart(listItemTouched as HTMLElement, isTouch ? e.touches[0].clientX : e.clientX, isTouch ? e.touches[0].clientY : e.clientY, index)
  }

  public getScrollContainerEl = () => (this.props.getScrollContainerEl && this.props.getScrollContainerEl()) || this.listRef.current!

  public getYOffset = () => {
    const listScroll = this.listRef.current ? this.listRef.current.scrollTop : 0
    // return this.getScrollContainerEl().scrollTop + listScroll;
    return window.pageYOffset + listScroll
  }

  public onStart = (target: HTMLElement, clientX: number, clientY: number, index: number) => {
    if (this.state.selectedItem > -1) {
      this.setState({ selectedItem: -1 })
      this.needle = -1
    }
    const targetRect = target.getBoundingClientRect() as DOMRect
    const targetStyles = window.getComputedStyle(target)
    this.calculateOffsets()
    this.initialYOffset = this.getYOffset()
    // this.lastYOffset = this.getScrollContainerEl().scrollTop;
    this.lastYOffset = window.pageYOffset
    this.lastListYOffset = this.listRef.current!.scrollTop
    this.setState({
      itemDragged: index,
      targetX: targetRect.left - parseInt(targetStyles["margin-left" as any], 10),
      targetY: targetRect.top - parseInt(targetStyles["margin-top" as any], 10),
      targetHeight: targetRect.height,
      targetWidth: targetRect.width,
      initialX: clientX,
      initialY: clientY,
    })
  }

  public onMouseMove = (e: MouseEvent) => {
    if (e.cancelable) {
      e.preventDefault()
    }
    this.onMove(e.clientX, e.clientY)
  }

  public onTouchMove = (e: TouchEvent) => {
    if (e.cancelable) {
      e.preventDefault()
    }
    this.onMove(e.touches[0].clientX, e.touches[0].clientY)
  }

  public onWheel = (e: React.WheelEvent) => {
    if (this.state.itemDragged < 0) {
      return
    }
    this.lastScroll = this.listRef.current!.scrollTop += e.deltaY
    this.moveOtherItems()
  }

  public onMove = (clientX: number, clientY: number) => {
    if (this.state.itemDragged === -1) {
      return null
    }
    transformItem(this.ghostRef.current!, clientY - this.state.initialY, this.props.lockVertically ? 0 : clientX - this.state.initialX)
    this.autoScrolling(clientY)
    this.moveOtherItems()
    return null
  }

  public moveOtherItems = () => {
    const targetRect = this.ghostRef.current!.getBoundingClientRect()
    const itemVerticalCenter = targetRect.top + targetRect.height / 2
    const offset = getTranslateOffset(this.getChildren()[this.state.itemDragged])
    const currentYOffset = this.getYOffset()
    // adjust offsets if scrolling happens during the item movement
    if (this.initialYOffset !== currentYOffset) {
      this.topOffsets = this.topOffsets.map((_offset) => _offset - (currentYOffset - this.initialYOffset))
      this.initialYOffset = currentYOffset
    }
    if (this.props.removableByMove && this.isDraggedItemOutOfBounds()) {
      this.afterIndex = this.topOffsets.length + 1
    } else {
      this.afterIndex = binarySearch(this.topOffsets, itemVerticalCenter)
    }
    this.animateItems(this.afterIndex === -1 ? 0 : this.afterIndex, this.state.itemDragged, offset)
  }

  public autoScrolling = (clientY: number) => {
    const { top, bottom, height } = this.listRef.current!.getBoundingClientRect()
    // const scrollContainerDims = this.getScrollContainerEl().getBoundingClientRect()
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight // scrollContainerDims.height //
    // console.log(`'autoScrolling' ${clientY} ${top} ${height} (${viewportHeight})`)
    // autoscrolling for the window (down)
    if (bottom > viewportHeight && viewportHeight - clientY < AUTOSCROLL_ACTIVE_OFFSET) {
      this.setState({
        scrollingSpeed: Math.round((AUTOSCROLL_ACTIVE_OFFSET - (viewportHeight - clientY)) / AUTOSCROLL_SPEED_RATIO),
        scrollWindow: true,
      })
      // autoscrolling for the window (up)
    } else if (top < 0 && clientY < AUTOSCROLL_ACTIVE_OFFSET) {
      this.setState({
        scrollingSpeed: Math.round((AUTOSCROLL_ACTIVE_OFFSET - clientY) / -AUTOSCROLL_SPEED_RATIO),
        scrollWindow: true,
      })
    } else {
      if (this.state.scrollWindow && this.state.scrollingSpeed !== 0) {
        this.setState({ scrollingSpeed: 0, scrollWindow: false })
      }
      // autoscrolling for containers with overflow
      if (height + 20 < this.listRef.current!.scrollHeight) {
        let scrollingSpeed = 0
        if (clientY - top < AUTOSCROLL_ACTIVE_OFFSET) {
          scrollingSpeed = Math.round((AUTOSCROLL_ACTIVE_OFFSET - (clientY - top)) / -AUTOSCROLL_SPEED_RATIO)
        } else if (bottom - clientY < AUTOSCROLL_ACTIVE_OFFSET) {
          scrollingSpeed = Math.round((AUTOSCROLL_ACTIVE_OFFSET - (bottom - clientY)) / AUTOSCROLL_SPEED_RATIO)
        }
        if (this.state.scrollingSpeed !== scrollingSpeed) {
          this.setState({ scrollingSpeed })
        }
      }
    }
  }

  public animateItems = (needle: number, movedItem: number, offset: number, animateMovedItem = false) => {
    this.getChildren().forEach((item, i) => {
      setItemTransition(item, this.props.transitionDuration)
      if (movedItem === i && animateMovedItem) {
        if (movedItem === needle) {
          return transformItem(item, null)
        }
        transformItem(
          item,
          movedItem < needle
            ? this.itemTranslateOffsets.slice(movedItem + 1, needle + 1).reduce((a, b) => a + b, 0)
            : this.itemTranslateOffsets.slice(needle, movedItem).reduce((a, b) => a + b, 0) * -1,
        )
      } else if (movedItem < needle && i > movedItem && i <= needle) {
        transformItem(item, -offset)
      } else if (i < movedItem && movedItem > needle && i >= needle) {
        transformItem(item, offset)
      } else {
        transformItem(item, null)
      }
    })
  }

  public isDraggedItemOutOfBounds = () => {
    const initialRect = this.getChildren()[this.state.itemDragged].getBoundingClientRect()
    const targetRect = this.ghostRef.current!.getBoundingClientRect()
    if (Math.abs(initialRect.left - targetRect.left) > targetRect.width) {
      if (this.state.itemDraggedOutOfBounds === -1) {
        this.setState({ itemDraggedOutOfBounds: this.state.itemDragged })
      }
      return true
    }
    if (this.state.itemDraggedOutOfBounds > -1) {
      this.setState({ itemDraggedOutOfBounds: -1 })
    }
    return false
  }

  public onEnd = (e: TouchEvent & MouseEvent) => {
    if (e.cancelable) {
      e.preventDefault()
    }
    document.removeEventListener("mousemove", this.schdOnMouseMove)
    document.removeEventListener("touchmove", this.schdOnTouchMove)
    document.removeEventListener("mouseup", this.schdOnEnd)
    document.removeEventListener("touchup", this.schdOnEnd)
    document.removeEventListener("touchcancel", this.schdOnEnd)

    const removeItem = this.props.removableByMove && this.isDraggedItemOutOfBounds()
    if (!removeItem && this.props.transitionDuration > 0 && this.afterIndex !== -2) {
      // animate drop
      schd(() => {
        setItemTransition(this.ghostRef.current!, this.props.transitionDuration, "cubic-bezier(.2,1,.1,1)")
        if (this.afterIndex < 1 && this.state.itemDragged === 0) {
          transformItem(this.ghostRef.current!, 0, 0)
        } else {
          transformItem(
            this.ghostRef.current!,
            // compensate window scroll
            -(window.pageYOffset - this.lastYOffset) +
              // compensate container scroll
              -(this.listRef.current!.scrollTop - this.lastListYOffset) +
              (this.state.itemDragged < this.afterIndex
                ? this.itemTranslateOffsets.slice(this.state.itemDragged + 1, this.afterIndex + 1).reduce((a, b) => a + b, 0)
                : this.itemTranslateOffsets.slice(this.afterIndex < 0 ? 0 : this.afterIndex, this.state.itemDragged).reduce((a, b) => a + b, 0) * -1),
            0,
          )
        }
      })()
    }
    this.dropTimeout = window.setTimeout(this.finishDrop, removeItem || this.afterIndex === -2 ? 0 : this.props.transitionDuration)
  }

  public finishDrop = () => {
    const removeItem = this.props.removableByMove && this.isDraggedItemOutOfBounds()
    if (removeItem || (this.afterIndex > -2 && this.state.itemDragged !== this.afterIndex)) {
      this.props.onChange({
        oldIndex: this.state.itemDragged,
        newIndex: removeItem ? -1 : Math.max(this.afterIndex, 0),
        targetRect: this.ghostRef.current!.getBoundingClientRect(),
      })
    }
    this.getChildren().forEach((item) => {
      setItemTransition(item, 0)
      transformItem(item, null)
    })
    this.setState({ itemDragged: -1, scrollingSpeed: 0 })
    this.afterIndex = -2
    // sometimes the scroll gets messed up after the drop, fix:
    if (this.lastScroll > 0) {
      this.listRef.current!.scrollTop = this.lastScroll
      this.lastScroll = 0
    }
  }

  public onKeyDown = (e: React.KeyboardEvent) => {
    const selectedItem = this.state.selectedItem
    const index = this.getTargetIndex(e)
    if (index === -1) {
      return
    }
    if (e.key === " ") {
      e.preventDefault()
      if (selectedItem === index) {
        if (selectedItem !== this.needle) {
          this.getChildren().forEach((item) => {
            setItemTransition(item, 0)
            transformItem(item, null)
          })
          this.props.onChange({
            oldIndex: selectedItem,
            newIndex: this.needle,
            targetRect: this.getChildren()[this.needle].getBoundingClientRect(),
          })
          ;(this.getChildren()[this.needle] as HTMLElement).focus()
        }
        this.setState({
          selectedItem: -1,
          liveText: this.props.voiceover.dropped(selectedItem + 1, this.needle + 1),
        })
        this.needle = -1
      } else {
        this.setState({
          selectedItem: index,
          liveText: this.props.voiceover.lifted(index + 1),
        })
        this.needle = index
        this.calculateOffsets()
      }
    }
    if ((e.key === "ArrowDown" || e.key === "j") && selectedItem > -1 && this.needle < this.props.values.length - 1) {
      e.preventDefault()
      const offset = getTranslateOffset(this.getChildren()[selectedItem])
      this.needle++
      this.animateItems(this.needle, selectedItem, offset, true)
      this.setState({
        liveText: this.props.voiceover.moved(this.needle + 1, false),
      })
    }
    if ((e.key === "ArrowUp" || e.key === "k") && selectedItem > -1 && this.needle > 0) {
      e.preventDefault()
      const offset = getTranslateOffset(this.getChildren()[selectedItem])
      this.needle--
      this.animateItems(this.needle, selectedItem, offset, true)
      this.setState({
        liveText: this.props.voiceover.moved(this.needle + 1, true),
      })
    }
    if (e.key === "Escape" && selectedItem > -1) {
      this.getChildren().forEach((item) => {
        setItemTransition(item, 0)
        transformItem(item, null)
      })
      this.setState({
        selectedItem: -1,
        liveText: this.props.voiceover.canceled(selectedItem + 1),
      })
      this.needle = -1
    }
    if ((e.key === "Tab" || e.key === "Enter") && selectedItem > -1) {
      e.preventDefault()
    }
  }

  public moveItem = (oldIndex: number, newIndex: number, animateMovedItem = true) => {
    const offset = getTranslateOffset(this.getChildren()[newIndex])
    this.animateItems(newIndex, oldIndex, offset, animateMovedItem)
    return new Promise((res) => {
      window.setTimeout(() => {
        const children = this.getChildren()
        children.forEach((item) => {
          setItemTransition(item, 0)
          transformItem(item, null)
        })
        const newIndexItem = children[newIndex]! as HTMLElement
        this.props.onChange({
          oldIndex,
          newIndex,
          targetRect: newIndexItem.getBoundingClientRect(),
        })
        // newIndexItem.focus();
        res(newIndexItem)
      }, this.props.transitionDuration + 200)
    })
  }

  public render() {
    const ghostStyle = {
      userSelect: "none",
      touchAction: "none",
      WebkitUserSelect: "none",
      MozUserSelect: "none",
      msUserSelect: "none",
      boxSizing: "border-box",
      top: this.state.targetY,
      left: this.state.targetX,
      width: this.state.targetWidth,
      height: this.state.targetHeight,
      display: "block",
      position: "fixed",
      marginTop: 0,
    } as React.CSSProperties
    return (
      <React.Fragment>
        {this.props.renderList({
          children: this.props.values.map((value, index) => {
            const isHidden = index === this.state.itemDragged
            const isSelected = index === this.state.selectedItem
            const isDisabled = this.props.values[index] && (this.props.values[index] as any).disabled
            const props: IItemProps = {
              key: index,
              tabIndex: isDisabled ? -1 : 0,
              "aria-roledescription": this.props.voiceover.item(index + 1),
              onKeyDown: this.onKeyDown,
            }
            const style = (isHidden && isSelected && hiddenSelectedStyles) || (isHidden && hiddenStyles) || undefined
            if (style) {
              props.style = style
            }
            props.moveItem = this.moveItem
            return this.props.renderItem({
              value,
              props,
              index,
              isDragged: false,
              isSelected,
              isOutOfBounds: false,
            })
          }),
          isDragged: this.state.itemDragged > -1,
          props: {
            ref: this.listRef,
          },
        })}
        {this.state.itemDragged > -1 &&
          ReactDOM.createPortal(
            this.props.renderItem({
              value: this.props.values[this.state.itemDragged],
              props: {
                ref: this.ghostRef,
                style: ghostStyle,
                onWheel: this.onWheel,
              },
              index: this.state.itemDragged,
              isDragged: true,
              isSelected: false,
              isOutOfBounds: this.state.itemDraggedOutOfBounds > -1,
            }),
            document.body,
          )}
        <div
          aria-live="assertive"
          role="log"
          aria-atomic="true"
          style={{
            position: "absolute",
            width: "1px",
            height: "1px",
            margin: "-1px",
            border: "0px",
            padding: "0px",
            overflow: "hidden",
            clip: "react(0px, 0px, 0px, 0px)",
            clipPath: "inset(100%)",
          }}
        >
          {this.state.liveText}
        </div>
      </React.Fragment>
    )
  }
}

export default List
