import { fontFamily } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import styled from "styled-components/macro"
import LinkRow from "../../../components/LinkRow"

import { fontWeight, palette, pxToRem } from "../../../utils/style-utils"

export const EntryBarTournamentLink = styled(LinkRow)`
  height: ${pxToRem(50)};
  border-bottom: ${pxToRem(1)} solid ${palette.gray3};
  &.is-viewed--true {
    background-color: ${palette.blue4};
    border-right: ${pxToRem(1)} solid ${palette.gray3};
  }
`

export const EntryBarComponentsContainer = styled.div`
  dl {
    display: inline-flex;
    text-align: center;
  }
  dl > * {
    padding-right: ${pxToRem(12)};
  }
  dl > * > * {
    padding-bottom: ${pxToRem(4)};
  }
  strong {
    font-weight: ${fontWeight.semiBold};
  }
  small {
    font-weight: ${fontWeight.semiBold};
    font-size: ${pxToRem(11)};
    color: ${palette.gray2};
    & strong {
      font-weight: ${fontWeight.bold};
      color: ${palette.black};
    }
  }
  .upperCased {
    text-transform: uppercase;
  }
  .comingSoon {
    color: ${palette.gray1};
    font-size: ${pxToRem(10)};
    white-space: nowrap;
  }
  & > * {
    padding-right: ${pxToRem(12)};
  }
  display: inline-flex;
  align-items: center;
  flex: 1;
  padding-left: ${pxToRem(12)};
  justify-content: space-between;
  & div:last-child {
    padding-right: 0;
  }
  & .entryBarPeriod {
    white-space: nowrap;
    &.withData {
      min-width: 50%;
    }
    & .periodDescription {
      display: flex;
      align-items: center;
    }
  }
  & .entryBarPeriodDetail,
  & .lockContainer {
    font-family: ${fontFamily.condensed};
  }
`

export const LockContainer = styled.div`
  height: ${pxToRem(20)};
  width: ${pxToRem(20)};
  padding: 0 ${pxToRem(5)};
`
