import styled from "styled-components/macro"
import { fontWeight, palette, pxToRem, layout } from "../../../utils/style-utils"

export const ScoringBreakdownModalLine = styled.p`
  padding: ${pxToRem(8)} 0 ${pxToRem(8)} 20%;
  width: 100%;
  white-space: nowrap;
`

const circleRadius = 37 / 2

export const ScoringBreakdownTabs = styled.div`
  & {
    border-bottom: ${pxToRem(1)} solid ${palette.gray3};
    display: flex;
    flex-flow: row nowrap;
    margin: ${pxToRem(16)} 0;
  }
  & .tabs__tab {
    flex: 1;
    border-bottom: ${pxToRem(1)} solid ${palette.gray3};
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding: ${pxToRem(8)} 0;
    position: relative;
    font-size: ${pxToRem(16)};
    font-weight: normal;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: ${pxToRem(3)};
      background-color: ${palette.blue2};
      transition: all 0.2s ease-out;
    }
    &.tabs__tab-is-selected--false::after {
      opacity: 0;
    }
  }
`
export const ScoringBreakdownSpreadContainer = styled.div`
  & h5 {
    font-weight: ${fontWeight.bold};
    font-size: ${pxToRem(21)};
    line-height: ${pxToRem(28)};
    color: ${palette.neutral70};
  }
  & h6 {
    font-weight: ${fontWeight.regular};
    font-size: ${pxToRem(18)};
    line-height: ${pxToRem(26)};
    color: ${palette.neutral70};
    & strong {
      font-weight: ${fontWeight.bold};
    }
  }
  & > section {
    padding-bottom: ${pxToRem(32)};
  }
  & .spread__link {
    text-align: center;
  }
  & .spread__text-c {
    text-align: center;
  }
  & .spread__color--green {
    color: ${palette.green};
  }
  & .spread__color--red {
    color: ${palette.red};
  }
  & .spread__color--blue {
    color: ${palette.blue2};
  }
  & .spread__prop-question {
    padding: ${pxToRem(6)} 0;
    display: inline-block;
  }
  & .spread__pickem-event-container {
    pointer-events: none;
    box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    position: relative;
    margin-bottom: ${pxToRem(16)};
    & > * {
      margin: 0;
    }
    & .pickem-event__record {
      display: none;
    }
  }
  & .spread__spread-change-container {
    font-size: ${pxToRem(22)};
    padding: ${pxToRem(8)} ${pxToRem(10)};
    & > * {
      justify-content: center;
    }
  }
  & .spread__padded {
    padding-left: ${pxToRem(32)};
    padding-right: ${pxToRem(32)};
    @media (max-width: ${pxToRem(450)}) {
      & {
        padding-left: ${pxToRem(8)};
        padding-right: ${pxToRem(8)};
      }
    }
  }
  & .spread__max-w {
    max-width: ${pxToRem(340)};
    margin: 0 auto;
    & .spread__padded {
      padding-left: 0;
      padding-right: 0;
    }
  }
  & .tabs__container {
    border-bottom: ${pxToRem(1)} solid ${palette.gray3};
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: ${pxToRem(16)};
  }
  & .tabs__tab {
    flex: 1;
    border-bottom: ${pxToRem(1)} solid ${palette.gray3};
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding: ${pxToRem(8)} 0;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: ${pxToRem(3)};
      background-color: ${palette.blue2};
      transition: all 0.2s ease-out;
    }
    &.tabs__tab-is-selected--false::after {
      opacity: 0;
    }
  }
  & .spread__well {
    background: ${palette.gray3};
    border-radius: ${pxToRem(6)};
    margin: 0 ${pxToRem(28)};
    padding: ${pxToRem(16)};
    @media (max-width: ${pxToRem(450)}) {
      & {
        margin: 0 ${pxToRem(8)};
      }
    }
  }

  & .spread__locator-container {
    position: absolute;
    width: 1px;
  }
  & .spread__locator-container {
    position: absolute;
    width: 1px;
    padding: ${pxToRem(circleRadius * 1.5)} 0;
  }
  & .spread__locator-container--first {
    bottom: ${pxToRem(-5)};
    transform: rotate(-32.3deg);
    transform-origin: top;
  }
  & .spread__locator-container--second {
    bottom: ${pxToRem(22)};
    transform: rotate(-12.9deg);
    transform-origin: top;
  }
  & .spread__locator-container--score {
    top: ${pxToRem(29)};
    left: ${pxToRem(68)};
    @media (max-width: ${layout.shrinkFontMaxW}px) {
      & {
        left: ${pxToRem(63)};
      }
    }
  }
  & .spread__locator-container--number {
    top: ${pxToRem(69)};
    left: ${pxToRem(206.5)};
  }
  & .spread__locator {
    position: absolute;
    width: ${pxToRem(circleRadius * 2)};
    height: ${pxToRem(circleRadius * 2)};
    border: 4px solid currentColor;
    border-radius: 50%;
    left: ${pxToRem(-circleRadius)};
    &:first-child {
      top: ${pxToRem(-circleRadius)};
    }
    &:last-child {
      bottom: ${pxToRem(-circleRadius)};
    }
    &.spread__locator--smaller {
      transform: scale(0.9);
    }
  }
  & .spread__locator-connector {
    width: 100%;
    background-color: currentColor;
    height: 100%;
  }
  & .spread__ul {
    padding-left: ${pxToRem(20)};
    & > li {
      margin-bottom: ${pxToRem(8)};
    }
  }
`
export const ScoringBreakdownTitle = styled.h4`
  font-weight: ${fontWeight.semiBold};
  width: 100%;
  padding: ${pxToRem(8)} 0;
  font-size: ${pxToRem(18)};
  text-align: center;
`
export const ScoringBreakdownHr = styled.hr`
  height: 1px;
  background-color: ${palette.uiSubText2};
`
export const ScoringBreakdownContainer = styled.div`
  max-width: ${pxToRem(240)};
  margin: ${pxToRem(44)} auto;
  align-self: center;
  flex: 1;
  width: 100%;
  & div {
    padding-bottom: 0.5em;
  }
  & strong {
    font-weight: ${fontWeight.semiBold};
  }
  & span {
    display: inline-block;
    min-width: 3em;
    padding-right: 0.5em;
    text-align: right;
  }
  & i {
    display: inline-block;
    color: ${palette.gray5};
  }
`

// TODO qac: factor out
export const NativeAppPromoWrapper = styled.div`
  & > * {
    margin-bottom: ${pxToRem(28)};
    text-align: center;
    line-height: 1.4em;
  }
  & .app-logo {
    display: block;
  }
  & .app-logo img {
    border-radius: 20%;
    height: ${pxToRem(175)};
  }
  & .alct {
    display: flex;
    justify-content: center;
  }
  & .app-links > * {
    margin: 0;
  }
  & .app-links > * {
    margin: 0;
    display: flex;
    flex-flow: row;
  }
  & .app-links a {
    margin: ${pxToRem(6)};
  }
`
