import * as React from "react"
import { emptyObject, recordFor } from "../../../../common/misc-utils"
import { ButtonSmallBase, ButtonSmallBaseAlt, ButtonWithAfterContentInteraction } from "../../../components/Button"
import CheckmarkSvg from "../../../components/icons/Checkmark"
import CloseSvg from "../../../components/icons/Close"
import TeamImg from "../../../components/TeamImg"
import { teamLongName, teamName } from "../../../utils/data-utils"
import { palette } from "../../../utils/style-utils"
import { AnimatedSaveStyles } from "../styles/Picks.styles"
import { IPickemEventTeamProps } from "./picks-types.d"

const styles = {
  green: {
    background: palette.green,
    color: palette.white,
    border: "none",
  },
  red: {
    background: palette.red,
    color: palette.white,
    border: "none",
  },
  greenHue: {
    background: palette.green + "33",
    borderColor: palette.gray3,
  },
  redHue: {
    background: palette.red + "33",
    borderColor: palette.gray3,
  },
}

interface IEventTeamState {
  isSaving: boolean
  itemIdToSave: null | string | boolean
}

function AnimatedSave({ className, isSaving, ...rest }) {
  const fullClassName = `is-saving--${!!isSaving} ${className || ""}`
  return (
    <AnimatedSaveStyles className={fullClassName} {...rest}>
      <CheckmarkSvg bgColor="currentColor" color="#fff" />
      <div>Saved!</div>
    </AnimatedSaveStyles>
  )
}

class EventTeam extends React.PureComponent<IPickemEventTeamProps, IEventTeamState> {
  public saveTimeoutId = 0
  public _isMounted = true

  public state = {
    isSaving: false,
    itemIdToSave: false,
  } as IEventTeamState

  public isLocked() {
    return this.props.pickUtils.isEventLocked(this.props.eventId)
  }

  public getEvent() {
    return this.props.pickUtils.getEventById(this.props.eventId)
  }

  public getTeam() {
    return this.props.pickUtils.getTeam(this.getEvent(), this.props.side, this.props.matchupId)
  }

  public getPick() {
    return this.props.pickUtils.getPick(this.props.eventId)
  }

  public onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    const { isMine, showSaveAnimation, delaySaveCommit, useOutsideSaveAnimation, startSaving } = this.props
    const team = this.getTeam()
    const pick = this.getPick()
    const teamIdOrErase = pick?.itemId === team.id ? null : team.id
    // console.log(`onClick: ${teamIdOrErase} ${useOutsideSaveAnimation} ${delaySaveCommit}`)
    if (!this.isLocked() && isMine) {
      if (showSaveAnimation) {
        if (useOutsideSaveAnimation) {
          // startSaving()
          this.persistItem(teamIdOrErase)
          setTimeout(startSaving, 200)
        } else {
          const state = {
            isSaving: true,
            itemIdToSave: false,
          } as IEventTeamState
          if (delaySaveCommit) {
            state.itemIdToSave = teamIdOrErase
          } else {
            this.persistItem(teamIdOrErase)
          }
          this.setState(state)
        }
        this.saveTimeoutId = setTimeout(this.clearSave, 1200)
      } else {
        // console.log(`teamIdOrErase: ${teamIdOrErase}`)
        this.persistItem(teamIdOrErase)
      }
    }
  }

  public componentWillUnmount() {
    this._isMounted = false
    this.clearSave()
  }
  public persistItem = (teamIdOrErase?: null | string) => {
    const { eventId, isMine, pickTeam } = this.props
    // console.log(`teamIdOrErase for ${eventId}: ${teamIdOrErase}`)
    if (isMine && pickTeam && !this.isLocked()) {
      return pickTeam(eventId, teamIdOrErase || null)
    }
    return Promise.reject()
  }

  public clearSave = () => {
    if (this.saveTimeoutId) {
      clearTimeout(this.saveTimeoutId)
      this.saveTimeoutId = 0
    }
    const { useOutsideSaveAnimation, clearSaving } = this.props
    if (useOutsideSaveAnimation) {
      if (this._isMounted) {
        clearSaving()
      }
    } else {
      const { isSaving, itemIdToSave } = this.state
      if (isSaving) {
        const state = {
          isSaving: false,
          itemIdToSave: false,
        } as IEventTeamState
        if (this._isMounted) {
          this.setState(state)
        }
        if (this.props.delaySaveCommit && itemIdToSave !== false && itemIdToSave !== true) {
          this.persistItem(itemIdToSave)
        }
      }
    }
  }
  // https://sportsfly.cbsistatic.com/fly-248/bundles/sportsmediacss/images/team-logos/ncaa/UCF.svg

  public render() {
    const { isSaving } = this.state
    const { isMine, version, isStarted, canUseTeamLogos, useOutsideSaveAnimation, maContextRecord, eventId, pickUtils, side, disabled } = this.props
    const isLocked = this.isLocked()
    const pick = this.getPick()
    const team = this.getTeam()
    const event = this.getEvent()
    const hasRanks = pickUtils.hasRanks(event)
    const spread = pickUtils.displayFormattedSpreadFor(event, team.id === event.homeTeam?.id)
    const score = event[`${side}TeamScore`] || 0
    const teamRank = event.extra[`${side}TeamRank`] || 0
    const correctItemId = pickUtils.getCorrectPickMapping()[eventId]
    const isWinning = !correctItemId && isStarted ? pickUtils.isWinning(event, side) : null
    const isPicked = !!pick && pick.itemId === team.id

    const percentOwned = event.extra[`${side}TeamPickemPercentOwned`]
    const canView = isMine || isLocked
    // const isWinner = correctItemId === team.id;
    if (version === "team") {
      let record = team && recordFor(team)
      if (maContextRecord) {
        record = maContextRecord
      }
      // hide if: no team, but 0-0 is ok!
      const canHaveRecord = !!record // && record !== "0-0"
      return (
        <div className="cardteam cardbodyrow">
          <TeamImg team={team} canUseTeamLogos={canUseTeamLogos} style={emptyObject} />
          {hasRanks ? <div className="cardteam__team-rank">{teamRank || ""}</div> : null}
          <div className="team-name">
            <span>{teamName(team)}</span>
          </div>
          <i className="picks-percentage">{typeof percentOwned !== "number" ? <span>&nbsp;</span> : `${percentOwned}%`}</i>
          {(isStarted && <div className="score">{score}</div>) || (canHaveRecord && <div className="record">{record}</div>) || null}
        </div>
      )
    }
    const isDisabled = isLocked || !isMine || disabled
    const styleKey =
      isPicked && canView
        ? (correctItemId && correctItemId === pick.itemId && "green") ||
          (correctItemId && correctItemId !== pick.itemId && "red") ||
          (isWinning === true && "greenHue") ||
          (isWinning === false && "redHue") ||
          "blue"
        : "unpicked"
    const style = styles[styleKey] || undefined
    if (version === "prop") {
      const event = pickUtils.getEventById(eventId)
      const favoredTeam = (event && pickUtils.getPropFavoredTeamFor(event)) || null
      const isFavoredTeam = team.id === favoredTeam?.id
      const classNames = [`team-event__prop-btn`, `team-event__prop-btn--${styleKey}`, `is-saving--${isSaving}`]
      return (
        <ButtonWithAfterContentInteraction type="button" className={classNames.join(" ")} disabled={isDisabled} onClick={this.onClick}>
          <div className="event-team__prop-btn-text">{isFavoredTeam ? "Yes" : "No"}</div>
          {(typeof percentOwned === "number" && <div className="event-team__prop-btn-perc-owned">Pick Percent {percentOwned}%</div>) || null}
          {style === styles.green && <CheckmarkSvg className="event-team__prop-btn-marker" color={palette.green} icon={true} />}
          {style === styles.red && <CloseSvg className="event-team__prop-btn-marker" color={palette.red} icon={true} />}
          {(!useOutsideSaveAnimation && <AnimatedSave isSaving={isSaving} className="animated-save" />) || null}
        </ButtonWithAfterContentInteraction>
      )
    }
    const Button = isPicked && canView ? ButtonSmallBase : ButtonSmallBaseAlt
    const title =
      (!canView && "Hidden") ||
      (pick && pick.itemId === team.id && `Picked ${teamLongName(team)}`) ||
      (isLocked && "Locked") ||
      `Pick ${teamLongName(team)}`
    if (version === "btn") {
      const btnStyles = isDisabled ? { ...(style ?? {}), color: palette.gray75 } : style
      return (
        <div className="cardaction cardbodyrow">
          <Button
            style={btnStyles}
            type="button"
            className={`is-picked--${isPicked} is-locked--${!!isLocked} is-saving--${isSaving} is-transparent--true`}
            disabled={isDisabled}
            onClick={this.onClick}
            title={title}
          >
            <span>{team.abbrev}</span>
            {spread && <small>{spread}</small>}
            {(!useOutsideSaveAnimation && <AnimatedSave isSaving={isSaving} className="animated-save" />) || null}
          </Button>
        </div>
      )
    }
    throw new Error(`invalid version: ${version}`)
  }
}
export default EventTeam
