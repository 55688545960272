import { DateTime } from "luxon"
import * as React from "react"
import PickUtils from "../../../../common/pick-utils"
import { OpacityBtn } from "../../../components/Button"
import CloseSvg from "../../../components/icons/Close"
import TeamImg from "../../../components/TeamImg"
import TeamJewel from "../../../components/TeamJewel"
import Analytics from "../../../utils/analytics"
import { palette } from "../../../utils/style-utils"
import { EntryBarTeamStyled } from "../styles/EntryBar.styles"

interface IEntryBarTeam {
  pickUtils: PickUtils
  slotId: string
  itemId?: string | null
  cacheBuster: string
  onClick: (slotId: string) => void
  correctItemId?: string | null
  isValid?: boolean
  canUseTeamLogos: boolean
}

export const styles = {
  blue: {
    borderLeftColor: palette.blue2,
    background: palette.white,
  },
  green: {
    borderLeftColor: palette.green,
    background: palette.white,
  },
  red: {
    borderLeftColor: palette.red,
    background: palette.white,
  },
  greenHue: {
    background: palette.green + "33",
  },
  redHue: {
    background: palette.red + "33",
  },
}

class EntryBarTeam extends React.PureComponent<IEntryBarTeam> {
  public onClick = (evt: React.SyntheticEvent) => {
    evt.preventDefault()
    Analytics.trackAction("left rail make picks", "left rail", "picks_remove")
    this.props.onClick(this.props.slotId)
  }

  public render() {
    const { pickUtils, slotId, correctItemId, isValid, itemId, canUseTeamLogos } = this.props
    // const pick = pickUtils.getPick(slotId)!;
    const event = pickUtils.getEventById(slotId)
    if (!event || !event.homeTeam) {
      return null
    }
    const side = event.homeTeam.id === itemId ? pickUtils.home : pickUtils.away
    // const teamRank = event.extra[`${side}TeamRank`];
    // console.dir(event);
    // console.log(`${side}TeamRank`);
    const isLocked = pickUtils.isEventLocked(event.id)
    const team = pickUtils.getTeam(event, side)
    const editInsteadOfRemove = pickUtils.isParlay() && pickUtils.supportsPicksWithDifferentSpreadLockTimes() && pickUtils.hasMadeAllPicks()
    const name = team.abbrev // teamLongName(team)
    const isStarted = event.startsAt < Date.now()
    const spread = pickUtils.displayFormattedSpreadFor(event, side === pickUtils.home)
    const possiblePoints = pickUtils.hasModifiers() ? pickUtils.getPossiblePointsFor(event.id) : null
    const styleKey =
      (correctItemId && correctItemId === itemId && "green") ||
      (correctItemId && correctItemId !== itemId && "red") ||
      (isStarted && (pickUtils.isWinning(event, side) ? "greenHue" : "redHue")) ||
      (isValid && "blue") ||
      ""
    const style = styles[styleKey]
    // const score = `${event.awayTeamScore || 0} - ${event.homeTeamScore || 0}`;
    const score = `${pickUtils.getTeam(event, pickUtils.away).abbrev} ${event.awayTeamScore || 0} - ${
      pickUtils.getTeam(event, pickUtils.home).abbrev
    } ${event.homeTeamScore || 0}`
    const atValue = isStarted
      ? // style === styles.green && `Won ${score}` || style === styles.greenHue && `Winning ${score}` || style === styles.red && `Lost ${score}` || `Losing ${score}`
        score
      : `at ${DateTime.fromMillis(event.startsAt).toFormat("L/dd h:mma ZZZZ")}`
    return (
      <EntryBarTeamStyled style={style}>
        {(possiblePoints !== null && <div className="points">{possiblePoints} pts</div>) || null}
        {(canUseTeamLogos && (
          <>
            <TeamImg team={team} alt={name} canUseTeamLogos={canUseTeamLogos} />
            {/*<div className="rank">{teamRank || ""}</div>*/}
            <div className="name">
              {name} {spread}
            </div>
          </>
        )) || (
          <>
            <TeamJewel className="team-jewel" team={team} />
            {(spread && <div className="name">{spread}</div>) || null}
          </>
        )}
        <div className="at">{atValue}</div>
        {!isLocked && (
          <OpacityBtn as="button" type="button" onClick={this.onClick}>
            {editInsteadOfRemove ? <span>Edit</span> : <CloseSvg />}
          </OpacityBtn>
        )}
      </EntryBarTeamStyled>
    )
  }
}
export default EntryBarTeam
