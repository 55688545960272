import * as React from "react"
import { useHistory, useLocation, useRouteMatch } from "react-router"
import { emptyArray } from "../../../../common/misc-utils"
import { CentralSegmentFragment } from "../../../../__generated__/CentralSegmentFragment"
import { ButtonSmallBase } from "../../../components/Button"
import CustomCopy from "../../../components/CustomCopy"
import Link from "../../../components/Link"
import { Logo } from "../../../components/Logos"
import usePoolData from "../../../hooks/usePoolData"
import { withoutDomain } from "../../../utils/url-utils"
import { UpsellCard, CalloutWithOpaque } from "../styles/Picks.styles"

interface IProps {
  className?: string
  id?: string
  segmentForArea: CentralSegmentFragment | undefined
  isChallengeWithLockedGame?: boolean
  lateToTheGame: boolean
}

function UpsellOtherGames(props: IProps) {
  const { className, id, segmentForArea } = props
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch<any>()
  if (!match) {
    return null
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const poolData = usePoolData({ match, location, history })
  const { allSegments } = poolData
  const _allSegments = allSegments || emptyArray
  const segment = _allSegments.find((s) => segmentForArea?.id === s.id)
  if (
    segment?.hasEnded ||
    (segment?.poolSettings.__typename === "PoolSettings" && segment?.poolSettings.picksDeadlineType === "BEFORE_START_OF_PERIODS_FIRST_GAME")
  ) {
    // promote other season logic:
    const segmentToPromote = _allSegments.find((s) => s.shouldPromoteToPlay && s.season.challengePoolSlug && segmentForArea?.id !== s.id)
    if (!segmentToPromote) {
      return null
    }
    return (
      <UpsellCard className={className}>
        <Logo id={id} gameInstanceUid={segmentToPromote.season.gameInstance.uid} season={segmentToPromote.season} segment={segmentToPromote} />
        <p>
          <CustomCopy as="strong" code="upsellCardPicksClosed" />
          <br />
          <CustomCopy as="strong" code="upsellCardPicksClosedPromo" />
          <CustomCopy as="strong" code="title" season={segmentToPromote.season} />
        </p>
        <ButtonSmallBase as={Link} to={withoutDomain(segmentToPromote.baseUrl)}>
          Play Now
        </ButtonSmallBase>
      </UpsellCard>
    )
  } else {
    return null
  }
}

const RulesAndPrizing = (props: IProps) => {
  const { className, id, isChallengeWithLockedGame, segmentForArea, lateToTheGame } = props
  const shouldPromoteOtherSeason =
    segmentForArea?.hasEnded ||
    (segmentForArea?.poolSettings.__typename === "PoolSettings" &&
      segmentForArea?.poolSettings.picksDeadlineType === "BEFORE_START_OF_PERIODS_FIRST_GAME")
  if (isChallengeWithLockedGame && lateToTheGame && shouldPromoteOtherSeason) {
    return <UpsellOtherGames {...props} />
  } else if (isChallengeWithLockedGame) {
    return (
      <CalloutWithOpaque className={className} id={id}>
        <CustomCopy code="picksPageStartedGamesHtml" />
      </CalloutWithOpaque>
    )
  } else {
    return (
      <CalloutWithOpaque className={className} id={id}>
        <CustomCopy code="picksPageRulesHtml" />
      </CalloutWithOpaque>
    )
  }
}
export default RulesAndPrizing
