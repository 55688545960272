import React, { useEffect, useState, useRef, useContext } from "react"
import { useLazyQuery } from "@apollo/client"
import { buildClassNames as cx } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { ISearchProps } from "@cbs-sports/sports-shared-client/build/types/components/Search/Search"
import { SearchDropdownWrap } from "./SearchEntriesDropdown.styles"
import { SearchComponent } from "@cbs-sports/sports-shared-client/build/cjs/components/Search"
import DropDownItem from "../../../../components/DropDownMenu/DropDownItem"
// import ChampionLogo from "../MultipleEntriesArea/PoolHome/ChampionLogo"
import EntryLogo from "../../components/EntryLogo"
import PoolDataContext, { PoolDataContextType } from "../../../../Contexts/PoolDataContext"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import constants from "../../../../../common/constants"
import { PoolEntriesSearch, PoolEntriesSearchVariables } from "../../../../../__generated__/PoolEntriesSearch"
import { POOL_ENTRIES_SEARCH } from "../../../queries"
import { edgeToNode } from "../../../../utils/misc-utils"
import { withoutDomain } from "../../../../utils/url-utils"

interface ISearchDropdownProps extends ISearchProps {
  onSelect?: (item: unknown) => void
}
export const SearchEntriesDropdown = ({ onSelect, ...rest }: ISearchDropdownProps) => {
  const poolDataContext: PoolDataContextType = useContext(PoolDataContext)
  const { poolId, poolDetail, centralBracketState, isManagerModeActive } = poolDataContext || emptyObject
  const { url } = poolDetail || emptyObject

  const wrap = useRef<HTMLDivElement | null>(null)
  const [showOptionsList, setShowOptionsList] = useState(false)

  const [getEntries, { loading, data }] = useLazyQuery<PoolEntriesSearch, PoolEntriesSearchVariables>(POOL_ENTRIES_SEARCH, {})
  const isLocked = Boolean(centralBracketState?.bracketState?.isLocked)

  useEffect(() => {
    const onClick = (e: MouseEvent | TouchEvent) => {
      const isClickInside = wrap.current?.contains(e.target as Node)
      if (!isClickInside) {
        setShowOptionsList(false)
      }
    }

    if (typeof document !== "undefined") {
      document.addEventListener("click", onClick, { passive: true })
      document.addEventListener("touchstart", onClick, { passive: true })
    }

    return () => {
      if (typeof document !== "undefined") {
        document.removeEventListener("click", onClick)
        document.removeEventListener("touchstart", onClick)
      }
    }
  }, [])

  const handleOnSearch = (value: string) => {
    getEntries({
      variables: {
        limit: 100,
        poolId,
        searchTerm: value,
      },
    })
    setShowOptionsList(true)
  }

  const entries = (data?.pool.entrySearch.edges || []).map(edgeToNode)

  return (
    <SearchDropdownWrap ref={wrap}>
      <SearchComponent {...rest} onSearch={handleOnSearch} minLength={constants.ENTRY_NAME_MIN_LENGTH} />
      <div
        className={cx({
          "option-list-container": true,
          "display-block": showOptionsList && !loading,
        })}
      >
        <ul className={`option-container`}>
          {!loading &&
            entries.length > 0 &&
            entries.map((entry, i) => {
              const { id, name, url, isMine } = entry
              const disabledOption = !isMine && !isLocked && !isManagerModeActive

              const key = `option-${i}-${id}`
              const props = {
                id,
                label: name,
                to: url,
                // subtitle: poolName,
                withIcon: true,
                icon: <EntryLogo entry={entry as any} size="rg" showChampionLogo />,
                // icon: <ChampionLogo championTeam={{ id: "krswc3j2giytcnzy" } as any} showTiebreaker={false} tiebreakerAnswers={[]} />,
                disabled: disabledOption,
              }
              return <DropDownItem {...props} key={key} />
            })}
          {!loading && entries.length === 0 && (
            <DropDownItem id="no-entries-to-show-id" key="no-entries-to-show-key" label="No bracket names found." to={withoutDomain(url)} disabled />
          )}
        </ul>
      </div>
    </SearchDropdownWrap>
  )
}
export default SearchEntriesDropdown
