import { Location } from "history"
import React from "react"
import { EntryPeriodPointsGlanceQuery } from "../../../../__generated__/EntryPeriodPointsGlanceQuery"
import { PoolPeriodQuery_gameInstance_period_segment_periods_edges_node } from "../../../../__generated__/PoolPeriodQuery"
import { setParam } from "../../../../common/url-utils"
import { EntryBarComponentsContainer, EntryBarTournamentLink } from "../styles/EntryBarTournamentLink.styles"
import EntryBarPeriodContainer from "./EntryBarPeriodContainer"
import EntryBarPeriodDetails from "./EntryBarPeroidDetails"

interface IEntryBarTournamentLinkProps {
  entryPeriodPointsGlance: EntryPeriodPointsGlanceQuery | null
  isHighlighted: boolean
  isLocked: boolean
  location: Location
  maxPicksCount?: number
  period: PoolPeriodQuery_gameInstance_period_segment_periods_edges_node
  picksCount?: number
}

const EntryBarTournamentLinkComponent = (props: IEntryBarTournamentLinkProps) => {
  const { entryPeriodPointsGlance, isHighlighted, isLocked, location, maxPicksCount, period, picksCount } = props
  const { isInComingSoon, order, id: periodId } = props.period
  const url = `${location.pathname}?${setParam(location.search, "order", order)}`
  const periodPointsEdges = entryPeriodPointsGlance?.entry.periodPoints.edges
  const periodPoints = periodPointsEdges ? periodPointsEdges.find((edge) => edge.node?.periodId === periodId) : undefined

  return (
    <EntryBarTournamentLink className={`is-viewed--${isHighlighted}`} to={url}>
      <EntryBarComponentsContainer>
        <EntryBarPeriodContainer period={period} isLocked={isLocked} isInComingSoon={isInComingSoon} />
        {!isInComingSoon ? (
          <EntryBarPeriodDetails
            fantasyPoints={periodPoints?.node?.fantasyPoints || 0}
            isLocked={isLocked}
            maxPicksCount={maxPicksCount || periodPoints?.node?.maxPicksCount || 0}
            picksCount={picksCount || periodPoints?.node?.picksCount || 0}
            poolRank={periodPoints?.node?.poolRank || 0}
          />
        ) : null}
      </EntryBarComponentsContainer>
    </EntryBarTournamentLink>
  )
}

export default EntryBarTournamentLinkComponent
