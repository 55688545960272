import * as React from "react"
import styled from "styled-components/macro"

const SVG = styled.svg`
  &.variant--down {
    transform: rotate(180deg);
  }
  &.variant--right {
    transform: rotate(90deg);
  }
  &.is-icon--true {
    height: 1.5em;
    width: 1.5em;
  }
`

interface IProps extends React.SVGProps<SVGSVGElement> {
  color?: string
  icon?: boolean
  variant?: "up" | "down" | "right"
}

const SvgComponent = React.forwardRef<SVGSVGElement, IProps>(({ color = "currentColor", icon = false, variant = "up", className, ...props }, ref) => {
  const classNames = [`variant--${variant}`] as string[]
  if (className) {
    classNames.push(className)
  }
  if (icon) {
    classNames.push("is-icon--true")
  }
  return (
    <SVG viewBox="0 0 5 8" className={classNames.join(" ")} {...props} ref={ref}>
      <path fill={color} d="M5 3H0l2.5-3L5 3z" />
      <path stroke={color} d="M2.5 1.5V8" />
    </SVG>
  )
})

const MemoizedComponent = React.memo(SvgComponent)
export default MemoizedComponent
