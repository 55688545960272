import * as React from "react"
import palette from "../../../common/palette"
import CashOutlineIcon from "./CashOutlineIcon"

interface IProps extends React.SVGProps<SVGSVGElement> {
  disabled?: boolean
}
const enabledStyle = { color: palette.green } as React.CSSProperties
const disabledStyle = { color: palette.gray2 } as React.CSSProperties

const El = ({ disabled, ref, ...rest }: IProps) => <CashOutlineIcon style={disabled ? disabledStyle : enabledStyle} {...rest} />

export default El
