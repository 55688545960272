import styled from "styled-components/macro"
import { fontWeight, pxToRem } from "../../../utils/style-utils"

export const Row = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  align-items: center;
`
export const BcgPromoSection = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  & svg {
    margin: 0 auto;
    width: 70%;
    display: block;
    max-width: ${pxToRem(250)};
    padding: ${pxToRem(20)};
  }
`
export const BcgTitle = styled.h4`
  text-transform: uppercase;
  font-weight: ${fontWeight.bold};
  text-align: center;
  font-size: ${pxToRem(15)};
`
export const BcgSubtext = styled.h5`
  font-weight: ${fontWeight.semiBold};
  text-align: center;
  font-size: ${pxToRem(12)};
  padding: ${pxToRem(8)};
`
