import React from "react"
import styled from "styled-components/macro"
import { fontWeight, palette, pxToRem } from "../utils/style-utils"

interface ITeamJewelTeam {
  abbrev: string
  colorHexDex: string | null
  colorSecondaryHex?: string | null
}
interface ITeamJewelProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  score?: number
  team: ITeamJewelTeam
}

const TeamJewelStyled = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: ${pxToRem(4)};
  overflow: hidden;
  height: 1.5em;
  flex: 0 0 auto;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.05);
  & div {
    width: ${pxToRem(8)};
    height: 100%;
    clip-path: polygon(0 0, 100% 0, 65% 99%, 0% 100%);
    position: absolute;
    left: -0.5px;
    top: 0;
  }
  & p {
    color: ${palette.white};
    letter-spacing: 0.01em;
    font-weight: ${fontWeight.bold};
    padding: 0 ${pxToRem(6)} 0 ${pxToRem(6 + 8)};
  }
`

function TeamJewel(props: ITeamJewelProps) {
  const { team, score, ref, ...rest } = props
  const abbrev = team.abbrev || "CHI"
  const colorHexDex = `#${team.colorHexDex || "C83803"}`
  const colorSecondaryHex = `#${team.colorSecondaryHex || "0B162A"}`
  const text = typeof score === "number" ? `${abbrev} ${score}` : abbrev
  return (
    <TeamJewelStyled {...rest} style={{ backgroundColor: colorHexDex }}>
      <div className="team-jewel__left-pane" style={{ backgroundColor: colorSecondaryHex }}>
        &nbsp;
      </div>
      <p>{text}</p>
    </TeamJewelStyled>
  )
}

const MemoComponent = React.memo(TeamJewel)
export default MemoComponent
