import styled from "styled-components/macro"
import BackgroundImage from "../../../components/BackgroundImage"
import { ButtonBase } from "../../../components/Button"
import { fontWeight, pxToRem } from "../../../utils/style-utils"

export const UpsellDisclaimer = styled.small`
  padding: ${pxToRem(16)} ${pxToRem(16)} ${pxToRem(32)};
  color: #000;
  display: block;
  font-size: 0.8em;
  & a {
    text-decoration: underline;
  }
`
export const UpsellWrapper = styled.div`
  background-color: #fff;
  text-align: center;
  width: ${pxToRem(450)};
  max-width: 100%;
  position: relative;
`
export const UpsellHeader = styled(BackgroundImage)`
  height: ${pxToRem(180)};
  background-image: url(https://www.cbssports.com/images/email/graphics/200210_conferencebracketsheaderimgmobile.png);
  border-bottom: ${pxToRem(2)} solid currentColor;
  background-size: cover;
  display: flex;
  flex-flow: row nowrap;
  &.sportsline {
    padding: 2.5em;
    background: rgba(0, 0, 0, 0.02);
  }
  & .sports-line-svg {
    width: 4.5em;
    margin-right: 1.5em;
  }
`
export const UpsellT1 = styled.div`
  font-weight: ${fontWeight.semiBold};
  font-size: ${pxToRem(18)};
  color: currentColor;
  padding: ${pxToRem(16)};
  & svg {
    height: 1em;
    width: 1em;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 0.5em;
  }
`
export const UpsellDiv = styled.div`
  height: ${pxToRem(4)};
  margin: ${pxToRem(6)} auto;
  width: ${pxToRem(40)};
  background-color: currentColor;
`
export const UpsellT2 = styled.div`
  font-weight: ${fontWeight.regular};
  text-transform: uppercase;
  font-size: ${pxToRem(26)};
  padding: ${pxToRem(16)};
  line-height: 1.2em;
  color: #000;
`
export const UpsellT3 = styled.div`
  font-weight: ${fontWeight.bold};
  font-size: ${pxToRem(22)};
  padding: ${pxToRem(16)};
  text-transform: uppercase;
  line-height: 1.2em;
  color: #000;
`
export const UpsellT4 = styled.div`
  font-size: ${pxToRem(18)};
  padding: ${pxToRem(16)};
  color: #000;
  & a {
    font-weight: ${fontWeight.semiBold};
  }
`
export const UpsellBtn = styled(ButtonBase)`
  margin: ${pxToRem(16)} auto;
`
