import { fontFamily } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import styled, { keyframes } from "styled-components/macro"
import { breakpoints, fontWeight, palette, pxToRem, zIndexes } from "../../../utils/style-utils"

const defaultPadding = 10
const maxWidthBp = 536

const CalloutIn = keyframes`
  0% {
    transform: scale(0.6);
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`
export const GPUpsellWrapper = styled.div`
  width: 95vw;
  max-width: ${pxToRem(maxWidthBp)};
  line-height: 1.6em;
  font-size: ${pxToRem(14)};

  & .color-variant--gold {
    color: ${palette.whGold};
  }
  & .color-variant--blue {
    color: ${palette.blue2};
  }

  & strong {
    font-weight: ${fontWeight.bold};
  }

  & .gp-upsell__wh-callout {
    text-align: center;
    margin: ${pxToRem(20)} auto 0;
    max-width: ${pxToRem(280)};
    & svg,
    img {
      display: block;
      height: 1.75rem;
      margin: 0 auto 1rem;
    }
    & small {
      font-size: ${pxToRem(12)};
      line-height: 1.6em;
      color: ${palette.gray5};
      font-weight: ${fontWeight.semiBold};
    }
  }

  & .gp-upsell__stricky-container {
    position: sticky;
    top: 0;
    background-color: ${palette.white};
    z-index: ${zIndexes.modalHeader};
    border-bottom: 1px solid ${palette.gray3};
    padding-bottom: ${pxToRem(10)};
    & .gp-upsell__onsubmit-title {
      padding: ${pxToRem(16)} 0;
    }
    & .gp-upsell__question {
      padding-bottom: ${pxToRem(16)};
    }
  }

  & .gp-upsell__close-btn-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: flex-end;
    pointer-events: none;
    & button {
      pointer-events: all;
      position: relative;
      color: ${palette.white};
      @media (min-width: ${pxToRem(maxWidthBp)}) {
        margin-right: -${pxToRem(16)};
      }
    }
  }

  & .gp-upsell__callout {
    margin-bottom: ${pxToRem(8)};
    border-radius: ${pxToRem(13)};
    animation-name: ${CalloutIn};
    animation-timing-function: ease-out;
    animation-fill-mode: both;
    animation-duration: 0.25s;
    animation-delay: 0.7s;
  }

  & .gp-upsell__wrapper {
    background-color: ${palette.white};
    border-radius: ${pxToRem(13)};
    width: 100%;
  }

  & .gp-upsell__inner {
    width: 100%;
    margin: 0 auto;
    & > * {
      padding-top: ${pxToRem(18)};
    }
    @media (min-width: ${pxToRem(maxWidthBp)}) {
      & {
        max-width: ${pxToRem(330)};
      }
    }
    @media (max-width: ${pxToRem(maxWidthBp)}) {
      & > *:not(.gp-upsell__onsubmit-items) {
        padding-left: ${pxToRem(16)};
        padding-right: ${pxToRem(16)};
      }
    }
  }

  & .gp-upsell__onsubmit-table-header {
    padding-top: 0;
    margin-bottom: ${pxToRem(-8)};
    font-size: ${pxToRem(12)};
    font-weight: ${fontWeight.bold};
    color: ${palette.gray1};
    letter-spacing: 0.02em;
    text-align: right;
  }
  & .gp-upsell__onsubmit-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    & small {
      font-size: ${pxToRem(12)};
      line-height: 1.6em;
      color: ${palette.gray2};
    }
    & svg {
      height: ${pxToRem(40)};
      margin-right: ${pxToRem(16)};
      width: auto;
    }
  }
  & .gp-upsell__onsubmit-title {
    color: ${palette.gray2};
    font-size: ${pxToRem(20)};
    line-height: 1.6em;
    text-align: center;
  }
  & .gp-upsell__question {
    color: ${palette.gray2};
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(20)};
    text-align: center;
    text-transform: uppercase;
    & strong {
      font-size: ${pxToRem(20)};
      padding: 0 ${pxToRem(4)};
    }
  }
  & .gp-upsell__onsubmit-items-v2 {
    border-bottom: ${pxToRem(1)} solid ${palette.gray3};
    font-size: ${pxToRem(16)};
    max-height: ${pxToRem(520)};
    padding-top: 0;
    & .gp-upsell__onsubmit-item:first-child {
      border-top: none;
    }
  }
  & .gp-upsell__onsubmit-items {
    border-bottom: ${pxToRem(1)} solid ${palette.gray3};
    font-size: ${pxToRem(16)};
    max-height: ${pxToRem(300)};
    padding-top: 0;
    margin-top: ${pxToRem(16)};
  }
  & .gp-upsell__onsubmit-item--is-elligible-false {
    background-color: ${palette.gray4}c2;
    & svg {
      opacity: 0;
    }
  }
  & .name {
    color: ${palette.gray1};
  }
  & .gp-upsell__onsubmit-item {
    height: ${pxToRem(46)};
    border: ${pxToRem(1)} solid ${palette.gray3};
    border-bottom: none;
    background-image: none;
    background-color: transparent;
    width: 100%;
    padding: 0 ${pxToRem(2)} 0 ${pxToRem(2)};
    & .checkbox {
      margin-left: auto;
      margin-right: ${pxToRem(7)};
      width: ${pxToRem(20)};
      height: ${pxToRem(20)};
    }
    @media (max-width: ${pxToRem(maxWidthBp)}) {
      & {
        border-left: none;
        border-right: none;
        padding: 0 ${pxToRem(8)} 0 ${pxToRem(8)};
      }
    }
  }

  & .gp-upsell__tool-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    & strong {
      font-size: ${pxToRem(18)};
    }
  }
  /* slider styles: */
  & .gp-upsell__tool-slider {
    color: ${palette.blue2};
    & label {
      position: absolute !important;
      height: 1px;
      width: 1px;
      overflow: hidden;
      clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
      clip: rect(1px, 1px, 1px, 1px);
      white-space: nowrap; /* added line */
    }
  }
  input[type="range"] {
    width: 100%;
    margin: 12px 0;
    background-color: transparent;
    -webkit-appearance: none;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    background: #d0d0d0;
    border: 0;
    border-radius: 5px;
    width: 100%;
    height: 3px;
    cursor: pointer;
  }
  input[type="range"]::-webkit-slider-thumb {
    margin-top: -12px;
    width: 27px;
    height: 27px;
    background: ${palette.blue2};
    border: 0;
    border-radius: 27px;
    cursor: pointer;
    -webkit-appearance: none;
    box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.25);
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #dddddd;
  }
  input[type="range"]::-moz-range-track {
    background: #d0d0d0;
    border: 0;
    border-radius: 5px;
    width: 100%;
    height: 3px;
    cursor: pointer;
  }
  input[type="range"]::-moz-range-thumb {
    width: 27px;
    height: 27px;
    background: ${palette.blue2};
    border: 0;
    border-radius: 27px;
    cursor: pointer;
    box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.25);
  }
  input[type="range"]::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 13px 0;
    color: transparent;
    width: 100%;
    height: 3px;
    cursor: pointer;
  }
  input[type="range"]::-ms-fill-lower {
    background: #c3c3c3;
    border: 0;
    border-radius: 10px;
  }
  input[type="range"]::-ms-fill-upper {
    background: #d0d0d0;
    border: 0;
    border-radius: 10px;
  }
  input[type="range"]::-ms-thumb {
    width: 27px;
    height: 27px;
    background: ${palette.blue2};
    border: 0;
    border-radius: 27px;
    cursor: pointer;
    margin-top: 0px;
    box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.25);
    /*Needed to keep the Edge thumb centred*/
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: #d0d0d0;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: #dddddd;
  }
  /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
  @supports (-ms-ime-align: auto) {
    /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
    input[type="range"] {
      margin: 0;
      /*Edge starts the margin from the thumb, not the track as other browsers do*/
    }
  }

  & .gp-upsell__cta-btn {
    height: ${pxToRem(40)};
    width: 100%;
    color: ${palette.white};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${palette.blue2};
    &:disabled {
      color: ${palette.gray2};
      background-color: ${palette.gray3};
    }
    &:active {
      background-color: ${palette.unknownButtonActiveBlue};
    }
    @media (hover: hover) {
      &:hover:not(:active) {
        background-color: ${palette.unknownButtonActiveBlue};
      }
    }
    & svg {
      font-size: ${pxToRem(7.5)};
      margin-left: ${pxToRem(8)};
    }
  }
  & .gp-upsell__disclaimer {
    font-size: ${pxToRem(10)};
    color: ${palette.gray3};
    text-align: center;
    padding-top: ${pxToRem(8)} !important;
    padding-bottom: ${pxToRem(8)} !important;
  }
`

// export const GPUpsellHeader = styled(BackgroundImage)`
//   max-height: ${pxToRem(180)};
//   /* background-image: url(https://www.cbssports.com/images/email/graphics/200210_conferencebracketsheaderimgmobile.png); */
//   border-bottom: ${pxToRem(2)} solid currentColor;
//   background-size: cover;
//   display: flex;
//   flex-flow: row nowrap;
// `
export const GPUpsellHeader = styled.header`
  position: sticky;
  top: 0;
  z-index: ${zIndexes.ui1};
  background: white;
  box-shadow: 0px 3px 2px -1px rgba(0, 0, 0, 0.2);
`

export const GPUpsellSuccessText = styled.div`
  font-weight: ${fontWeight.semiBold};
  font-size: 1rem;
  color: ${palette.white};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  background-color: ${palette.green};
  height: ${pxToRem(54)};
  width: 20rem;
  padding-left: 1rem;
  @media (min-width: ${pxToRem(breakpoints.handheld)}) {
    width: 30rem;
    font-size: ${pxToRem(18)};
    justify-content: center;
    padding-left: 0;
  }
`
export const GPUpsellCasinoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: ${pxToRem(15)};
  & .gambling-partner-callout {
    color: ${palette.whBlue3};
    display: flex;
    font-size: ${pxToRem(18)};
    align-items: center;
    & svg {
      margin-left: ${pxToRem(5)};
      width: ${pxToRem(87)};
    }
  }
  & .picks-promo-callout {
    color: ${palette.gray2};
    font-size: ${pxToRem(11)};
    font-weight: ${fontWeight.semiBold};
  }
`

export const GPBettingSlip = styled.div`
  padding: ${pxToRem(defaultPadding)};
  & .gambling-partner-betting-slip-title {
    color: ${palette.whPrimaryBlue};
    font-size: ${pxToRem(16)};
    font-weight: ${fontWeight.extraBold};
  }
  & .bet-amount {
    width: ${pxToRem(45)};
    color: ${palette.blue2};
    margin-left: ${pxToRem(6)};
  }
`

export const ThreeCol = styled.div`
  display: flex;
  flex-flow: row nowrap;
  height: ${pxToRem(48)};
  margin: ${pxToRem(10)} 0;
  text-align: center;
  width: 100%;
  & svg {
    height: ${pxToRem(20)};
  }
  & > * {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    flex: 1;
  }
  & dt {
    font-family: ${fontFamily.condensed};
    font-size: ${pxToRem(11)};
    padding-bottom: ${pxToRem(6)};
    text-transform: uppercase;
    color: ${palette.gray2};
    font-weight: ${fontWeight.semiBold};
  }
  & dd {
    font-weight: ${fontWeight.semiBold};
    font-size: ${pxToRem(16)};
    height: ${pxToRem(32)};
  }
`

export const GPGamesWrapper = styled.div`
  padding: ${pxToRem(defaultPadding)};
  min-height: 80vh;
`

export const GPSubmitBetButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: ${pxToRem(defaultPadding)};
  position: sticky;
  bottom: 0;
  background: white;
  box-shadow: 0px -3px 2px -1px rgba(0, 0, 0, 0.2);
`

export const GPSetEventsItemCheckmark = styled.div`
  width: 3em;
  padding: 0.75em;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
  }
`

export const GPBettingActions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  flex: 0 0 ${pxToRem(30)};
  min-width: ${pxToRem(30)};
  & button {
    height: ${pxToRem(16)};
    width: ${pxToRem(16)};

    &.checkmark {
      height: 100%;
      width: 100%;
    }

    & svg {
      height: 100%;
    }
  }
`

export const GambleOnEventContent = styled.div`
  display: flex;
  flex-direction: column;
  color: ${palette.default};
  border-bottom: ${pxToRem(1)} solid ${palette.cardBorderGray};
  padding-top: ${pxToRem(10)};
  & .title {
    font-size: ${pxToRem(13)};
    font-weight: ${fontWeight.light};
    text-align: left;
  }
  & .team-container {
    display: flex;
    align-items: center;

    & .team-gambling-info-container {
      display: flex;
      flex-grow: 1;
      padding: 0 ${pxToRem(5)};
      font-size: ${pxToRem(14)};
      align-items: center;

      & .team-spread-container {
        display: flex;
        flex-grow: 1;
        font-weight: ${fontWeight.semiBold};

        & .team-spread {
          margin-left: ${pxToRem(5)};
        }
      }

      & .team-moneyline-container {
        font-weight: ${fontWeight.bold};
        font-size: ${pxToRem(11)};
        @media (min-width: ${pxToRem(breakpoints.handheld)}) {
          font-size: ${pxToRem(14)};
        }
      }
    }
  }
`
